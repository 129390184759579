import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import { Formations } from "../../../types/panels/wellPanel/formationType";
import { FormationData } from "../../../types/panels/wellPanel/wellPanel";

import config from "../../../configs/appSettings";

import { FORMATION_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

export const useWellPanelFormations = () => {
  const [data, setData] = useState<FormationData | undefined>(undefined);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [dataError, setDataError] = useState<string | undefined>(undefined);

  const getWellFormationTops = async (wellId: number, uwi: string) => {
    const formationData: FormationData = {
      type: "formation",
      formation: [],
      isDataLoaded: false,
    };

    try {
      setIsDataLoading(true);
      const response = await axios.get(
        `${config.endpoints.wellService}api/wells/${wellId}/${FORMATION_SECTION.urlSlug}`
      );

      if (!response || !("data" in response)) {
        formationData.isDataLoaded = true;
        setData(formationData);
        return;
      }
      formationData.formation = response.data;
      formationData.isDataLoaded = true;

      formationData.formation.map((formation: Formations) => {
        formation.uwi = uwi;
        return formation;
      });

      setData(formationData);

      //to remove if old well card is removed
      return response.data;
    } catch (errorResponse) {
      const err = errorResponse as AxiosError<APIErrorResponse>;
      setDataError(err.message);
      //to remove if old well card is removed
      return {
        status: "error",
        msg: "",
      };
    } finally {
      setIsDataLoading(false);
    }
  };

  return { getWellFormationTops, data, isDataLoading, dataError };
};

export default useWellPanelFormations;
