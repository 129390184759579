import { Typography, styled } from "@mui/material";

import { Accordion } from "component-library";

import {
  CARBON_STORAGE_LABEL,
  CARBON_STORAGE_LEGEND_ITEMS,
} from "../../../constants/map/carbonStorage";

const SquareIcon = styled("span")(({ color }: { color: string }) => ({
  backgroundColor: color,
  width: 12.95,
  height: 12,
  border: "1px solid #FFFFFF26",
  borderRadius: 2,
}));

const CarbonStorageLegend = () => {
  return (
    <Accordion
      title={CARBON_STORAGE_LABEL}
      className="layer-info-accordion"
      isDefaultOpen
    >
      <Typography className="layer-info-sub-header">
        Metric Tons (Mt)
      </Typography>
      <div className="layer-info-list multi-cols">
        {CARBON_STORAGE_LEGEND_ITEMS.map((item) => (
          <div key={item.label} className="layer-info-item carbon-storage">
            <div className="layer-info-symbol">
              <SquareIcon color={item.color} />
            </div>
            <div className="layer-info-label">{item.label}</div>
          </div>
        ))}
      </div>
    </Accordion>
  );
};

export default CarbonStorageLegend;
