import { memo } from "react";

import useChartStore from "../store/chart/chartStore";

import ChartTypeSettings from "./charts/ChartTypeSettings";
import ExportChart from "./charts/export/ExportChart";
import SagaMap from "./map/SagaMap";
import ExportPermitPanel from "./modals/permitPanel/ExportPermitPanel";
import ExportWellPanel from "./modals/wellPanel/ExportWellPanel";
import SagaModularWindow from "./modularity/SagaModularWindow";

const MapArea = () => {
  const chartConfigModalParam = useChartStore(
    (state) => state.chartSettingsModalParams
  );

  return (
    <>
      <SagaModularWindow>
        <section className="arlas-cs-map-section">
          <SagaMap />
        </section>
        <ExportChart />
        <ExportWellPanel />
        <ExportPermitPanel />
      </SagaModularWindow>

      {chartConfigModalParam.chartType && (
        <ChartTypeSettings
          open={chartConfigModalParam.open}
          chartType={chartConfigModalParam.chartType}
          chartId={chartConfigModalParam.chartId}
        />
      )}
    </>
  );
};

export default memo(MapArea);
