import { noData, specialFloat } from "../../../constants/constants";

import { convertStringToDate } from "../../../utils/charts/aggregator";
import { formattedNumber } from "../../../utils/helper";

import { getNumberWithComma } from "../../../utils";

const fieldValidation = (field, type) => {
  const validateAlphaNumeric = (str) => {
    return /^[ A-Za-z0-9_@./#&+-]*$/g.test(str);
  };

  const validateLetters = (str) => {
    return /^[A-Za-z\s]*$/.test(str);
  };

  if (type === "num") {
    if (!field) {
      return noData;
    } else if (validateLetters(field) && typeof field === "string") {
      return field;
    } else if (validateAlphaNumeric(field) && typeof field === "string") {
      return field;
    } else {
      return field ? formattedNumber(field) : noData;
    }
  } else if (type === "date") {
    return convertStringToDate(field);
  } else if (type === specialFloat) {
    return field ? formattedNumber(field, type) : noData;
  } else if (type === "special") {
    if (field) {
      let specialField = field.split(" ");
      return (
        getNumberWithComma(parseInt(specialField[0])) + " " + specialField[1]
      );
    } else {
      return noData;
    }
  } else {
    return field ? field : noData;
  }
};

export { fieldValidation };
