import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import ScatterPlotChartColorBy from "../../../../assets/images/release-notes/ScatterPlotChartColorBy.png";
import ScatterPlotExports from "../../../../assets/images/release-notes/ScatterPlotExports.png";
import WellDocumentInventoryList from "../../../../assets/images/release-notes/WellDcoumentInventoryList.png";
import WellEconomicsDataExport from "../../../../assets/images/release-notes/WellEconomicsDataExport.png";

const Version20240403: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240403"}
      onChange={handleChange("Version20240403")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.04.03
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          04/03/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes Adding Well Document Inventory List, Pipelines
          by MAPSearch™, some enhancements to scatter plots Export Cash Flow
          Model
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>• Well Document Inventory List </AccordionContent>
      <AccordionSubContent isItalic>
        o Users can now easily view available well document inventory for any
        selected well through the Well Panel feature. The integration of the
        Well Data Analytics and TGS R360 applications allows for streamlined
        workflows, making well file discovery fast and easy for the user.
      </AccordionSubContent>

      <AccordionImage
        src={WellDocumentInventoryList}
        width="100%"
        draggable={false}
      />
      <AccordionSubContent isItalic>
        o After selecting a well and navigating to the LOGS & DOCS tab within
        the well panel, the user can view well document inventory and then click
        on the DOC VIEWER icon to view the documents. Doing this will re-direct
        the user to a separate browser window. The integration with R360 now
        makes it easy to review and access well document files while working in
        Well Data Analytics
      </AccordionSubContent>

      <AccordionContent>• Scatter Plot Enhancements </AccordionContent>
      <AccordionSubContent isItalic>
        o Users can export Scatter Plot in two different formats:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent isItalic>
          ▪ Export to Image
          <br />▪ Export to Excel
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage src={ScatterPlotExports} width="100%" draggable={false} />
      <AccordionImage
        src={ScatterPlotChartColorBy}
        width="100%"
        draggable={false}
      />

      <AccordionContent>
        • Adding Well Economics Data to the Export Manager
      </AccordionContent>
      <AccordionSubContent isItalic>
        o After running a search, the user can export all the available
        Economics Data for search results through Export Manager in these
        formats as CSV files.
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent isItalic>
          ▪ Economics Summary: The Economic Summary deliverable includes
          economic inputs and outputs for each well.
          <br />▪ Cash Flow Model: The Cash Flow Model deliverable includes the
          entire month-by-month cash flow analysis for every single well in the
          search result for as long as the well is economic.
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage
        src={WellEconomicsDataExport}
        width="100%"
        draggable={false}
      />
    </Accordion>
  );
};

export default Version20240403;
