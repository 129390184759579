import { INTERPRETED_PRODUCING_FORMATION } from "../attributes";

//NOTE: CONSTANTS HERE ARE USED BY CHART EXPORT TO EXCEL
export const PRODUCTION_DATA_FIELDS = [
  {
    name: "Name",
    displayName: "Name",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "name",
    responseDataOrigin: "name",
  },

  // for individual wells only
  {
    name: "uwi",
    displayName: "UWI",
    hasForecast: false,
    responseDataFieldName: "uwi",
    responseDataOrigin: "uwi",
  },
  {
    name: "wellName",
    displayName: "Well Name",
    hasForecast: false,
    responseDataFieldName: "wellName",
    responseDataOrigin: "wellName",
  },
  {
    name: "wellNumber",
    displayName: "Well Number",
    hasForecast: false,
    responseDataFieldName: "wellNumber",
    responseDataOrigin: "wellNumber",
    alwaysOn: true,
  },
  {
    name: "Date",
    displayName: "Date",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "date",
    responseDataOrigin: "date",
  },
  {
    name: "Sequence Month",
    displayName: "Sequence Month",
    hasForecast: false,
    responseDataFieldName: "month",
    responseDataOrigin: "month",
  },
  // end for individual wells only

  {
    name: "Oil",
    displayName: "Oil (Monthly)",
    dailyDisplayName: "Oil (Daily)",
    hasForecast: true,
    hasDaily: true,
    responseDataFieldName: "oil",
    responseDataOrigin: "mdata",
  },
  {
    name: "Oil",
    displayName: "Oil (Cumulative)",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "oil",
    responseDataOrigin: "cdata",
  },
  {
    name: "Gas",
    displayName: "Gas (Monthly)",
    dailyDisplayName: "Gas (Daily)",
    hasForecast: true,
    hasDaily: true,
    responseDataFieldName: "gas",
    responseDataOrigin: "mdata",
  },
  {
    name: "Gas",
    displayName: "Gas (Cumulative)",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "gas",
    responseDataOrigin: "cdata",
  },
  {
    name: "Boe",
    displayName: "BOE (Monthly)",
    dailyDisplayName: "BOE (Daily)",
    hasForecast: true,
    hasDaily: true,
    responseDataFieldName: "bOE",
    responseDataOrigin: "mdata",
  },
  {
    name: "Boe",
    displayName: "BOE (Cumulative)",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "bOE",
    responseDataOrigin: "cdata",
  },
  {
    name: "Water",
    displayName: "Water (Monthly)",
    dailyDisplayName: "Water (Daily)",
    hasForecast: true,
    hasDaily: true,
    responseDataFieldName: "wtr",
    responseDataOrigin: "mdata",
  },
  {
    name: "Water",
    displayName: "Water (Cumulative)",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "wtr",
    responseDataOrigin: "cdata",
  },
  {
    name: "DaysOnProduction",
    displayName: "Days On Production",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "dOP",
    responseDataOrigin: "mdata",
  },
  {
    name: "WaterOilRatio",
    displayName: "Water-Oil Ratio",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "wtrOR",
    responseDataOrigin: "mdata",
  },
  {
    name: "WaterCut",
    displayName: "Water Cut",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "wtrC",
    responseDataOrigin: "mdata",
  },
  {
    name: "GasOilRatio",
    displayName: "Gas-Oil Ratio",
    dailyDisplayName: "",
    hasForecast: true,
    hasDaily: false,
    responseDataFieldName: "gasOR",
    responseDataOrigin: "mdata",
  },
  {
    name: "TotalLiquid",
    displayName: "Total Injected Liquid (Monthly)",
    dailyDisplayName: "Total Injected Liquid (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "tLqd",
    responseDataOrigin: "mdata",
  },
  {
    name: "TotalLiquid",
    displayName: "Total Injected Liquid (Cumulative)",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "tLqd",
    responseDataOrigin: "cdata",
  },
  {
    name: "TotalGas",
    displayName: "Total Injected Gas (Monthly)",
    dailyDisplayName: "Total Injected Gas (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "tGas",
    responseDataOrigin: "mdata",
  },
  {
    name: "TotalGas",
    displayName: "Total Injected Gas (Cumulative)",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "tGas",
    responseDataOrigin: "cdata",
  },
  {
    name: "MaxInjPressure",
    displayName: "Max Injection Pressure",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "mIP",
    responseDataOrigin: "mdata",
  },
  {
    name: "AvInjPressure",
    displayName: "Average Injection Pressure",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "aIP",
    responseDataOrigin: "mdata",
  },
  {
    name: "DaysOnInjection",
    displayName: "Days On Injection",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "dOI",
    responseDataOrigin: "mdata",
  },
  {
    name: "TotalDisposed",
    displayName: "Total Disposed Volume",
    dailyDisplayName: "Total Disposed Volume (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "tD",
    responseDataOrigin: "mdata",
  },
  {
    name: "Vent",
    displayName: "Vent",
    dailyDisplayName: "Vent (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "vnt",
    responseDataOrigin: "mdata",
  },
  {
    name: "Flare",
    displayName: "Flare",
    dailyDisplayName: "Flare (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "flr",
    responseDataOrigin: "mdata",
  },
  {
    name: "VentFlare",
    displayName: "Vent or Flare",
    dailyDisplayName: "Vent or Flare (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "vntFlr",
    responseDataOrigin: "mdata",
  },
  {
    name: "TotalUsed",
    displayName: "Total Used Volume",
    dailyDisplayName: "Total Used Volume (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "tUsed",
    responseDataOrigin: "mdata",
  },
  {
    name: "Used",
    displayName: "Used",
    dailyDisplayName: "Used (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "used",
    responseDataOrigin: "mdata",
  },
  {
    name: "Inferred",
    displayName: "Inferred",
    dailyDisplayName: "Inferred (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "inf",
    responseDataOrigin: "mdata",
  },
  {
    name: "Other",
    displayName: "Other",
    dailyDisplayName: "Other (Daily)",
    hasForecast: false,
    hasDaily: true,
    responseDataFieldName: "othr",
    responseDataOrigin: "mdata",
  },

  // for excel forecast column arrangement
  {
    name: "Oil",
    displayName: "Oil (Monthly) Forecast",
    dailyDisplayName: "Oil (Daily) Forecast",
    hasDaily: true,
  },

  {
    name: "Oil",
    displayName: "Oil (Cumulative) Forecast",
    dailyDisplayName: "",
    hasDaily: false,
  },

  {
    name: "Gas",
    displayName: "Gas (Monthly) Forecast",
    dailyDisplayName: "Gas (Daily) Forecast",
    hasDaily: true,
  },

  {
    name: "Gas",
    displayName: "Gas (Cumulative) Forecast",
    dailyDisplayName: "",
    hasDaily: false,
  },
  {
    name: "Boe",
    displayName: "BOE (Monthly) Forecast",
    dailyDisplayName: "BOE (Daily) Forecast",
    hasDaily: true,
  },
  {
    name: "Boe",
    displayName: "BOE (Cumulative) Forecast",
    dailyDisplayName: "",
    hasDaily: false,
  },
  {
    name: "Water",
    displayName: "Water (Monthly) Forecast",
    dailyDisplayName: "Water (Daily) Forecast",
    hasDaily: true,
  },

  {
    name: "Water",
    displayName: "Water (Cumulative) Forecast",
    dailyDisplayName: "",
    hasDaily: false,
  },

  {
    name: "WaterOilRatio",
    displayName: "Water-Oil Ratio Forecast",
    dailyDisplayName: "",
    hasDaily: false,
  },

  {
    name: "WaterCut",
    displayName: "Water Cut Forecast",
    hasDaily: false,
  },

  {
    name: "GasOilRatio",
    displayName: "Gas-Oil Ratio Forecast",
    hasDaily: false,
  },
];

export const GROUP_BY_MAP: { [key: string]: string } = {
  DisplayFormation: "ProducingFormation",
  InterpretedProducingFormation: INTERPRETED_PRODUCING_FORMATION.label,
  OperatorName: "CurrentOperator",
  UltimateOwner: "UltimateOwner",
  OriginalOperator: "OriginalOperator",
  LeaseName: "LeaseName",
  LeaseID: "LeaseNameAndID",
  WellStatus: "WellStatus",
  WellType: "WellType",
  CalculatedMajorPhase: "MajorPhase",
  LastInjectionType: "InjectionType",
  LastInjectionFormation: "InjectionFormation",
  DispositionType: "GasDispositionType",
  BasinName: "Basin",
  FieldName: "Field",
  SurfaceUWI: "SurfaceUWI",
  County: "County",
  Slant: "Slant",
  Default: "ALL",
  NONE: "IndividualWells",
};
