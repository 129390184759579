import { FC } from "react";

import { PermitPanelSectionProp } from "../../../../types/panels/permitPanel/permitPanel";

import {
  PERMIT_OPERATOR_CONTACT_INFO,
  PERMIT_PANEL_SECTION,
} from "../../../../constants/panels/permitPanel/permit";

import { checkFormatValidation } from "../../../../utils/panel/wellPanel/fieldValidation";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../../wellPanel/common/WellPanelAccordion";

const OperatorContact: FC<PermitPanelSectionProp> = ({
  isExpanded,
  onChange,
  data,
}) => {
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={PERMIT_PANEL_SECTION.OPERATOR_CONTACT_SECTION.displayLabel}
      onChange={() => onChange(PERMIT_PANEL_SECTION.OPERATOR_CONTACT_SECTION)}
      isLoading={false}
    >
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_NAME.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_NAME.key]
            )}
          />
        </div>
      </div>
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_CONTACT.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_CONTACT.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.PHONE_NUMBER.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.PHONE_NUMBER.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.ADDRESS.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.ADDRESS.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.CITY.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.CITY.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.STATE.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.STATE.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_OPERATOR_CONTACT_INFO.ZIP_CODE.label}
            value={checkFormatValidation(
              data[PERMIT_OPERATOR_CONTACT_INFO.ZIP_CODE.key]
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default OperatorContact;
