import { Box } from "@mui/material";

import { Button, Modal } from "component-library";

import useExportPackageManager from "../../../customHooks/exports/useExportPackageManager";

const ExportPackageManager = () => {
  const { preventExport, exportAlertMessage, handleClose } =
    useExportPackageManager();

  return (
    <Modal
      title="Alert"
      className="export-alert-modal"
      dialogContent={exportAlertMessage}
      dialogActions={
        <Box className="alert-action-box">
          <Button text="Okay" onClick={handleClose} />
        </Box>
      }
      size="xs"
      onClose={handleClose}
      show={preventExport}
    />
  );
};

export default ExportPackageManager;
