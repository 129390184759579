import { useCallback, useEffect, useMemo } from "react";

import { useOktaAuth } from "@okta/okta-react";

import LayerGroup from "ol/layer/Group";

import { Layer as DeckLayer } from "deck.gl";

import { DVT } from "../../types/map";
import {
  AddLayerType,
  RemoveLayerType,
  UseUpdateDVTLayersProps,
} from "../../types/map/customHooks/useUpdateMapLayer";

import config from "../../configs/appSettings";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  DYNAMIC_VECTOR_TILES,
  PERMIT_DVT_LAYERS,
  WELL_DVT_LAYERS,
  WELL_SPOTS,
} from "../../constants/constants";
import { DRAW_LAYER } from "../../constants/map/layers";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

import { getToken } from "../../utils/common/getToken";

import {
  createLayerFromLayerDefinition,
  getLayerDefinitionsKeys,
  getLayerGroupNamesByFetchType,
} from "../../utils";
import useSearchCriteria from "../search/useSearchCriteria";
import usePrevious from "../usePrevious";
import useBubbleMapInfo from "./mapSettings/useBubbleMapInfo";
import useLayerStylingInfo from "./mapSettings/useLayerStylingInfo";
import useSagaMap from "./useSagaMap";

const useUpdateDVTLayers = ({
  getDVTQuery,
  cartoClickProps,
  selectedLayerNames,
}: UseUpdateDVTLayersProps) => {
  const { authState } = useOktaAuth();
  const { hasSearchCriteria } = useSearchCriteria();
  const { dvtLayersByRecordType } = useSagaMap();

  const map = useMapStore((state) => state.map);
  const deckGl = useMapStore((state) => state.deckGl);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const layers = useMapStore((state) => state.layers);

  const DVTQueryLoading = useMapStore((state) => state.DVTQueryLoading);
  const updateDVTQueryLoading = useMapStore(
    (state) => state.updateDVTQueryLoading
  );
  const DVTQuery = useMapStore((state) => state.DVTQuery);
  const updateDVTQuery = useMapStore((state) => state.updateDVTQuery);

  const DVTUpdated = useMapStore((state) => state.DVTUpdated);

  const isHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.isHighlightSelectedSpots
  );
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const layerLegendColors = useMapSettingsStore(
    (state) => state.layerLegendColors
  );

  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );
  const dataGridSelector = useDataGridStore((state) => state.allWellSelectors);

  const updateDVTProcessing = useStore((state) => state.updateDVTProcessing);
  const updateViewportChanged = useStore(
    (state) => state.updateViewportChanged
  );

  // Map - Parent Well IDs
  const selectedMapParentWellIDs = useMapSelectionStore(
    (state) => state.selectedMapParentWellIDs
  );

  // Map - UWI12s
  const selectedBottomWellboreIDs = useMapSelectionStore(
    (state) => state.selectedBottomWellboreIDs
  );

  const selectedPermitIds = useMapSelectionStore(
    (state) => state.selectedPermitIds
  );

  const selectedWellCardPWIDs = usePanelsStore(
    (state) => state.selectedWellCardPWIDs
  );
  const selectedWellCardBWIDs = usePanelsStore(
    (state) => state.selectedWellCardBWIDs
  );
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );

  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );

  // Search Panel
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);

  const selectedLayersWithDVT = useMemo(() => {
    const layerNamesWithDVT = getLayerGroupNamesByFetchType(
      getLayerDefinitionsKeys(),
      DYNAMIC_VECTOR_TILES
    );

    return layerNamesWithDVT.filter((layerName) =>
      selectedLayerNames.includes(layerName)
    );
  }, [selectedLayerNames]);

  const stringifiedSelectedLayersWithDVT = useMemo(
    () => JSON.stringify(selectedLayersWithDVT),
    [selectedLayersWithDVT]
  );

  const prevDVTUpdated = usePrevious(DVTUpdated);
  const isDVTUpdatedChange = useMemo(
    () => DVTUpdated !== prevDVTUpdated,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [DVTUpdated]
  );

  const { getLayerStylingInfo } = useLayerStylingInfo();
  const { getBubbleMapInfo } = useBubbleMapInfo();

  const isLayerSelected = useCallback(
    (layerName: string) => {
      return !!layers.find((layer) => layer.name === layerName)?.isSelected;
    },
    [layers]
  );

  const isSurfaceSelected = useMemo(
    () => isLayerSelected(WELL_SPOTS),
    [isLayerSelected]
  );

  const isBottomSelected = useMemo(
    () => isLayerSelected(BOTTOM_WELL_SPOTS),
    [isLayerSelected]
  );

  const removeLayers: RemoveLayerType = useCallback(
    async (layerTitlesToRemove) => {
      if (!map || !deckGl || !getToken(authState)) {
        return;
      }

      const displayedLayers = map
        .getLayers()
        .getArray()
        .filter((layer) => {
          const isBaseMap = layer.get("isBaseMap");
          const isDrawLayer = layer.get("title") === DRAW_LAYER;

          return !isBaseMap && !isDrawLayer;
        });

      displayedLayers.forEach((displayedLayer) => {
        const layerTitle = displayedLayer.get("title");
        if (layerTitlesToRemove.includes(layerTitle)) {
          map.removeLayer(displayedLayer);

          const layerDefinition = config.layerDefinitions[layerTitle];
          const layers = deckGl.props.layers as DeckLayer[];

          const layerTitlesToRemove: string[] = [];
          layerDefinition.layers.forEach((layer) => {
            const l = layer as DVT;
            layerTitlesToRemove.push(l.title || layerTitle);
          });
          deckGl.setProps({
            layers: [
              ...layers.filter(
                (cartoLayer) => !layerTitlesToRemove.includes(cartoLayer.id)
              ),
            ],
          });
        }
      });
    },
    [authState, deckGl, map]
  );

  const addLayers: AddLayerType = useCallback(
    async (layerTitlesToDisplay) => {
      if (!map || !deckGl || !getToken(authState)) {
        return;
      }

      const displayedLayers = map
        .getLayers()
        .getArray()
        .filter((layer) => {
          const isBaseMap = layer.get("isBaseMap");
          const isDrawLayer = layer.get("title") === DRAW_LAYER;

          return !isBaseMap && !isDrawLayer;
        })
        .map((layer) => layer.get("title"));

      const layersToDisplay = layerTitlesToDisplay
        // only add layers that aren't displayed
        .filter(
          (layerTitleToDisplay) =>
            !displayedLayers.includes(layerTitleToDisplay)
        )
        .map(async (layerTitleToDisplay) => {
          const { compressedWellSpotData, compressedWellSpotInfo } =
            getLayerStylingInfo(layerTitleToDisplay);
          const { compressedBubbleMapData, compressedBubbleMapInfo } =
            getBubbleMapInfo();

          return await createLayerFromLayerDefinition({
            definitionKey: layerTitleToDisplay,
            zoom: currentZoom,
            hasBubbleMap:
              !!layers.find((layer) => layer.name === BUBBLE_MAP)?.isSelected &&
              (isSurfaceSelected || isBottomSelected) &&
              hasSearchCriteria &&
              currentZoom >= 12,
            DVTQuery,
            getDVTQuery,
            initialGridSearchMade,
            cartoIsEnabledByZoomLevel: currentZoom >= 12,
            updateDVTProcessing,
            updateViewportChanged,
            currentDeckGl: deckGl,
            layerStyles,
            layerLegendColors,
            compressedWellSpotData,
            compressedWellSpotInfo,
            compressedBubbleMapData,
            compressedBubbleMapInfo,
            selectedMapParentWellIDs,
            selectedBottomWellboreIDs,
            selectedPermitIds,
            selectedWellCardPWIDs,
            selectedWellCardBWIDs,
            selectedCardPermitIDs,
            isBottomSelected,
            isHighlightSelectedSpots,
            dataGridSelector,
            token: getToken(authState) ?? "",
            visible: isLayerSelected(layerTitleToDisplay),
            cartoClickProps,
          });
        });

      const waitedLayersToDisplay = (await Promise.all(
        layersToDisplay
      )) as LayerGroup[];

      waitedLayersToDisplay.forEach((layerToDisplay) => {
        map.addLayer(layerToDisplay);
      });

      return waitedLayersToDisplay;
    },
    [
      authState,
      deckGl,
      map,
      DVTQuery,
      cartoClickProps,
      currentZoom,
      getDVTQuery,
      hasSearchCriteria,
      initialGridSearchMade,
      isBottomSelected,
      isSurfaceSelected,
      isLayerSelected,
      layers,
      updateDVTProcessing,
      updateViewportChanged,
      layerLegendColors,
      layerStyles,
      selectedMapParentWellIDs,
      selectedBottomWellboreIDs,
      selectedPermitIds,
      isHighlightSelectedSpots,
      dataGridSelector,
      getLayerStylingInfo,
      getBubbleMapInfo,
    ]
  );

  // const updateMapLayers: UpdateLayersType = useCallback(async () => {
  //   if (!map) return;
  //   const layersToRemove = getDVTLayerTitlesToRemove(
  //     map,
  //     selectedLayersWithDVT
  //   );
  //   removeLayers(layersToRemove);

  //   const waitedLayersToDisplay = await addLayers(selectedLayersWithDVT);

  //   return { layersToRemove, waitedLayersToDisplay };
  // }, [map, selectedLayersWithDVT, addLayers, removeLayers]);

  // Hard refresh layers
  // Mannually removing them then adding them again
  const hardRefreshMapLayers = useCallback(
    (layersToRemove: string[], layersToAdd: string[]) => {
      // const finalLayersToRefresh = layersToRefresh?.length
      //   ? layersToRefresh
      //   : selectedLayersWithDVT;
      removeLayers(layersToRemove);
      addLayers(layersToAdd);
    },
    [removeLayers, addLayers]
  );

  // const smartMapLayerUpdate: SmartMapLayerUpdateType = useCallback(async () => {
  //   if (!map) return;

  //   const layersThen = getMapLayerGroupsByValue(map, "title")
  //     .filter((layerName) => CARTO_LAYER_GROUP_NAMES.includes(layerName))
  //     .filter(onlyUnique);

  //   const waitedLayers = await updateMapLayers();
  //   if (!waitedLayers) return;

  //   const { layersToRemove, waitedLayersToDisplay } = waitedLayers;

  //   // If no layers was removed/added in OpenLayers
  //   // (This is possible when Search Criteria is changed, but not selected layers)
  //   if (
  //     fetchedSavedSearchTrigger || // Refresh if it comes from a saved search
  //     (!layersToRemove.length && !waitedLayersToDisplay?.length) || // Refresh if it is a change of criteria
  //     isDVTUpdatedChange // Refresh if reset with different toggled layer and search criteria
  //   ) {
  //     // to update query even if layers have not changed
  //     hardRefreshMapLayers(
  //       layersThen.filter((layerName) => isLayerSelected(layerName))
  //     );
  //   }
  // }, [
  //   map,
  //   fetchedSavedSearchTrigger,
  //   updateMapLayers,
  //   hardRefreshMapLayers,
  //   isLayerSelected,
  //   isDVTUpdatedChange,
  // ]);

  const hasMap = useMemo(() => !!map, [map]);

  useEffect(() => {
    // Only call layer update once when DVTQueryLoading is first an empty object
    if (hasMap && JSON.stringify(DVTQueryLoading) == "{}") {
      // smartMapLayerUpdate();
      hardRefreshMapLayers(
        [...WELL_DVT_LAYERS, ...PERMIT_DVT_LAYERS],
        dvtLayersByRecordType
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DVTQueryLoading, hasMap]);

  // Trigger a load that recognizes new layers to add or remove
  useEffect(() => {
    updateDVTQueryLoading();
  }, [stringifiedSelectedLayersWithDVT, updateDVTQueryLoading]);

  // Trigger a layer update when search criteria has changed
  // Needs to be separate from selectedLayerNames for isolated restart of DVTQuery
  useEffect(() => {
    updateDVTQuery();
    updateDVTQueryLoading();
  }, [
    searchCriteria,
    dvtLayersByRecordType,
    updateDVTQuery,
    updateDVTQueryLoading,
  ]);

  return { removeLayers, addLayers };
};

export default useUpdateDVTLayers;
