import { ChangeEvent, FC, useState } from "react";

import { Tab, Tabs, styled } from "@mui/material";

import useReleaseNotesStore from "../../store/releaseNotes/releaseNotesStore";

import ReleaseNotesAccordion from "./ReleaseNotes/ReleaseNotesAccordion";
import TabPanel from "./ReleaseNotes/ReleaseNotesTabPanel";
import WhatsNext from "./WhatsNext/WhatsNext";
import StyleOverrideThemeProvider from "./styleOverridesTheme";

import { CloseIcon } from "../../assets/CustomIcons";

const CloseIconContainer = styled("div")({
  position: "absolute",
  right: 16,
  zIndex: 9,
});

const LineSeperator = styled("div")({
  height: 1,
  width: "100%",
  background: "#fff",
  marginTop: -1,
});

const CustomTab = styled(Tab)({
  color: "#fff",
  fontSize: 13,
  fontWeight: "400 !Important",
  width: "162px !important",
});

const InformationTabs: FC = () => {
  const updateIsOpenReleaseNotes = useReleaseNotesStore(
    (state) => state.updateIsOpenReleaseNotes
  );

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (_: ChangeEvent<object>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <StyleOverrideThemeProvider>
      <CloseIconContainer>
        <CloseIcon
          viewBox="0 0 28 28"
          onClick={() => updateIsOpenReleaseNotes(false)}
        />
      </CloseIconContainer>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <CustomTab label="RELEASE NOTES" />
        <CustomTab label="WHAT'S NEXT" />
      </Tabs>
      <LineSeperator />
      <TabPanel activePanel={0} value={activeTab}>
        <ReleaseNotesAccordion />
      </TabPanel>
      <TabPanel activePanel={1} value={activeTab}>
        <WhatsNext />
      </TabPanel>
    </StyleOverrideThemeProvider>
  );
};

export default InformationTabs;
