import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import LogCurveAttributes from "../../../../assets/images/release-notes/LogCurveAttributes.png";
import MidlandBasinWellEconomics from "../../../../assets/images/release-notes/MidlandBasinWellEconomics.png";
import ScatterPlotMultipleYAxisOptions from "../../../../assets/images/release-notes/ScatterPlotMultipleYAxisOptions.png";
import WellCardPanelVisioning1 from "../../../../assets/images/release-notes/WellCardPanelVisioning1.png";
import WellCardPanelVisioning2 from "../../../../assets/images/release-notes/WellCardPanelVisioning2.png";

const Version20231221: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20231221"}
      onChange={handleChange("Version20231221")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2023.12.21
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          12/21/2023
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes the release of Midland Basin Well Economics and
          Log Curve attributes and enhancements to well card view and scatter
          plot.
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>
        • Added Well Economics for Midland Basin wells
      </AccordionContent>
      <AccordionSubContent isItalic>
        o Well data analytics users can access the new TGS well economics
        dataset via the Well Data Analytics software.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Default cost input and economic output values for all Midland Basin
        wells
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Exportable economic model
      </AccordionSubContent>
      <AccordionImage
        src={MidlandBasinWellEconomics}
        width="100%"
        draggable={false}
      />

      <AccordionContent>
        • Enhancements to Scatter Plot: Multiple Y axis option
      </AccordionContent>
      <AccordionSubContent isItalic>
        o Well Data Analytics users can open a scatter plot chart with multiple
        Y axis capabilities and analyze search results data relationships.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Designed default Scatter Plot is Completion Data vs BOE Total Cum but
        other attributes can be selected for X and up to 5 Y axis under Setting
        dropdown window.
      </AccordionSubContent>
      <AccordionImage
        src={ScatterPlotMultipleYAxisOptions}
        width="100%"
        draggable={false}
      />

      <AccordionContent>• New Well Card/Panel Visioning</AccordionContent>
      <AccordionSubContent isItalic>
        o We have changed Well Card view for better analysis and added new and
        improved well hover label to cover more information for each well.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Added two sections: Well Info and Charts & Plots
      </AccordionSubContent>
      <AccordionImage
        src={WellCardPanelVisioning1}
        width="100%"
        draggable={false}
      />
      <AccordionImage
        src={WellCardPanelVisioning2}
        width="100%"
        draggable={false}
      />

      <AccordionContent>
        • Added Log Curve attributes for Midland Basin wells
      </AccordionContent>
      <AccordionSubContent isItalic>
        o These five attributes are derived from TGS’s extensive log data
        library and are assigned as new attributes to each well:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ VShale
          <br />
          ▪ Density
          <br />
          ▪ Neutron Porosity
          <br />
          ▪ Resistivity
          <br /> ▪ Sonic
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o These attributes are available in Scatter Plot and search results grid
        table
      </AccordionSubContent>
      <AccordionImage src={LogCurveAttributes} width="100%" draggable={false} />
    </Accordion>
  );
};

export default Version20231221;
