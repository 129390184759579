const WellPanelDashedLineIcon = () => {
  return (
    <div>
      <svg
        width="2"
        height="30"
        viewBox="0 0 1 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.334875 0.03125L0.334966 55.5078"
          stroke="#0091EA"
          strokeWidth="2"
          strokeDasharray="4 4"
        />
      </svg>
    </div>
  );
};

export default WellPanelDashedLineIcon;
