import { FC, useEffect } from "react";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";

import { permitColumns } from "../../../../constants/panels/permitPanel/permit";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellPanelPermits from "../../../../customHooks/panels/wellPanel/useWellPanelPermits";

import WellCardTable from "../../../WellCard/WellCardTable";
import WellPanelAccordion from "../common/WellPanelAccordion";

const Permit: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
}) => {
  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsPermitsLoading = usePanelsStore(
    (state) => state.updateIsPermitsLoading
  );

  const { data, isDataLoading, getWellPermits } = useWellPanelPermits();

  useEffect(() => {
    if (data?.isDataLoaded) {
      updateWellCardDataByTypeAndKey(
        data,
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsPermitsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (
      wellData.pageNumber === activePage &&
      !wellData.permitsData?.isDataLoaded
    ) {
      getWellPermits(
        wellData.wellCardData.wellId as number,
        wellData.wellCardData.uwi as string
      );
      updateIsPermitsLoading(true);
    }
  }, [wellData.pageNumber, wellData.permitsData, activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.PERMIT_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.PERMIT_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isDataLoading}
    >
      {wellData.permitsData?.permit.length && (
        <WellCardTable
          TableData={wellData.permitsData?.permit}
          TableHeader={permitColumns}
          tabContentTableSize={285}
          isLoading={isDataLoading}
        />
      )}
    </WellPanelAccordion>
  );
};

export default Permit;
