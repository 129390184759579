import dayjs from "dayjs";

import { DVT } from "../../../types/map";
import { GetTopAttributesResponse } from "../../../types/map/mapSettings/useTopAttributeValues";

import appSettings from "../../../configs/appSettings";

import {
  DATE,
  FLOAT,
  INTEGER,
  SPECIAL,
  TEXT,
} from "../../../constants/attributes";
import { BUBBLE_MAP, DYNAMIC_VECTOR_TILES } from "../../../constants/constants";
import {
  COLOR_BY_ATTRIBUTE_INFO,
  DEFAULT_ATTRIBUTE_COLOR,
  DEFAULT_BUBBLE_MAP_SIZES_IN_PIXELS,
  DEFAULT_COLOR,
  DEFAULT_GRADIENT_COLORS,
  DEFAULT_SIZE,
  DEFAULT_TOP_COLORS,
  SIZE,
} from "../../../constants/map/mapSettings";

import { formattedNumber } from "../../../utils/helper";
import { formatNumberByAttribute } from "../../../utils/numberFormatter";

export const getDefaultCartoStyle = <
  T extends typeof SIZE | typeof DEFAULT_SIZE | typeof DEFAULT_COLOR
>(
  layerKey: string,
  styleKey: T
): DVT[T] | undefined => {
  const layer = appSettings.layerDefinitions[layerKey]?.layers.find(
    (keys): keys is DVT => keys.fetchType === DYNAMIC_VECTOR_TILES
  );
  return layer?.[styleKey];
};

export const getZIndexByTitle = (layerKey: string) => {
  let searchedLayer;
  for (const layerDefinitionKey in appSettings.layerDefinitions) {
    const layerDefinition = appSettings.layerDefinitions[layerDefinitionKey];
    const layer = layerDefinition.layers.find(
      (layer) => "title" in layer && layer.title === layerKey
    );
    if (layer) {
      searchedLayer = layer;
      break;
    }
  }
  return searchedLayer?.zIndex || 50;
};

export const getDefaultColorList = (
  topAttribValues: GetTopAttributesResponse,
  colorByKey: string,
  min: number | null,
  max: number | null,
  hasNull: boolean,
  initialGridSearchMade: boolean
) => {
  const attributeAllOthers = {
    key: "All Others",
    label: "All Others",
    value: DEFAULT_ATTRIBUTE_COLOR,
  };

  if (!initialGridSearchMade) return [];

  const dataType = COLOR_BY_ATTRIBUTE_INFO[colorByKey].dataType;

  switch (dataType) {
    case FLOAT:
    case INTEGER:
    case DATE:
      const numDateColorList = [];
      if (max !== null) {
        numDateColorList.push({
          key: "high",
          label:
            dataType === DATE
              ? dayjs(max).format("YYYY-MM-DD")
              : formatNumberByAttribute(max, colorByKey),
          value: DEFAULT_GRADIENT_COLORS[1],
        });

        if (min !== null && min !== max) {
          numDateColorList.push({
            key: "low",
            label:
              dataType === DATE
                ? dayjs(min).format("YYYY-MM-DD")
                : formatNumberByAttribute(min, colorByKey),
            value: DEFAULT_GRADIENT_COLORS[0],
          });
        }
      }

      if (hasNull) numDateColorList.push(attributeAllOthers);

      return numDateColorList;
    case SPECIAL:
    case TEXT:
      const varcharColorList = topAttribValues.map((name, idx) => {
        if (name === "All Others") {
          return attributeAllOthers;
        }
        return {
          key: name,
          label: name,
          value: DEFAULT_TOP_COLORS[idx],
        };
      });
      return varcharColorList;
    default:
      return [];
  }
};

export const getDefaultSizeList = (
  min: number | null,
  max: number | null,
  initialGridSearchMade: boolean
) => {
  const defaultSize = getDefaultCartoStyle(BUBBLE_MAP, "defaultSize") ?? 0;

  const uniformAllOthers = {
    key: "All Bubbles",
    label: "All Bubbles",
    value: defaultSize,
  };

  if (!initialGridSearchMade) return [uniformAllOthers];

  const sizeList = [];
  if (min !== null || max !== null) {
    const sizes = DEFAULT_BUBBLE_MAP_SIZES_IN_PIXELS;
    sizeList.push({
      key: "high",
      label: formattedNumber(max),
      value: sizes[sizes.length - 1],
    });

    if (min !== max)
      sizeList.push({
        key: "low",
        label: formattedNumber(min),
        value: sizes[0],
      });
  }

  return sizeList;
};
