import { FrameState } from "ol/Map";
import { getTopLeft } from "ol/extent";
import { DEVICE_PIXEL_RATIO } from "ol/has.js";
import Layer, { RenderFunction } from "ol/layer/Layer";
import TileLayer from "ol/layer/Tile";
import { toLonLat } from "ol/proj";
import { WMTS as oLWMTS } from "ol/source";
import WMTSTileGrid from "ol/tilegrid/WMTS";

import { VectorTileLayer, vectorQuerySource } from "@deck.gl/carto";

import { Color, Layer as DeckLayer } from "deck.gl";

import { Feature } from "../../../types/map/layers/carto";
import { CommonLayer } from "../../../types/map/layers/common";
import { GetDVTLayer, ProcessDVT } from "../../../types/map/layers/dvt";

import config from "../../../configs/appSettings";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  CARTO_MAX_ZOOM,
  CARTO_MIN_ZOOM,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_WELL_SPOTS,
  GEOPOINT,
  PERMIT_SPOTS,
  WELL_PATHS,
  WELL_PATHS_GEO_POINT,
  WELL_SPOTS,
  WELL_STICKS,
  WELL_STICKS_GEO_POINT,
  WMTS_FETCH_TYPE,
} from "../../../constants/constants";
import {
  COLOR_BY_ATTRIBUTE_INFO,
  SIZE_BY_ATTRIBUTE_INFO,
} from "../../../constants/map/mapSettings";

import { getZIndexByTitle } from "../../../components/charts/utils/mapSettingsUtils";

import { getColorByAttribute } from "../../../data/map/utils/colorAggregator";

import { cartoClickCallback } from "./cartoClickCallback";
import {
  getIconPoinTypeConfig,
  getIconPointTypeUpdateTriggers,
  getIconSizeTriggers,
} from "./iconPointType/iconPointTypeConfig";
import {
  getCartoLineColor,
  getCartoLineWidthPixels,
  getCartoOpacity,
  getCartoPointRadius,
  getCartoPointRadiusUnits,
  getHighlightCartoConfig,
  getUpdateTriggersPointRadius,
} from "./layerStyleUtils";
import { returnAsWMTS, tileLoadFetcher } from "./tileUtils";
import { getTileCalculations } from "./wmtsUtils";

const getDVTLayer = async ({
  layer,
  definitionKey,
  initialGridSearchMade,
  hasBubbleMap,
  cartoIsEnabledByZoomLevel,
  currentDeckGl,
  layerStyles,
  layerLegendColors,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
  selectedCardPermitIDs,
  isHighlightSelectedSpots,
  selectedMapParentWellIDs,
  selectedBottomWellboreIDs,
  selectedPermitIds,
  compressedWellSpotData,
  compressedWellSpotInfo,
  compressedBubbleMapData,
  compressedBubbleMapInfo,
  DVTQuery,
  isBottomSelected,
  zoom,
  updateDVTProcessing,
  updateViewportChanged,
  layers,
  visible,
  token,
  cartoClickProps,
  getDVTQuery,
  dataGridSelector,
}: GetDVTLayer) => {
  if (initialGridSearchMade || cartoIsEnabledByZoomLevel) {
    return await processDynamicVectorTile({
      accessToken: token,
      connection: layer.connection,
      // data: layer.data,
      title: definitionKey,
      subtitle: layer.title,
      color: layer.defaultColor,
      currentDeckGl,
      layerStyles,
      layerLegendColors,
      selectedMapParentWellIDs,
      selectedBottomWellboreIDs,
      selectedPermitIds,
      selectedWellCardPWIDs,
      selectedWellCardBWIDs,
      selectedCardPermitIDs,
      DVTQuery,
      getDVTQuery,
      zIndex: layer.zIndex,
      geoColumn: layer.geoColumn,
      minZoom: layer.minZoom ? layer.minZoom : 0,
      size: layer.defaultSize ? layer.defaultSize : 0,
      lineColor: layer.defaultLineColor,
      isBottomSelected,
      zoom,
      dataGridSelector,
      // ...(!layer.clickDisabled && {
      onClick: (info, event) =>
        cartoClickCallback({
          ...cartoClickProps,
          info,
          event,
        }),
      // }),
      updateDVTProcessing,
      updateViewportChanged,
      linkToOpenlayersMap: true,
      visible,
      compressedWellSpotInfo,
      compressedWellSpotData,
      compressedBubbleMapInfo,
      compressedBubbleMapData,
      isProcessingStyles: false,
      isHighlightSelectedSpots,
      initialGridSearchMade,
    });
  } else {
    const WMTSLayer = layers.find(
      (layer) => layer.fetchType === WMTS_FETCH_TYPE
    );
    if (WMTSLayer) {
      const wmtsLayer = returnAsWMTS(WMTSLayer);
      const tileCalculations = getTileCalculations(wmtsLayer);

      if (tileCalculations && wmtsLayer.projection) {
        const hiDPI = DEVICE_PIXEL_RATIO > 1;
        const layer = hiDPI ? "bmaphidpi" : "geolandbasemap";
        const tilePixelRatio = hiDPI ? 2 : 1;

        const layerAttributes = {
          title: definitionKey,
          visible,
          source: new oLWMTS({
            matrixSet: wmtsLayer.projection,
            url: wmtsLayer.url,
            tileLoadFunction: (tile, src) => {
              tileLoadFetcher(tile, src, token);
            },
            tilePixelRatio,
            projection: tileCalculations.projection,
            tileGrid: new WMTSTileGrid({
              origin: getTopLeft(tileCalculations.projectionExtent),
              resolutions: tileCalculations.resolutions,
              matrixIds: tileCalculations.matrixIds,
            }),
            wrapX: true,
            crossOrigin: "anonymous",
            layer: "",
            style: "",
          }),
          ...(wmtsLayer.maxZoom && {
            maxZoom: wmtsLayer.maxZoom,
          }),
          ...(wmtsLayer.zIndex && { zIndex: wmtsLayer.zIndex }),
        };
        return new TileLayer(layerAttributes);
      }
    }
  }
};

const getDefaultTableSuffix: (geoColumn: string) => string = (geoColumn) => {
  switch (geoColumn) {
    case WELL_PATHS_GEO_POINT:
      return `INNER JOIN (SELECT UWI AS WellsPathUWI, WELLSPATH FROM \`${config.BQ_TABLE}.Saga.TBL_WELLSPATH\`) ON UWI = WellsPathUWI`;
    case WELL_STICKS_GEO_POINT:
      if (config.hasCanadaWells) {
        return `INNER JOIN (SELECT SurfaceUWI AS WSSurfaceUWI, WellStickLine FROM \`${config.BQ_TABLE}.Saga.tbl_wellstick\`) ON SurfaceUWI = WSSurfaceUWI `;
      }
      return `INNER JOIN (SELECT SurfaceUWI, WellStickLine FROM \`${config.BQ_TABLE}.Saga.tbl_wellstick\`) ON UWI10 = SurfaceUWI`;
    default:
      return "";
  }
};

const processDynamicVectorTile = async ({
  accessToken,
  connection = "sa-carto-saga-analytics",

  color = [0, 88, 161],
  lineColor,
  currentDeckGl,
  linkToOpenlayersMap = true,

  title,
  subtitle,
  visible = true,
  minZoom,
  geoColumn,

  isBottomSelected,

  layerStyles,
  layerLegendColors,
  zoom,

  selectedMapParentWellIDs,
  selectedBottomWellboreIDs,
  selectedPermitIds,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
  selectedCardPermitIDs,
  DVTQuery,
  getDVTQuery,
  zIndex,
  compressedWellSpotInfo = {
    data: {},
    min: null,
    max: null,
  },
  compressedWellSpotData = [],
  compressedBubbleMapInfo = {
    data: {},
    min: null,
    max: null,
  },
  compressedBubbleMapData = [],
  isProcessingStyles = false,
  isHighlightSelectedSpots = true,
  initialGridSearchMade,
  onClick,
  updateDVTProcessing,
  updateViewportChanged,
}: CommonLayer & ProcessDVT) => {
  const id: string = subtitle || title;

  const isBubbleMapLayer = title === BUBBLE_MAP;
  const isPermitLayer = title === PERMIT_SPOTS;
  const legendColors = isPermitLayer
    ? layerLegendColors[PERMIT_SPOTS]
    : layerLegendColors[WELL_SPOTS];

  let layerSize = layerStyles ? layerStyles[title].size : 0;
  let bubbleMapTitle: string;
  let queryID: string = id;
  if (isBubbleMapLayer) {
    if (isBottomSelected) {
      layerSize = layerStyles ? layerStyles[BOTTOM_WELL_SPOTS].size : 0;
      bubbleMapTitle = DYNAMIC_BOTTOM_WELL_SPOTS;
      queryID = DYNAMIC_BOTTOM_WELL_SPOTS;
      geoColumn = "BottomGeoPoint";
    } else {
      layerSize = layerStyles ? layerStyles[WELL_SPOTS].size : 0;
      bubbleMapTitle = DYNAMIC_WELL_SPOTS;
      queryID = DYNAMIC_WELL_SPOTS;
      geoColumn = "SurfaceGeoPoint";
    }
  }

  let query = DVTQuery[queryID]?.query;
  let hasCount = !!DVTQuery[queryID]?.hasCount || !initialGridSearchMade;

  if (initialGridSearchMade && !getDVTQuery && !query) return;

  if (!isBubbleMapLayer && !query && getDVTQuery) {
    const newDVTQuery = await getDVTQuery({
      id,
      geoColumn,
    });
    if (newDVTQuery?.query) query = newDVTQuery.query;
    hasCount = !!newDVTQuery?.hasCount || !initialGridSearchMade;
  }

  const colorByKey = layerStyles[title].color;
  const colorByAttribute = COLOR_BY_ATTRIBUTE_INFO[colorByKey];
  const sizeByKey = layerStyles[BUBBLE_MAP].size;
  const sizeByAttribute = SIZE_BY_ATTRIBUTE_INFO[sizeByKey];

  if (!query) {
    query = getDefaultDVTQuery(subtitle, geoColumn);
  }

  const newCartoLayer = new VectorTileLayer({
    ...getHighlightCartoConfig(title, initialGridSearchMade),
    ...getIconPoinTypeConfig({
      layerName: subtitle,
      size: layerStyles[title].size as number,
      zoom,
      selectedPermitIds,
      colorByAttribute,
      layerColors: legendColors,
      compressedWellSpotData,
      compressedWellSpotInfo,
      defaultColor: color,
      selectedCardPermitIDs,
      isHighlightSelectedSpots,
    }),
    // Base Info
    minZoom: minZoom || CARTO_MIN_ZOOM,
    maxZoom: CARTO_MAX_ZOOM,
    visible: hasCount ? visible : false,
    pickable: true,
    // Basic Info
    id,
    data: vectorQuerySource({
      accessToken,
      apiBaseUrl: config.cartoBaseURL,
      connectionName: connection,
      headers: {
        "Cache-Control": "max-age=86400",
      },
      spatialDataColumn: GEOPOINT,
      sqlQuery: query,
    }),
    lineWidthUnits: "pixels",
    getLineWidth: (f: Feature) =>
      getCartoLineWidthPixels({
        feature: f,
        layerName: title,
        selectedWellCardPWIDs,
        selectedWellCardBWIDs,
      }),
    opacity: getCartoOpacity(title),
    pointRadiusUnits: getCartoPointRadiusUnits(title),
    getPointRadius: (f: Feature) =>
      getCartoPointRadius({
        feature: f,
        layerName: title,
        zoom,
        layerSize,
        compressedBubbleMapData,
        compressedBubbleMapInfo,
        sizeByAttribute,
        bubbleMapTitle,
        selectedWellCardPWIDs,
        selectedWellCardBWIDs,
      }),
    getLineColor: (f: Feature) =>
      getCartoLineColor({
        feature: f,
        layerName: subtitle,
        selectedMapParentWellIDs,
        selectedBottomWellboreIDs,
        selectedPermitIds,
        isHighlightSelectedSpots,
        colorByAttribute,
        layerColors: legendColors,
        compressedWellSpotData,
        compressedWellSpotInfo,
        defaultColor: lineColor,
      }) as Color,

    getFillColor: (f: Feature) => {
      if (isBubbleMapLayer || isPermitLayer) return color as Color;

      return getColorByAttribute({
        colorByAttribute,
        compressedWellSpotData,
        compressedWellSpotInfo,
        feature: f,
        layerName: subtitle,
        wellColorList: legendColors,
        defaultColor: color,
      }) as Color;
    },

    // Callbacks
    onClick,

    // Put values here that has to update the DeckGL Layer
    // Thing of this as the:
    // DeckGL useEffect dependencies
    updateTriggers: {
      getLineWidth: [selectedWellCardPWIDs, selectedWellCardBWIDs],
      getLineColor: [
        selectedMapParentWellIDs,
        selectedBottomWellboreIDs,
        selectedPermitIds,
        isHighlightSelectedSpots,
        compressedWellSpotData,
        legendColors,
        isProcessingStyles,
      ],
      getFillColor: [
        compressedWellSpotData,
        legendColors,
        isProcessingStyles,
        color,
      ],
      getPointRadius: getUpdateTriggersPointRadius({
        layerName: title,
        layerSize,
        zoom,
        selectedWellCardPWIDs,
        selectedWellCardBWIDs,
        compressedBubbleMapData,
        compressedBubbleMapInfo,
      }),
      getIcon: getIconPointTypeUpdateTriggers({
        layerName: subtitle,
        selectedPermitIds,
        compressedWellSpotData,
        legendColors,
        color,
        isHighlightSelectedSpots,
        selectedCardPermitIDs,
      }),
      getIconSize: getIconSizeTriggers({
        layerName: subtitle,
        selectedCardPermitIDs,
        zoom,
      }),
    },

    onViewportLoad: () => {
      updateViewportChanged(false);
      updateDVTProcessing(false);
    },
  });

  if (currentDeckGl) {
    const layers = currentDeckGl.props.layers as DeckLayer[];

    const updatedLayers = [
      ...layers.filter((cartoLayer) => cartoLayer?.id !== id),
      newCartoLayer,
    ].sort((a, b) => {
      const configA = getZIndexByTitle(a.id);
      const configB = getZIndexByTitle(b.id);
      return configA - configB;
    });

    currentDeckGl.setProps({ layers: updatedLayers });
    currentDeckGl.redraw();

    const render: RenderFunction = ({ size, viewState, mapId }: FrameState) => {
      const [width, height] = size;
      const [longitude, latitude] = toLonLat(viewState.center);
      const zoom = viewState.zoom - 1;

      const bearing = (-viewState.rotation * 180) / Math.PI;
      const deckViewState = {
        bearing,
        longitude,
        latitude,
        zoom,
      };

      currentDeckGl.setProps({
        width,
        height,
        viewState: deckViewState,
      });
      currentDeckGl.redraw();
      return document.getElementById(mapId) as HTMLElement; //temp
    };

    if (linkToOpenlayersMap) {
      const newLayer = new Layer({
        properties: {
          title,
        },
        zIndex,
        render,
        maxZoom: CARTO_MAX_ZOOM,
      });
      return newLayer;
    }
  }
};

const determineDVTSizeDividerBasedOnZoom = (zoom: number) => {
  switch (zoom) {
    case 3:
      return 0.5;
    case 4:
      return 0.75;
    case 5:
      return 1.25;
    case 6:
      return 2;
    case 7:
      return 3.5;
    case 8:
      return 6;
    case 9:
      return 10;
    case 10:
      return 20;
    case 11:
      return 50;
    case 12:
      return 90;
    case 13:
      return 180;
    case 14:
      return 300;
    case 15:
      return 680;
    case 16:
      return 1200;
    case 17:
      return 2100;
    case 18:
      return 4200;
    default:
      return (zoom * 1000) / 2;
  }
};

const getDefaultDVTQuery = (layerName: string, geoColumn: string) => {
  let columns: string[] = [];
  let table = "";
  switch (layerName) {
    case DYNAMIC_WELL_SPOTS:
      columns = ["ParentWellID"];
      table = config.SURFACE_WELL_SPOTS_TABLE;
      break;
    case DYNAMIC_BOTTOM_WELL_SPOTS:
      columns = ["BottomWellboreID"];
      table = config.BOTTOM_WELL_SPOTS_TABLE;
      break;
    case WELL_PATHS:
      columns = ["BottomWellboreID"];
      table = config.WELL_PATHS_TABLE;
      break;
    case WELL_STICKS:
      columns = ["BottomWellboreID"];
      table = config.WELL_STICKS_TABLE;
      break;
    default:
      columns = [];
      table = "WellPerformanceHeader";
      break;
  }
  columns.push(`${geoColumn} AS ${GEOPOINT}`);
  return `SELECT ${columns.join(", ")} FROM \`${
    config.BQ_TABLE
  }.Saga.${table}\``;
};

export {
  determineDVTSizeDividerBasedOnZoom,
  getDVTLayer,
  getDefaultDVTQuery,
  processDynamicVectorTile,
};
