import { useEffect, useState } from "react";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";
import { GetWellCardDataProps } from "../../../types/panels/wellPanel/hooks";
import {
  MapLayer,
  WellPanelInfo,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import {
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_WELL_SPOTS,
  WELL_SPOTS,
} from "../../../constants/constants";

import usePanelsStore from "../../../store/panels/panelsStore";

import useGetWellPanelData from "../../../customHooks/panels/wellPanel/useGetWellPanelData";

import { clone } from "../../../utils/helper";

import Loading from "../../common/Loading";
import PanelDrawer from "../common/PanelDrawer";
import WellPanelContent from "./WellPanelContent";
import WellPanelFooter from "./WellPanelFooter";
import WellPanelHeader from "./WellPanelHeader";

interface WellPanelProps extends PanelDrawerProps {
  wellId: number;
  layer: MapLayer;
  parentWellId: number;
  bottomWellBoreId: number;
  uwi: string;
  groupedWellID: number;
}
const WellPanel = ({
  open,
  handleCloseMenuClick,
  wellId,
  layer,
  bottomWellBoreId,
  parentWellId,
  uwi,
  groupedWellID,
}: WellPanelProps) => {
  const wellInfoList = usePanelsStore((state) => state.wellList);

  const updateWellCardDataByKey = usePanelsStore(
    (state) => state.updateWellCardDataByKey
  );
  const updateWellPanelPaginationByKey = usePanelsStore(
    (state) => state.updateWellPanelPaginationByKey
  );
  const {
    getWellCard,
    isWellCardDataLoading,
    wellCardData,
    isSurfaceAndBottomEqual,
  } = useGetWellPanelData();
  const [currentWellPanelInfo, setCurrentWellPanelInfo] = useState<
    WellPanelInfo | undefined
  >(undefined);
  const [currentViewedWellSystem, setCurrentViewedWellSystem] = useState<
    WellSystemData | undefined
  >(undefined);

  const updateIsSurfaceBottomEqual = usePanelsStore(
    (state) => state.updateIsSurfaceBottomEqual
  );

  useEffect(() => {
    const copiedWellInfoList: WellPanelInfo[] = clone(wellInfoList);
    const searchedWellPanelInfo = copiedWellInfoList.find(
      (info) =>
        info.parentWellId === parentWellId &&
        info.bottomWellBoreId === bottomWellBoreId &&
        info.wellType === layer
    );
    if (searchedWellPanelInfo) {
      setCurrentWellPanelInfo(searchedWellPanelInfo);
    }
  }, [wellInfoList]);

  useEffect(() => {
    if (
      !currentWellPanelInfo ||
      (currentWellPanelInfo.wellSystemData &&
        currentWellPanelInfo.wellSystemData.every(
          (wellData) => wellData.wellCardData.isDataLoaded
        ))
    ) {
      const findWellSystem = currentWellPanelInfo?.wellSystemData?.find(
        (data) => data.pageNumber === currentWellPanelInfo.activePage
      );
      setCurrentViewedWellSystem(findWellSystem);
      return;
    }

    const isSurfaceLayer = [DYNAMIC_WELL_SPOTS, WELL_SPOTS].includes(
      currentWellPanelInfo.wellType ?? ""
    );

    const getWellCardDataProps: GetWellCardDataProps = isSurfaceLayer
      ? {
          parentWellId: currentWellPanelInfo.parentWellId,
          layer: currentWellPanelInfo.wellType ?? DYNAMIC_WELL_SPOTS,
        }
      : {
          parentWellId: currentWellPanelInfo.parentWellId,
          bottomWellBoreId: currentWellPanelInfo.bottomWellBoreId,
          layer: currentWellPanelInfo.wellType ?? DYNAMIC_BOTTOM_WELL_SPOTS,
        };

    getWellCard(getWellCardDataProps);
    // updateWellInformation(WELL_INFORMATION_BY_KEY);
  }, [currentWellPanelInfo]);

  useEffect(() => {
    if (!currentWellPanelInfo?.wellType || !wellCardData || !groupedWellID) {
      return;
    }
    updateWellCardDataByKey(
      wellCardData,
      currentWellPanelInfo.wellType,
      groupedWellID,
      wellId
    );
  }, [wellCardData]);

  useEffect(() => {
    if (!currentWellPanelInfo || !wellCardData || !groupedWellID) {
      return;
    }
    updateIsSurfaceBottomEqual(isSurfaceAndBottomEqual, layer, groupedWellID);
  }, [isSurfaceAndBottomEqual]);

  const setWellPanelPage = (page: number) => {
    if (!groupedWellID) {
      return;
    }

    updateWellPanelPaginationByKey(layer, groupedWellID, page);
  };

  return (
    <PanelDrawer
      className="well-panel"
      open={open && (currentWellPanelInfo?.isActive ?? false)}
      handleCloseMenuClick={handleCloseMenuClick}
    >
      {!isWellCardDataLoading &&
      currentWellPanelInfo &&
      currentViewedWellSystem ? (
        <div className="well-panel-container">
          <div className="well-panel-body">
            <WellPanelHeader
              wellPanelInfo={currentWellPanelInfo}
              data={currentViewedWellSystem}
              parentWellId={parentWellId}
              bottomWellBoreId={bottomWellBoreId}
              layer={layer}
              groupedWellID={groupedWellID ?? 0}
            />
            <WellPanelContent
              data={currentViewedWellSystem}
              activePage={currentWellPanelInfo.activePage}
              layer={layer}
              groupedWellID={groupedWellID ?? 0}
              uwi={uwi}
              surfaceUWI={currentWellPanelInfo.surfaceUWI}
              country={currentWellPanelInfo.country}
            />
          </div>

          <WellPanelFooter
            totalPages={
              currentWellPanelInfo && currentWellPanelInfo.wellSystemData
                ? currentWellPanelInfo.wellSystemData.length
                : 0
            }
            currentPage={currentWellPanelInfo?.activePage ?? 0}
            currentViewedWellPanel={currentViewedWellSystem}
            onPageChange={(page) => setWellPanelPage(page)}
          />
        </div>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <Loading />
        </div>
      )}
    </PanelDrawer>
  );
};

export default WellPanel;
