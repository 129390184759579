import * as ATTRIBUTE from "../constants/attributes";

import { formattedNumber, roundNumber } from "./helper";
import { floatValidation } from "./panel/wellPanel/fieldValidation";

/**
 * Formats a number based on the provided attribute key.
 * @param value - The number to be formatted.
 * @param attributeKey - The attribute key used to determine the formatting rules.
 * @returns The formatted number.
 */
export const formatNumberByAttribute = (
  value: number,
  attributeKey: string
) => {
  switch (attributeKey) {
    case ATTRIBUTE.LOE_COST.key:
    case ATTRIBUTE.GPT_COST.key:
    case ATTRIBUTE.WATER_COST.key:
      // Format number with 2 decimal places and commas
      return formatNumberWithNegative(value, 2, true);

    case ATTRIBUTE.BREAK_EVEN_PRICE.key:
    case ATTRIBUTE.PAYOUT.key:
      // Format number with 1 decimal place and commas
      return formatNumberWithNegative(value, 1, true);

    case ATTRIBUTE.FIXED_COST.key:
    case ATTRIBUTE.NET_PRESENT_VALUE.key:
      // Format number with no decimal places and commas
      return formatNumberWithNegative(value, 0, true);

    case ATTRIBUTE.DRILLING_COMPLETION_CAP_EX.key:
    case ATTRIBUTE.INFRA_CAP_EX.key:
      // Format number with 3 decimal places
      return formatNumberWithNegative(value, 3);

    case ATTRIBUTE.WORKING_INTEREST.key:
    case ATTRIBUTE.ROYALTY.key:
    case ATTRIBUTE.INTERNAL_RATE_OF_RETURN.key:
      // Format number with no decimal places
      return formatNumberWithNegative(value, 0);

    default:
      // Format number using default formatting rules
      return formattedNumber(value);
  }
};

/**
 * Formats a negative number and its decimal places.
 * @param value - The number to be formatted.
 * @param numberOfDecimals - The number of decimal places to include in the formatted value.
 * @param hasCommas - Indicates whether the formatted value should include commas.
 * @returns The formatted number as a string.
 */
export const formatNumberWithNegative = (
  value: number,
  numberOfDecimals: number,
  hasCommas = false
) => {
  const positiveValue = Math.abs(value);
  let formattedStringValue = hasCommas
    ? floatValidation(positiveValue, numberOfDecimals)
    : `${roundNumber(positiveValue, numberOfDecimals)}`;
  if (value < 0) formattedStringValue = `(${formattedStringValue})`;
  return formattedStringValue;
};
