import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
  FlexAccordionImage,
  FlexContainer,
  SpanText,
} from "./../StyledAccordions";

import DataCurrency from "../../../../assets/images/data-currency.png";
import GunBarrel from "../../../../assets/images/gun-barrel.png";
import HideSelectIndicator from "../../../../assets/images/hide-select-indicator.png";
import MapScaleBar from "../../../../assets/images/map-scale-bar.png";
import NewAddAnalysis from "../../../../assets/images/new-add-analysis.png";
import NormalizeByLateral from "../../../../assets/images/normalize-by-lateral.png";
import PlotDailyValues from "../../../../assets/images/plot-daily-values.png";
import ReleaseNotes from "../../../../assets/images/release-notes.png";
import Slant from "../../../../assets/images/slant.png";
import Top5Operator from "../../../../assets/images/top-5-operator.png";
import WellCardProdPlot from "../../../../assets/images/well-card-prod-plot.png";
import WellSpotLabel from "../../../../assets/images/well-spot-label.png";
import WhatsNext from "../../../../assets/images/whats-next.png";

const Version20230904: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20230904"}
      onChange={handleChange("Version20230904")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2023.09.04
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          9/04/2023
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes additional functionality and datasets for
          existing features, new tools to get information about the current
          state of the Well Data Analytics software , incremental enhancements
          to the map and analysis visualizations, as well as some bug fixes and
          quality-of-life enhancements.
        </CustomTypography>
        <AccordionContent>
          • Quick configure dashboard charts - Additional datasets for bar
          charts.
        </AccordionContent>
        <FlexContainer>
          <FlexAccordionImage src={Top5Operator} draggable={false} />
          <FlexAccordionImage src={Slant} draggable={false} />
        </FlexContainer>

        <AccordionContent>• Well Data Analytics UI</AccordionContent>
        <AccordionSubContent isItalic>
          o We&lsquo;ve added a new question mark icon on the top right edge of
          the application that includes information about the state of the Well
          Data Analytics application, including Release Notes, What&lsquo;s
          Next, and Data Currency.
        </AccordionSubContent>
        <AccordionSubContent isItalic>
          o <SpanText>Release Notes</SpanText> - The release notes feature is
          where we will post documentation about changes and updates in each
          subsequent release. This will also house older historic release notes
          for a set amount of time.
        </AccordionSubContent>
        <AccordionImage src={ReleaseNotes} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>What&lsquo;s Next</SpanText> - This is where we will
          communicate information about the features that are in development for
          the next scheduled release.
        </AccordionSubContent>
        <AccordionImage src={WhatsNext} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>Data Currency</SpanText> - This grid is meant to inform
          users about the status of the data available through well data
          analytics. We perform two production releases per month, but most
          state agencies typically only provide data once per month. This
          feature documents the relevant dates about the most current dataset
          available in each state.
        </AccordionSubContent>
        <AccordionImage src={DataCurrency} draggable={false} />
        <AccordionContent>• Map and Well Card Enhancements:</AccordionContent>
        <AccordionSubContent isItalic>
          o <SpanText>Well Spot label and settings</SpanText> - Hover over well
          spots shows Well Name and Well Number.
        </AccordionSubContent>
        <AccordionImage src={WellSpotLabel} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>Hide Selected Indicator</SpanText> - Settings option hides
          magenta ring around selected well spots
        </AccordionSubContent>
        <AccordionImage
          src={HideSelectIndicator}
          width="100%"
          draggable={false}
        />
        <AccordionSubContent isItalic>
          o <SpanText>Map Scale Bar</SpanText> - In the Base Map Style
          subsection of the map settings there is now an option to turn on a map
          scale bar and choose between imperial and metric units.
        </AccordionSubContent>
        <AccordionImage src={MapScaleBar} width="100%" draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>Well Card Production Plot</SpanText> - There is now a new
          tab in the well card that shows a production plot for that specific
          UWI14.
        </AccordionSubContent>
        <AccordionImage src={WellCardProdPlot} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>Gun Barrel Export Image</SpanText> - Gun barrel diagrams
          can now be exported as image files.
        </AccordionSubContent>
        <AccordionImage src={GunBarrel} draggable={false} />
        <AccordionContent>• Analysis Plot Enhancements:</AccordionContent>
        <AccordionSubContent isItalic>
          o <SpanText>Normalize Type Curve by Lateral Length</SpanText> - There
          is now an option to normalize type curve production volumes by the
          lateral length assigned to the well. When this option is selected all
          wells that do not have a lateral length will be removed from the plot.
        </AccordionSubContent>
        <AccordionImage src={NormalizeByLateral} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>Plot Daily Values</SpanText> - There is a new option in
          the production plot and type curve settings that will change monthly
          volumes to daily volumes. The frequency of the TGS production dataset
          is still monthly, but this option divides those monthly volumes by
          30.5 to convert to equivalent daily volumes.
        </AccordionSubContent>
        <AccordionImage src={PlotDailyValues} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>New Add Analysis Plot Images</SpanText> - We&lsquo;ve
          added new icons and images for selecting production plots and type
          curves to the analysis tab.
        </AccordionSubContent>
        <AccordionImage src={NewAddAnalysis} draggable={false} />
        <AccordionSubContent isItalic>
          o <SpanText>Analysis Plot Individual Wells Order</SpanText> - When
          aggregating production plots and type curves by individual wells, the
          order of individual wells is now determined by CumBOE in descending
          order.
        </AccordionSubContent>
        <AccordionSubContent isItalic>
          o <SpanText>Analysis Plot Manipulation</SpanText> - Plots in the
          analysis tab are easier to re-order and re-size.
        </AccordionSubContent>
        <AccordionSubContent isItalic>
          o <SpanText>Analysis Plot Excel Exports</SpanText> - Individual well
          exports now include Well Name and Well Number
        </AccordionSubContent>
        <AccordionContent>• Other Enhancements</AccordionContent>
        <AccordionSubContent isItalic>
          o <SpanText>EUR per Ft</SpanText> - Based on customer feedback
          we&lsquo;ve updated EUR per 1000 ft to EUR per ft.
        </AccordionSubContent>
        <AccordionSubContent isItalic>
          o <SpanText>TGS Operator</SpanText> - Previously labelled Ultimate
          Owner, this attribute is the TGS view on the current operator, and
          tracks M&A activity where regulatory agencies typically do not.
        </AccordionSubContent>
        <AccordionSubContent isItalic>
          o <SpanText>Optimized Login</SpanText> - We&lsquo;ve narrowed the
          window before triggering login after user logins have timed out.
        </AccordionSubContent>
      </AccordionDetails>
    </Accordion>
  );
};

export default Version20230904;
