const WELLNAME_MAX_LENGTH = 10;

const horizontalTargetCrossHairConfig = {
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  showlegend: false,
  line: {
    dash: "dot",
    width: "1",
    color: "rgba(255,255,255, 0.5)",
  },
};

const verticalTargetCrossHairConfig = {
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  showlegend: false,
  line: {
    dash: "dot",
    width: "1",
    color: "rgba(255,255,255, 0.5)",
  },
};

const horizontalFocusedPointCrossHairConfig = {
  type: "scatter",
  mode: "lines",
  showlegend: false,
  hoverinfo: "none",
  line: {
    dash: "dot",
    width: "2",
    color: "white",
  },
};

const verticalFocusedPointCrossHairConfig = {
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  showlegend: false,
  line: {
    dash: "dot",
    width: "2",
    color: "white",
  },
};

const overlayConfig = {
  fill: "toself",
  fillcolor: "#000",
  type: "scatter",
  mode: "none",
  hoverinfo: "skip",
  opacity: "0.5",
  showlegend: false,
};

const wellHoverAnnotationConfig = {
  opacity: 0.7,
  font: {
    size: 10,
    color: "#000",
  },
  borderwidth: 1,
  borderpad: 2,
  bgcolor: "#fff",
  showarrow: false,
};

const wellHorizontalDistanceHoverAnConfig = {
  font: {
    size: 9,
    color: "#fff",
  },
  showarrow: false,
};

const wellVerticalDistanceHoverAnConfig = {
  font: {
    size: 9,
    color: "#fff",
  },
  showarrow: false,
};

export {
  horizontalTargetCrossHairConfig,
  verticalTargetCrossHairConfig,
  horizontalFocusedPointCrossHairConfig,
  verticalFocusedPointCrossHairConfig,
  wellHoverAnnotationConfig,
  wellHorizontalDistanceHoverAnConfig,
  wellVerticalDistanceHoverAnConfig,
  overlayConfig,
  WELLNAME_MAX_LENGTH,
};
