import { StateCreator } from "zustand";

import {
  DataGridCountStore,
  DataGridExportStore,
  DataGridStateStore,
  DataGridTriggerStore,
} from "../../types/grid";

const dataGridExportStore: StateCreator<DataGridExportStore> = (set, get) => ({
  isExportToGridLoading: false,
  updateIsExportToGridLoading: (isExportToGridLoading) =>
    set((state) => ({ isExportToGridLoading })),
});

const dataGridCountSlice: StateCreator<DataGridCountStore> = (set, get) => ({
  gridTotalCount: 0,
  gridFilteredCount: 0,
  updateGridTotalCount: (gridTotalCount) =>
    set((state) => ({ gridTotalCount })),
  updateGridFilteredCount: (gridFilteredCount) =>
    set((state) => ({ gridFilteredCount })),
});

const dataGridStateStore: StateCreator<DataGridStateStore> = (set, get) => ({
  showGrid: false,
  toggleGrid: (status) => set((state) => ({ showGrid: status })),
  showGridHeader: false,
  toggleGridHeader: (status) => set((state) => ({ showGridHeader: status })),

  gridHeight: "350px",
  setGridHeight: (height) => set((state) => ({ gridHeight: height })),

  initialWellDataFetched: false,
  updateInitialWellDataFetched: (initialWellDataFetched) =>
    set((state) => ({
      initialWellDataFetched,
    })),
  isFullScreenGrid: false,
  toggleFullScreenGrid: (status) =>
    set((state) => ({ isFullScreenGrid: status })),

  isExpandGridWidth: false,
  toggleGridWidth: (status) =>
    set(() => ({ isExpandGridWidth: status ?? !get().isExpandGridWidth })),
});

const dataGridTriggerStore: StateCreator<DataGridTriggerStore> = (
  set,
  get
) => ({
  gridWellDataTrigger: "",
  updateGridWellDataTrigger: (gridWellDataTrigger) =>
    set((state) => ({ gridWellDataTrigger })),
});

export {
  dataGridExportStore,
  dataGridCountSlice,
  dataGridStateStore,
  dataGridTriggerStore,
};
