import { noData } from "../../../constants/constants";

import { formatNumberInK, getNumberWithComma, roundNumber } from "../../helper";

//format negatives with parenthesis
export const formatWellEconomicsOutputValue = (
  value: number | string,
  scale: number,
  formatK = false
) => {
  if (typeof value !== "number") return noData;

  let isNegative = false;
  const num = value;

  if (num < 0) isNegative = true;

  const roundedNum = scale > 0 ? roundNumber(Math.abs(num), scale) : num;

  const formattedNum =
    formatK && Math.abs(num) > 999
      ? formatNumberInK(roundedNum, 1000)
      : getNumberWithComma(roundedNum);

  if (isNegative) {
    return `(${formattedNum})`;
  }

  return formattedNum;
};
