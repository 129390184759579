import { FC, SVGProps } from "react";

interface UWIIcon extends SVGProps<SVGAElement> {
  pathFill?: string;
  pathFillOpacity?: string;
}

const UWIIcon: FC<UWIIcon> = ({
  width = "16",
  height = "16",
  fill = "none",
  pathFill = "white",
  pathFillOpacity = "0.56",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7.16667C5.91667 7.16667 4.14583 6.84028 2.6875 6.1875C1.22917 5.53472 0.5 4.75 0.5 3.83333C0.5 2.91667 1.22917 2.13194 2.6875 1.47917C4.14583 0.826389 5.91667 0.5 8 0.5C10.0833 0.5 11.8542 0.826389 13.3125 1.47917C14.7708 2.13194 15.5 2.91667 15.5 3.83333C15.5 4.75 14.7708 5.53472 13.3125 6.1875C11.8542 6.84028 10.0833 7.16667 8 7.16667ZM8 11.3333C5.91667 11.3333 4.14583 11.0069 2.6875 10.3542C1.22917 9.70139 0.5 8.91667 0.5 8V5.91667C0.5 6.52778 0.784722 7.04528 1.35417 7.46917C1.92361 7.8925 2.60417 8.23611 3.39583 8.5C4.1875 8.76389 5.01056 8.95472 5.865 9.0725C6.71889 9.19083 7.43056 9.25 8 9.25C8.56944 9.25 9.28111 9.19083 10.135 9.0725C10.9894 8.95472 11.8125 8.76389 12.6042 8.5C13.3958 8.23611 14.0764 7.8925 14.6458 7.46917C15.2153 7.04528 15.5 6.52778 15.5 5.91667V8C15.5 8.91667 14.7708 9.70139 13.3125 10.3542C11.8542 11.0069 10.0833 11.3333 8 11.3333ZM8 15.5C5.91667 15.5 4.14583 15.1736 2.6875 14.5208C1.22917 13.8681 0.5 13.0833 0.5 12.1667V10.0833C0.5 10.6944 0.784722 11.2119 1.35417 11.6358C1.92361 12.0592 2.60417 12.4028 3.39583 12.6667C4.1875 12.9306 5.01056 13.1217 5.865 13.24C6.71889 13.3578 7.43056 13.4167 8 13.4167C8.56944 13.4167 9.28111 13.3578 10.135 13.24C10.9894 13.1217 11.8125 12.9306 12.6042 12.6667C13.3958 12.4028 14.0764 12.0592 14.6458 11.6358C15.2153 11.2119 15.5 10.6944 15.5 10.0833V12.1667C15.5 13.0833 14.7708 13.8681 13.3125 14.5208C11.8542 15.1736 10.0833 15.5 8 15.5Z"
        fill={pathFill}
        fillOpacity={pathFillOpacity}
      />
    </svg>
  );
};

export default UWIIcon;
