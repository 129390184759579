import { FC } from "react";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material/";
import { IconButton, Typography, styled } from "@mui/material";

import { SortableTableLabelPropsType } from "../../../types/information/information";

import { ASC } from "../../../constants/dataCurrency";

const CustomIcon = styled(IconButton)({
  color: "#fff",
});

const SortableTableLabel: FC<SortableTableLabelPropsType> = ({
  active,
  direction,
  label,
  onClick,
}) => {
  return (
    <Typography variant="body1" component="div" onClick={onClick}>
      {label}
      {active && (
        <CustomIcon size="small">
          {direction === ASC ? <ArrowDropUp /> : <ArrowDropDown />}
        </CustomIcon>
      )}
    </Typography>
  );
};

export default SortableTableLabel;
