import { ChangeEvent, useCallback, useEffect, useState } from "react";

import {
  Checkbox,
  Radio,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  ChartAxisDataFields,
  ChartAxisType,
} from "../../../../types/charts/chartType/chartType";
import { YAxisDataPickerProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

import { CHART_AXIS } from "../../../../constants/charts/charts";

import { getTopDataField } from "../../../../utils/charts/sorting";

import { clone } from "../../../../utils";
import AxisDropdown from "./AxisDropdown";

const YAxisDataPicker = ({
  dataList,
  selected,
  onDataFieldsSelection,
  chartAxisList,
  isColorByOn,
}: YAxisDataPickerProps) => {
  const [selectedList, setSelectedList] = useState(selected);

  const changeToDefaultAxis = (selectedAxisFields: ChartAxisDataFields[]) => {
    return selectedAxisFields.map((dataField: ChartAxisDataFields) => {
      dataField.axis = CHART_AXIS.LEFT;

      return dataField;
    });
  };
  const handleCheckboxSelection = (data: ChartAxisDataFields) => {
    let newSelected = [...selectedList];
    const selectedIndex = newSelected.findIndex(
      (dataField) => dataField.id === data.id
    );

    if (selectedIndex === -1) {
      //if data field is not yet selected
      newSelected = [...newSelected, { ...data, axis: CHART_AXIS.LEFT }];
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    if (newSelected.length === 1) {
      //default to left axis
      newSelected = changeToDefaultAxis(clone(newSelected));
    }

    setSelectedList(newSelected);
  };

  useEffect(() => {
    if (chartAxisList.length === 1) {
      const rightAxisExists = chartAxisList.find(
        (axis) => axis.name === CHART_AXIS.RIGHT
      );
      const fieldsWithRightAxis = selectedList.filter(
        (field) => field.axis === CHART_AXIS.RIGHT
      ).length;

      if (!rightAxisExists && fieldsWithRightAxis) {
        const newSelected = changeToDefaultAxis(clone(selectedList));
        setSelectedList(newSelected);
      }
    }
  }, [chartAxisList]);

  const isDataSelected = (data: any) => {
    const dataIndex = selectedList?.findIndex(
      (dataField) => dataField.id === data.id
    );
    return dataIndex > -1;
  };

  const selectAllHandler = () => {
    if (selectedList) {
      setSelectedList([]);
    }
  };

  useEffect(() => {
    onDataFieldsSelection(selectedList);
  }, [selectedList]);

  const handleOnSelectAxisChange = (
    event: SelectChangeEvent<ChartAxisType>,
    attribute: string
  ) => {
    const updatedSelectedList = clone(selectedList).map(
      (dataField: ChartAxisDataFields) => {
        if (dataField.name === attribute) {
          dataField.axis = event.target.value as ChartAxisType;
        }
        return dataField;
      }
    );

    setSelectedList(updatedSelectedList);
  };

  const getSelectedAttributeAxis = useCallback(
    (id: number) => {
      return selectedList.find((attribute) => attribute.id === id)?.axis;
    },
    [selectedList]
  );

  const handleRadioButtonSelection = (data: ChartAxisDataFields) => {
    setSelectedList(changeToDefaultAxis([clone(data)]));
  };
  useEffect(() => {
    if (isColorByOn) {
      const newSelectedList = getTopDataField(selectedList, dataList);

      setSelectedList(changeToDefaultAxis(clone(newSelectedList)));
    }
  }, [isColorByOn]);

  return (
    <div className="data-fields-wrapper">
      <TableContainer className="data-fields-table-head">
        <Table className="data-fields-table-head">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {!isColorByOn && (
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedList?.length > 0 && selectedList?.length < 5
                    }
                    checked={selectedList?.length === 5}
                    onChange={selectAllHandler}
                    disabled={!selectedList.length}
                  />
                )}
              </TableCell>
              <TableCell>
                <Typography>Data</Typography>
              </TableCell>
              <TableCell>
                <Typography>Y Axis</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer className="data-fields-table-body">
        <Table>
          <TableBody>
            {dataList.map((data) => (
              <TableRow key={data.id}>
                <TableCell padding="checkbox">
                  {!isColorByOn ? (
                    <Checkbox
                      size="small"
                      checked={isDataSelected(data)}
                      value={data.id}
                      onChange={(e) => {
                        handleCheckboxSelection(data);
                      }}
                      disabled={
                        !isDataSelected(data) && selectedList.length === 5
                      }
                    />
                  ) : (
                    <Radio
                      size="small"
                      checked={selectedList[0]?.id === data.id}
                      value={data.id}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleRadioButtonSelection(data);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Typography>{data.displayName}</Typography>
                </TableCell>
                <TableCell className="cell-axis-select">
                  <AxisDropdown
                    selectedAxis={
                      getSelectedAttributeAxis(data.id) ?? CHART_AXIS.LEFT
                    }
                    handleOnSelectAxisChange={(e) =>
                      handleOnSelectAxisChange(e, data.name)
                    }
                    chartAxisList={chartAxisList}
                    isAttributeSelected={isDataSelected(data)}
                    selectedList={selectedList}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default YAxisDataPicker;
