import {
  createFocusedPointCrossHair,
  createOnHoverAnnotations,
  createOverlay,
  createTargetCrossHair,
} from "./gunBarrelInteractivityUtils";

//private
const getFocusedPoint = (e) => {
  return {
    ...e.points[0].data,
    ...{
      x: [e.points[0].x],
      y: [e.points[0].y],
      showlegend: false,
      ...(e.points[0].text && {
        text: e.points[0].text,
        hoverinfo: "text",
      }),
    },
  };
};

const handleOnHover = ({ e, setState, initialData, initialLayout }) => {
  const focusedPoint = getFocusedPoint(e);
  const [xMin, xMax] = initialData.current.xRange.range;
  const [yMin, yMax] = initialData.current.yRange.range;
  const targetWell = initialData.current.originalData.targetWell;

  setState((state) => ({
    ...state,
    ...{
      data: [
        //initial traces
        ...state.data,
        //overlay
        ...createOverlay(xMin, xMax, yMin, yMax),
        //places formations on top of overlay
        ...initialData.current.formationTraces,
        //crosshair trace for target
        ...createTargetCrossHair(targetWell, xMin, xMax, yMin, yMax),
        //crosshair trace for focused point
        ...createFocusedPointCrossHair(focusedPoint, targetWell),
        //get trace for target well
        { ...initialData.current.wellTraces[0], ...{ showlegend: false } },
        focusedPoint,
      ],
      layout: {
        ...state.layout,
        annotations: [
          ...initialLayout.current.annotations,
          ...createOnHoverAnnotations(focusedPoint, targetWell),
        ],
      },
      revision: state.revision + 1,
    },
  }));
};

const handleOnUnHover = ({ e, setState, initialData, initialLayout }) => {
  setState((state) => ({
    ...state,
    ...{
      data: [
        ...initialData.current.formationTraces,
        ...initialData.current.wellTraces,
      ],
      layout: initialLayout.current,
      revision: state.revision + 1,
    },
  }));
};

export { handleOnHover, handleOnUnHover };
