import { FC, SyntheticEvent, useRef, useState } from "react";

import { WellCounts } from "../../../../../../types/common/wells";
import { VarcharBooleanProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { IN } from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useQueryBuilderStore from "../../../../../../store/search/queryBulder/queryBuilderStore";

import QBTypeAheadComboBox from "./QBAutocomplete";

const QBVarcharAttribute: FC<VarcharBooleanProps> = ({
  attribute,
  attributeQBInfo,
  isOptionsLoading,
  getOptionsWithWellCounts,
  modifyAttributeBounds,
}) => {
  const updateCurrentQBInfo = useQueryBuilderStore(
    (state) => state.updateCurrentQBInfo
  );

  const [inputValue, setInputValue] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onAttributeSelect = (
    selected: WellCounts[],
    isMultipleSelecting?: boolean
  ) => {
    const selectedValues = selected.map((item) => item.attributeValue);
    updateCurrentQBInfo(attribute.key, "values", selectedValues);
    modifyAttributeBounds(selectedValues, IN.key);
    if (!isMultipleSelecting) setInputValue("");
  };

  return (
    <div className="relative">
      <QBTypeAheadComboBox
        inputRef={inputRef}
        options={attributeQBInfo.options}
        isOptionsLoading={isOptionsLoading}
        value={attributeQBInfo.values.map((val) => ({
          attributeValue: val.toString(),
          count: 0,
        }))}
        placeHolder={attribute.label}
        onSelect={(_, value, isMultipleSelecting) =>
          onAttributeSelect(value as WellCounts[], isMultipleSelecting)
        }
        onClick={(sort) => getOptionsWithWellCounts(sort)}
        onBlur={() => setInputValue("")} // Replaces `clearOnBlur`
        onInputChange={(e: SyntheticEvent<Element, Event>, value, reason) =>
          reason !== "reset" && setInputValue(value)
        }
        inputValue={inputValue}
        attribute={attribute}
        hasOnInputEvents
        isMultiple
      />
    </div>
  );
};

export default QBVarcharAttribute;
