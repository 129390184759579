import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import { PermitPanelDataRequestBody } from "../../../types/panels/permitPanel/hooks";
import { PermitSystemData } from "../../../types/panels/permitPanel/permitPanel";
import { WellData } from "../../../types/panels/wellPanel/wellPanel";

import config from "../../../configs/appSettings";

const permitUrl = `${config.endpoints.wellService}api/permits/permit-panel`;

const usePermitsPanelData = () => {
  const [isLoadingPermitData, setIsLoadingPermitData] = useState(false);
  const [isSuccessPermitData, setSuccessPermitData] = useState(false);
  const [permitDataError, setPermitDataError] = useState<string | undefined>(
    undefined
  );
  const [permitData, setPermitData] = useState<PermitSystemData | undefined>(
    undefined
  );

  const getPermitData = async (permitId: number, uwi?: string) => {
    try {
      setIsLoadingPermitData(true);
      setSuccessPermitData(false);
      setPermitData(undefined);

      const body: PermitPanelDataRequestBody = {
        permitId: permitId,
        ...(uwi ? { uwi } : {}),
      };

      const response = await axios.post<WellData>(permitUrl, body);

      if (!response || !("data" in response)) {
        return;
      }

      const permitDataObj: PermitSystemData = {
        permitData: {
          ...response.data,
          isDataLoaded: true,
        },
        permitSectionStatus: {
          isHeaderSectionOpen: true,
          isOperatorContactSectionOpen: false,
          isRelatedPermitsSectionOpen: false,
          isRelatedWellsSectionOpen: false,
        },
      };

      setPermitData(permitDataObj);
      setSuccessPermitData(true);
    } catch (e) {
      const err = e as AxiosError<APIErrorResponse>;
      setPermitDataError(err.message);
      setSuccessPermitData(false);
    } finally {
      setIsLoadingPermitData(false);
    }
  };

  return {
    isLoadingPermitData,
    isSuccessPermitData,
    permitDataError,
    permitData,
    getPermitData,
  };
};

export default usePermitsPanelData;
