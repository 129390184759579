import { Fragment } from "react";

import { ChevronRight, WarningAmber } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";

const TextWithIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: 24,
  color: "#fff",
  fontSize: 14,
  fontWeight: 400,
});

const WarningIcon = styled(WarningAmber)({
  marginRight: 16,
  color: "#F57C00",
  fontSize: 20,
  cursor: "default",
});

const ChevronICon = styled(ChevronRight)({
  marginRight: 16,
  opacity: 0.7,
  fontSize: 20,
  cursor: "default",
});

const Description = styled(Typography)({
  color: "#fff",
  opacity: 0.7,
  fontSize: 14,
  // fontStyle: "italic",
  fontWeight: 400,
  marginBottom: 24,
});

const Image = styled("img")({
  width: "100%",
  height: 300,
  borderRadius: 4,
  marginBottom: 24,
});

const AccordionSubContent = styled("li")<{ isItalic?: boolean }>(
  ({ isItalic }) => ({
    marginLeft: 16,
    marginBottom: 10,
    fontSize: 14,
    fontStyle: isItalic ? "italic" : "normal",
    fontWeight: 400,
    color: "rgba(255, 255, 255, 0.70)",
  })
);

const SpanText = styled("span")({
  fontStyle: "normal",
});

const WhatsNext = () => (
  <Fragment>
    <TextWithIcon>Next Planned Release Date: 9/25/2024</TextWithIcon>
    <Description>
      Features currently in development targeted for the next release:
    </Description>
    <ul>
      <AccordionSubContent isItalic>
        <SpanText>Enhancements to Well Panel functionality</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>User-Customizable Units of Measurement</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>Emission Points Layer</SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>
          Scheduled Export: Enabling users to schedule weekly or monthly data
          exports
        </SpanText>
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        <SpanText>Sharing Option for Saved Searches and Dashboards</SpanText>
      </AccordionSubContent>
    </ul>
  </Fragment>
);

export default WhatsNext;
