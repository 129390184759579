import { create } from "zustand";

import { GridStoreState } from "../../types/grid";

import { bulkResetStateSlice } from "./bulkResetStateSlice";
import { dataGridColumnSlice } from "./dataGridColumnSlice";
import {
  dataGridAllWellDataSlice,
  dataGridBatchWellDataSlice,
} from "./dataGridDataSlice";
import { dataGridFilterSlice } from "./dataGridFilterSlice";
import {
  dataGridCountSlice,
  dataGridExportStore,
  dataGridStateStore,
  dataGridTriggerStore,
} from "./dataGridSlice";
import { dataGridSortSlice } from "./dataGridSortSlice";
import { searchCriteriaSlice } from "./searchCriteriaSlice";
import { wellSelectSlice } from "./wellSelectSlice";

const useDataGridStore = create<GridStoreState>()((...a) => ({
  ...dataGridCountSlice(...a),
  ...dataGridColumnSlice(...a),
  ...dataGridAllWellDataSlice(...a),
  ...dataGridBatchWellDataSlice(...a),
  ...dataGridFilterSlice(...a),
  ...dataGridExportStore(...a),
  ...dataGridStateStore(...a),
  ...dataGridTriggerStore(...a),
  ...dataGridSortSlice(...a),
  ...searchCriteriaSlice(...a),
  ...bulkResetStateSlice(...a),
  ...wellSelectSlice(...a),
}));

export default useDataGridStore;
