import { FC } from "react";

import { WellCounts } from "../../../../../../types/common/wells";
import { VarcharBooleanProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { BOOLEAN } from "../../../../../../constants/attributes";
import { initialOperationType } from "../../../../../../constants/panels/searchPanel/queryBuilder/store";

import useQueryBuilderStore from "../../../../../../store/search/queryBulder/queryBuilderStore";

import QBTypeAheadComboBox from "./QBAutocomplete";

const QBBooleanAttribute: FC<VarcharBooleanProps> = ({
  attribute,
  attributeQBInfo,
  isOptionsLoading,
  getOptionsWithWellCounts,
  modifyAttributeBounds,
}) => {
  const updateCurrentQBInfo = useQueryBuilderStore(
    (state) => state.updateCurrentQBInfo
  );

  const onAttributeSelect = (newValue: WellCounts) => {
    // autocomplete automatically sets the new value
    // to null when clearing the value
    const attribValue = newValue?.attributeValue;
    const value = attribValue ? [attribValue] : [];
    updateCurrentQBInfo(attribute.key, "values", value);
    modifyAttributeBounds(value, initialOperationType[BOOLEAN]);
  };

  return (
    <QBTypeAheadComboBox
      label={attribute.label}
      options={attributeQBInfo.options}
      isOptionsLoading={isOptionsLoading}
      value={
        attributeQBInfo?.values.length
          ? {
              attributeValue: attributeQBInfo.values[0].toString(),
              count: 0,
            }
          : null
      }
      inputValue={
        attributeQBInfo?.values.length
          ? attributeQBInfo.values[0].toString()
          : ""
      }
      placeHolder={attribute.label}
      onSelect={(e, value) => onAttributeSelect(value as WellCounts)}
      onClick={getOptionsWithWellCounts}
    />
  );
};

export default QBBooleanAttribute;
