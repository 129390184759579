import { useState } from "react";

import axios from "axios";

import { Perforation } from "../../../types/panels/wellPanel/perforationType";

import config from "../../../configs/appSettings";

import { PERFORATION_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

const useWellPanelPerforation = () => {
  const [data, setData] = useState<Perforation[]>([]);
  const [success, setSuccess] = useState(true);
  const [error, setError] = useState(null);

  const getWellPerforations = async (wellId: number, uwi: string) => {
    setSuccess(false);
    try {
      const response = await axios.get(
        `${config.endpoints.wellService}api/${PERFORATION_SECTION.urlSlug}/${wellId}`
      );

      const { data } = response;
      if (data && data.length) {
        const dataWithUWI = data.map((val: any) => {
          val.uwi = uwi;
          return {
            ...val,
          };
        });

        setData(dataWithUWI);
        setSuccess(true);

        //to remove if old well card is removed
        return dataWithUWI;
      }
    } catch (error: any) {
      console.debug("No WellCard Perforations", error);
      setData([]);
      setSuccess(false);
      setError(error);

      //to remove if old well card is removed
      return {
        status: "error",
        msg: error,
      };
    }
  };

  return {
    getWellPerforations,
    data,
    success,
    error,
  };
};

export default useWellPanelPerforation;
