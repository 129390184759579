import React, { useMemo, useState } from "react";

import { Backdrop, Button, Popover, Typography } from "@mui/material";

interface ButtonWithPromptProps {
  label: string;
  description: string;
  disabled?: boolean;
  showPrompt?: boolean;
  handleConfirmClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancelClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  buttonProps?: object;
}

const ButtonWithPrompt: React.FC<ButtonWithPromptProps> = ({
  label,
  description,
  disabled = false,
  showPrompt = false,
  handleConfirmClick,
  handleCancelClick,
  buttonProps = {},
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose();
    handleConfirmClick(event);
  };

  const handleCancel = () => {
    handleClose();
    if (handleCancelClick) handleCancelClick();
  };

  const isShowPopover = useMemo(() => showPrompt && open, [showPrompt, open]);
  const handleButtonClick = useMemo(
    () => (showPrompt ? handleClick : handleConfirmClick),
    [showPrompt, handleConfirmClick]
  );

  return (
    <>
      <Button
        aria-label="button-with-prompt"
        variant="contained"
        size="medium"
        onClick={handleButtonClick}
        disabled={disabled}
        {...buttonProps}
      >
        {label}
      </Button>
      <Backdrop className="text-white" open={isShowPopover}>
        <Popover
          open={isShowPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{ root: "button-popover-confirmation" }}
        >
          <Typography>{description}</Typography>
          <Button variant="text" onClick={handleConfirm}>
            YES
          </Button>
          <Typography>/</Typography>
          <Button variant="text" onClick={handleCancel}>
            NO
          </Button>
        </Popover>
      </Backdrop>
    </>
  );
};

export default ButtonWithPrompt;
