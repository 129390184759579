import { useState } from "react";

import axios from "axios";

import {
  WellEconomicsInputTypes,
  WellEconomicsInputs,
  WellEconomicsResponse,
} from "../../../types/panels/wellPanel/wellEconomicsType";

import config from "../../../configs/appSettings";

import { SELF } from "../../../constants/constants";
import { WELL_ECONOMICS_DEFAULT_VALUES } from "../../../constants/panels/wellPanel/wellEconomics";
import { WELL_ECONOMICS_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import useAlertPopupStore from "../../../store/map/alert/alertStore";

const useWellEconomicsOutputData = () => {
  const [data, setData] = useState<WellEconomicsResponse>();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const getWellEconomicsOutputData = async (
    uwi: string[],
    inputs?: WellEconomicsInputs,
    isForExport?: boolean
  ) => {
    setLoading(true);
    setSuccess(false);
    setData(undefined);
    setError(null);
    try {
      const body = {
        Uwis: uwi,
        SummaryOnly: isForExport ? false : true,
        Inputs: inputs ?? WELL_ECONOMICS_DEFAULT_VALUES,
      };

      const { data } = await axios.post<WellEconomicsResponse>(
        `${config.endpoints.productDelivery}/${WELL_ECONOMICS_SECTION.urlSlug}`,
        body
      );

      if (!data.Result.Success && data.Result.Audits[0].Audit) {
        updateAlertState({
          message: `API Error: ${data.Result.Audits[0].Audit}`,
          severity: "error",
        });
      }

      if ((isForExport && inputs) || !isForExport) {
        const dataWithRoundedInputs = data.Inputs;
        (Object.keys(data.Inputs) as WellEconomicsInputTypes[]).forEach(
          (input) =>
            (dataWithRoundedInputs[input] = data.Inputs[input]
              ? Number(data.Inputs[input]?.toFixed(3)) //round numbers to 3 decimal places
              : null)
        );

        setData({ ...data, Inputs: dataWithRoundedInputs });
        setSuccess(true);
        setLoading(false);
      } else if (isForExport && !inputs) {
        setData(undefined); //prevent update if default
        setSuccess(true);
        setLoading(false);
      }

      if (isForExport && data.Url) {
        window.open(data.Url, SELF);
      }
    } catch (error: any) {
      console.debug("Error fetching well economics", error);
      setLoading(false);
      setError(error);
      setLoading(false);
    }
  };

  return {
    getWellEconomicsOutputData,
    data,
    success,
    error,
    loading,
  };
};

export default useWellEconomicsOutputData;
