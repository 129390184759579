import { FC } from "react";

import { isArray } from "lodash";

import { PermitPanelSectionProp } from "../../../../types/panels/permitPanel/permitPanel";

import { noData } from "../../../../constants/constants";
import {
  PERMIT_PANEL_SECTION,
  PERMIT_RELATED_WELLS_INFO,
} from "../../../../constants/panels/permitPanel/permit";

import { checkFormatValidation } from "../../../../utils/panel/wellPanel/fieldValidation";
import { removeHyphensString } from "../../../../utils/stringUtils";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../../wellPanel/common/WellPanelAccordion";
import SectionCard from "../common/SectionCards";

const RelatedWells: FC<PermitPanelSectionProp> = ({
  isExpanded,
  onChange,
  data,
}) => {
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={PERMIT_PANEL_SECTION.RELATED_WELLS_SECTION.displayLabel}
      onChange={() => onChange(PERMIT_PANEL_SECTION.RELATED_WELLS_SECTION)}
      isLoading={false}
    >
      {data?.relatedWells &&
        isArray(data.relatedWells) &&
        data.relatedWells.map((relatedWell, index) => (
          <SectionCard key={index}>
            <div className="well-info-container">
              <div className="well-info-item">
                <WellInfoText
                  label={PERMIT_RELATED_WELLS_INFO.UWI.label}
                  value={checkFormatValidation(
                    relatedWell[PERMIT_RELATED_WELLS_INFO.UWI.key]
                  )}
                />
              </div>
              <div className="well-info-item">
                <WellInfoText
                  label={PERMIT_RELATED_WELLS_INFO.WELL_NAME.label}
                  value={checkFormatValidation(
                    relatedWell[PERMIT_RELATED_WELLS_INFO.WELL_NAME.key]
                  )}
                />
              </div>
              <div className="well-info-item">
                <WellInfoText
                  label={PERMIT_RELATED_WELLS_INFO.WELL_NUMBER.label}
                  value={checkFormatValidation(
                    removeHyphensString(
                      relatedWell[PERMIT_RELATED_WELLS_INFO.WELL_NUMBER.key]
                    )
                  )}
                />
              </div>
              <div className="well-info-item">
                <WellInfoText
                  label={PERMIT_RELATED_WELLS_INFO.SPUD_DATE.label}
                  value={checkFormatValidation(
                    relatedWell[PERMIT_RELATED_WELLS_INFO.SPUD_DATE.key]
                  )}
                />
              </div>
              <div className="well-info-item">
                <WellInfoText
                  label={PERMIT_RELATED_WELLS_INFO.NO_WELLS.label}
                  value={
                    relatedWell[PERMIT_RELATED_WELLS_INFO.NO_WELLS.key] ??
                    noData
                  }
                />
              </div>
            </div>
          </SectionCard>
        ))}
    </WellPanelAccordion>
  );
};

export default RelatedWells;
