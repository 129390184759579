import { FC } from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import classNames from "classnames";

import {
  DefaultAttributeProps,
  OperationTypeKeys,
} from "../../../../../../types/panels/searchPanel/queryBuilder";

import { DATE_OPTIONS } from "../../../../../../constants/panels/searchPanel/queryBuilder/opTypes";

import useQueryBuilderStore from "../../../../../../store/search/queryBulder/queryBuilderStore";

import QBDateFields from "./QBDateFields";

const QBDateAttribute: FC<DefaultAttributeProps> = ({
  attribute,
  attributeQBInfo,
  modifyAttributeBounds,
}) => {
  const updateCurrentQBInfo = useQueryBuilderStore(
    (state) => state.updateCurrentQBInfo
  );
  const updateIsQBUpdated = useQueryBuilderStore(
    (state) => state.updateIsQBUpdated
  );

  const handleOperationChange = (e: SelectChangeEvent) => {
    const operationType = e.target.value as OperationTypeKeys;
    updateCurrentQBInfo(attribute.key, "operationType", operationType);

    if (attributeQBInfo.values.length < 1) return;
    updateCurrentQBInfo(attribute.key, "values", []);
    updateIsQBUpdated(true);
    modifyAttributeBounds([], operationType);
  };

  return (
    <div className="numeric-date-attribute">
      <FormControl
        variant="standard"
        className={classNames("select-operation-type", {
          "has-value": attributeQBInfo.operationType !== "",
        })}
      >
        <InputLabel id="select-label">{attribute.label}</InputLabel>
        <Select
          labelId="select-label"
          value={attributeQBInfo.operationType}
          label={attribute.label}
          onChange={handleOperationChange}
        >
          {DATE_OPTIONS.map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <QBDateFields
        attribute={attribute}
        attributeQBInfo={attributeQBInfo}
        operationType={attributeQBInfo.operationType}
        modifyAttributeBounds={modifyAttributeBounds}
      />
    </div>
  );
};

export default QBDateAttribute;
