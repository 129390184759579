import { useEffect, useMemo, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  DialogActions,
  DialogContent,
  FormLabel,
  Grid,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { isEqual } from "lodash";

import { ChartSettingsModalParams } from "../../../types/charts/chartStore/chartStore";
import {
  ChartAxisDataFields,
  ChartColorByInfo,
  IChartTypeInfo,
} from "../../../types/charts/chartType/chartType";
import { ScatterPlotChartConfigProps } from "../../../types/charts/chartTypeSettings/chartTypeSettings";
import { Modules } from "../../../types/modularity/modules";

import { CHART_TYPES } from "../../../constants/charts/charts";
import {
  SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES,
} from "../../../constants/charts/scatterPlotDataFields";

import useChartStore from "../../../store/chart/chartStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../store/modularity/modularityStore";

import { useColorBy } from "../../../customHooks/charts/config/useColorBy";
import { useScatterPlotConfig } from "../../../customHooks/charts/config/useScatterPlotConfig";

import {
  sortChartDataAlphabetical,
  sortModulesAlphabetical,
} from "../../../utils/charts/sorting";
import { hasEmoji } from "../../../utils/charts/validator";

import { clone } from "../../../utils";
import Loading from "../../common/Loading";
import ColorByPicker from "./formPickers/ColorByPicker";
import MultipleAxisConfig from "./formPickers/MultipleAxisConfig";
import XAxisDataPicker from "./formPickers/XAxisDataPicker";
import YAxisDataPicker from "./formPickers/YAxisDataPicker";

const ScatterPlotConfig = ({
  chartData,
  handleClose,
}: ScatterPlotChartConfigProps) => {
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );

  const chartDataList = useChartStore((state) => state.chartData);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const updateChartModalParam = useChartStore(
    (state) => state.setChartSettingsModalParams
  );

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const [title, setTitle] = useState(
    chartData?.title === ""
      ? chartData?.chartType + "_" + chartData?.chartId
      : chartData?.title
  );
  const [isInputTitleError, setIsInputTitleError] = useState(false);
  const [noYAxisSelected, setNoYAxisSelected] = useState(false);
  const [inputTitleValidationMessage, setInputTitleValidationMessage] =
    useState("");

  const [selectedXAxisDataField, setSelectedXAxisDataField] = useState(
    chartData?.chartXAxisDataFields
  );

  const [selectedYAxisDataField, setSelectedYAxisDataField] = useState(
    chartData?.chartYAxisDataFields
  );

  const [chartAxisList, setChartAxisList] = useState(chartData?.chartAxis);
  const [isChartAxisLabelError, setIsChartAxisLabelError] = useState(false);

  const [chartColorByInfo, setChartColorByInfo] = useState(
    chartData?.chartColorBy
  );
  const [isColorByAttributeError, setIsColorByAttributeError] = useState(false);

  const {
    fetchScatterChartData,
    isLoading,
    partitions,
    topAttributes,
    colorRangeFieldsToggle,
    setColorByTrace,
    colorByTrace,
    data: rawData,
  } = useColorBy(chartData);

  const { formDropdownList } = useScatterPlotConfig();

  const isTitleExisting = () => {
    return (
      chartDataList.filter(
        (chart) =>
          (chart.title === title ||
            chart.chartType + "_" + chart.chartId === title) &&
          chart.chartId !== chartData?.chartId
      ).length > 0
    );
  };

  const handleTitleOnBlur = () => {
    if (!title) {
      setInputTitleValidationMessage("Invalid Chart Title");
      setIsInputTitleError(true);
    } else {
      if (isTitleExisting()) {
        setInputTitleValidationMessage("Duplicated Chart Title");
        setIsInputTitleError(true);
      }

      if (hasEmoji(title)) {
        setInputTitleValidationMessage("Invalid Chart Title");
        setIsInputTitleError(true);
      }
    }
  };

  const handleTitleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isInputTitleError) {
      setIsInputTitleError(false);
      setInputTitleValidationMessage("");
    }

    setTitle(e.target.value);
  };

  const handleXAxisDataFieldChange = (e: SelectChangeEvent<string>) => {
    const dataFieldValue = e.target.value;

    const xAxisFieldInfo = SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES.find(
      (field) => field.name === dataFieldValue
    );

    setSelectedXAxisDataField(xAxisFieldInfo);
  };

  const handleYAxisDataFieldSelection = (
    dataFieldsList: ChartAxisDataFields[]
  ) => {
    dataFieldsList.length > 0
      ? setNoYAxisSelected(false)
      : setNoYAxisSelected(true);
    setSelectedYAxisDataField(dataFieldsList);
  };

  //will check if y data fields are equal regardless of order
  const isYAxisDataFieldsToDisplayEqual = (
    originalCopy: ChartAxisDataFields[],
    updatedCopy: ChartAxisDataFields[]
  ) => {
    const originalCopyIdList = originalCopy.map((data) => data.id);
    const updatedCopyIdList = updatedCopy.map((data) => data.id);

    if (
      originalCopyIdList
        .slice()
        .sort((a, b) => a - b)
        .join(",") ===
      updatedCopyIdList
        .slice()
        .sort((a, b) => a - b)
        .join(",")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isXAxisDataFieldEqual = useMemo(() => {
    return isEqual(selectedXAxisDataField, chartData?.chartXAxisDataFields);
  }, [selectedXAxisDataField, chartData?.chartXAxisDataFields]);

  const isConfigEqual = useMemo(() => {
    return Boolean(
      isEqual(chartColorByInfo, chartData?.chartColorBy) &&
        isYAxisDataFieldsToDisplayEqual(
          selectedYAxisDataField,
          chartData?.chartYAxisDataFields
        ) &&
        isXAxisDataFieldEqual
    );
  }, [
    chartData,
    selectedYAxisDataField,
    selectedXAxisDataField,
    chartColorByInfo,
  ]);

  useEffect(() => {
    if (isConfigEqual) {
      return;
    }

    if (
      chartColorByInfo?.toggle &&
      selectedYAxisDataField.length &&
      chartColorByInfo.attributeKey &&
      selectedGridDataKeys.length
    ) {
      fetchScatterChartData(
        chartColorByInfo,
        selectedYAxisDataField,
        selectedXAxisDataField
      );
    }
  }, [
    selectedYAxisDataField,
    selectedXAxisDataField,
    chartColorByInfo?.toggle,
    chartColorByInfo?.attributeKey,
    selectedGridDataKeys,
  ]);

  useEffect(() => {
    if (!partitions) {
      return;
    }
    setChartColorByInfo((prevState) => {
      const newColorByInfo = clone(prevState) as ChartColorByInfo;
      newColorByInfo.minMax = partitions;
      newColorByInfo.attributesList = [];
      return newColorByInfo;
    });
  }, [partitions]);

  useEffect(() => {
    if (!topAttributes) {
      return;
    }
    setChartColorByInfo((prevState) => {
      const newColorByInfo = clone(prevState) as ChartColorByInfo;
      newColorByInfo.minMax = {
        min: null,
        max: null,
        hasNull: false,
      };
      newColorByInfo.attributesList = topAttributes;
      return newColorByInfo;
    });
  }, [topAttributes]);

  const handleOnSaveClick = () => {
    let shouldRefetch = false;
    const copiedChartDataList: IChartTypeInfo[] = clone(chartDataList);
    const modifiedChartData: IChartTypeInfo[] = copiedChartDataList.map(
      (chart: IChartTypeInfo) => {
        if (chart.chartId === chartData?.chartId) {
          chart.title = title;

          if (chart.objectType === CHART_TYPES.SCATTER_PLOT) {
            const isYAxisEqual = isYAxisDataFieldsToDisplayEqual(
              chartData?.chartYAxisDataFields,
              selectedYAxisDataField
            );
            if (
              selectedGridDataKeys.length &&
              (!isYAxisEqual ||
                !isXAxisDataFieldEqual ||
                chartData.chartColorBy?.toggle) &&
              !chartColorByInfo?.toggle
            ) {
              shouldRefetch = true;
            }

            chart.chartXAxisDataFields = selectedXAxisDataField;
            chart.chartYAxisDataFields = selectedYAxisDataField;
            chart.chartAxis = chartAxisList;
            chart.chartColorBy = chartColorByInfo;

            if (chartColorByInfo?.toggle && !isConfigEqual) {
              chart.chartData = colorByTrace;
              chart.chartRawData = rawData;
            }
          }
        }
        return chart;
      }
    );

    sortChartDataAlphabetical(modifiedChartData);
    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (chart: IChartTypeInfo) => chart.chartId === chartData?.chartId
    );
    if (updatedChartData) {
      updateChartDataByKey(chartData?.chartId, updatedChartData);
    }

    const modifiedModules = (clone(modules) as Modules[]).map((chart) => {
      if (chart.module === chartData?.chartId) {
        chart.title = title;
      }
      return chart;
    });
    sortModulesAlphabetical(modifiedModules);

    const updatedModule: Modules | undefined = modifiedModules.find(
      (module) => module.module === chartData?.chartId
    );
    if (updatedModule) updateModuleDataByKey(chartData?.chartId, updatedModule);

    const chartConfigModalParams: ChartSettingsModalParams = {
      open: false,
      chartId: "",
      chartType: "",
    };

    updateGetDataFlag({ chartId: chartData?.chartId, refetch: shouldRefetch });
    updateChartModalParam(chartConfigModalParams);
  };

  const handleOnSelectColorByAttrChange = (newValue: string) => {
    if (isColorByAttributeError) {
      setIsColorByAttributeError(false);
    }

    const colorByAttrType = SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES.find(
      (field) => field.key === newValue
    )?.dataType;

    setChartColorByInfo((prevState) => {
      const newColorByInfo = clone(prevState) as ChartColorByInfo;
      newColorByInfo.attributeKey = newValue;
      newColorByInfo.attibuteDataType = colorByAttrType;
      return newColorByInfo;
    });
  };

  const handleColorBySelectBlur = () => {
    setIsColorByAttributeError(
      Boolean(chartColorByInfo?.toggle && !chartColorByInfo.attributeKey)
    );
  };

  const handleColorByToggleChange = () => {
    if (isColorByAttributeError) {
      setIsColorByAttributeError(false);
    }

    setColorByTrace([]);
    setChartColorByInfo((prevState) => {
      return {
        ...prevState,
        toggle: !prevState?.toggle,
        attributeKey: "",
        attibuteDataType: undefined,
        minMax: {
          min: null,
          max: null,
          hasNull: false,
        },
        attributesList: [],
      };
    });
  };

  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {isLoading && (
            <div className="map-overlay">
              <Loading />
            </div>
          )}
          <Grid item xs={6} md={8}>
            <TextField
              id="chart-title"
              sx={{ paddingBottom: 3 }}
              label="Title"
              size="small"
              value={title}
              error={isInputTitleError}
              helperText={isInputTitleError ? inputTitleValidationMessage : ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleTitleOnChange(e);
              }}
              onBlur={handleTitleOnBlur}
              required
            />
          </Grid>
          <Grid className="form-divider" container item></Grid>
          <Grid
            xs={6}
            md={12}
            spacing={3}
            className="align-items-center"
            container
            item
            sx={{ paddingBottom: "10px" }}
          >
            <ColorByPicker
              dataList={formDropdownList.colorByAttributes}
              handleOnSelectChange={handleOnSelectColorByAttrChange}
              handleColorByToggleChange={handleColorByToggleChange}
              chartColorByInfo={chartColorByInfo}
              isError={isColorByAttributeError}
              handleOnBlur={handleColorBySelectBlur}
              fieldToggle={colorRangeFieldsToggle}
            />
          </Grid>
          <Grid className="form-divider" container item></Grid>
          <Grid
            xs={6}
            md={8}
            spacing={3}
            className="align-items-center"
            container
            item
            sx={{ paddingBottom: "10px" }}
          >
            <Grid item className="x-axis-label-container">
              <FormLabel id="x-axis-data-field-label">X Axis </FormLabel>
            </Grid>
            <XAxisDataPicker
              dataList={formDropdownList.xAttributes}
              selected={selectedXAxisDataField?.name ?? ""}
              onChange={handleXAxisDataFieldChange}
            />
          </Grid>
          <Grid className="form-divider" container item></Grid>
          <Grid
            xs={6}
            md={8}
            spacing={7}
            className="align-items-center"
            container
            item
          >
            <Grid item>
              <FormLabel id="scale-label">Y Axis</FormLabel>
            </Grid>
          </Grid>
          <Grid
            xs={6}
            md={12}
            spacing={7}
            className="align-items-center"
            container
            item
          >
            <MultipleAxisConfig
              chartAxisList={chartAxisList}
              setChange={setChartAxisList}
              setIsError={setIsChartAxisLabelError}
              isColorByOn={chartColorByInfo?.toggle ?? false}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <YAxisDataPicker
              onDataFieldsSelection={handleYAxisDataFieldSelection}
              selected={selectedYAxisDataField}
              dataList={formDropdownList.yAttributes}
              chartAxisList={chartAxisList}
              isColorByOn={chartColorByInfo?.toggle ?? false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleClose}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleOnSaveClick}
          disabled={
            isInputTitleError ||
            noYAxisSelected ||
            isColorByAttributeError ||
            (chartColorByInfo?.toggle && !chartColorByInfo.attributeKey) ||
            isChartAxisLabelError
          }
        >
          SAVE
        </Button>
      </DialogActions>
    </>
  );
};

export default ScatterPlotConfig;
