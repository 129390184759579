import { FC, memo, useMemo } from "react";
import { FixedSizeList, areEqual } from "react-window";

import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import {
  CheckboxProps,
  VirtualizedRowList,
} from "../../../../../../types/grid";

import {
  FILTER_BLANK_TEXT_DISPLAY,
  FILTER_IS_EMPTY_OPERATOR,
} from "../../../../../../constants/grid";
import { GRID_COLUMN_FORMATTING_RULES } from "../../../../../../constants/grid/displayFormat";

// eslint-disable-next-line react/display-name
const renderRow = memo((props: VirtualizedRowList) => {
  const { index, style, data } = props;
  const labelId = `checkbox-list-label-${index}`;

  const text =
    data.listItems[index] === FILTER_BLANK_TEXT_DISPLAY
      ? FILTER_IS_EMPTY_OPERATOR.label
      : data.listItems[index];
  const formattedText =
    text !== FILTER_IS_EMPTY_OPERATOR.label &&
    GRID_COLUMN_FORMATTING_RULES[data.field]
      ? GRID_COLUMN_FORMATTING_RULES[data.field]?.formatValue(text)
      : text;

  return (
    <ListItem style={style} key={labelId} disablePadding>
      <ListItemButton
        onClick={() => {
          data.onToggleSwitch([text]);
        }}
        dense
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={data.checked.indexOf(text) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
          />
        </ListItemIcon>
        <Tooltip title={formattedText} placement="right-end">
          <ListItemText
            className="grid-filter-text-list"
            id={labelId}
            primary={formattedText}
          />
        </Tooltip>
      </ListItemButton>
    </ListItem>
  );
}, areEqual);

const CheckboxList: FC<CheckboxProps> = (props) => {
  const { checked, onToggleSwitch, listItems, field } = props;
  const listHeight = 34;

  const itemData = useMemo(
    () => ({
      listItems,
      checked,
      onToggleSwitch,
      field,
    }),
    [listItems, checked, onToggleSwitch, field]
  );

  const pixelHeight = useMemo(() => {
    switch (listItems.length) {
      case 1:
        return 40;
      case 2:
        return 80;
      case 3:
        return 120;
      default:
        return 170;
    }
  }, [listItems]);

  return (
    <div className="checkbox-list-container">
      <FixedSizeList
        height={pixelHeight}
        width={"100%"}
        itemSize={listHeight}
        itemCount={listItems.length}
        overscanCount={5}
        itemData={itemData}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
};

export default CheckboxList;
