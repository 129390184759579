import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import EagleFordWellEconomics from "../../../../assets/images/release-notes/EagleFordWellEconomics.png";
import PermitRecords from "../../../../assets/images/release-notes/PermitRecords.png";
import StorageAssessmentLayers_1 from "../../../../assets/images/release-notes/StorageAssessmentLayers_1.png";
import StorageAssessmentLayers_2 from "../../../../assets/images/release-notes/StorageAssessmentLayers_2.png";

const Version20240819: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240819"}
      onChange={handleChange("Version20240819")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.08.19
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          08/19/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes the addition of Permit data, CO2 Storage
          Assessment Layers, and Eagle Ford Well Economics Data.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>• Permit Activity Tracking</AccordionContent>
      <AccordionSubContent isItalic>
        o Permits are updated daily, allowing users to track drilling activity
        across the U.S., with coverage for Canada coming in a future release.
        Permit data includes well header, location, dates, proposed depths and
        formations, and operator contact information.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Permit records can be searched under Record Type “Permits” or “Wells
        and Permits” in the search query and can be viewed alongside drilled and
        completed wells in the grid, map, and charts. Once a permitted well has
        been drilled, you can find the permit record in the drilled well’s Well
        Panel.
      </AccordionSubContent>
      <AccordionImage src={PermitRecords} width="100%" draggable={false} />

      <AccordionContent>• CO2 Storage Assessment Layers</AccordionContent>
      <AccordionSubContent isItalic>
        o The recently added AR-MS-AL Storage Capacity layers are now available
        in Well Data Analytics under Culture Layers in MAP SETTINGS.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o TGS recently announced a significant expansion of coverage for CO2
        storage assessment to include eight additional U.S. onshore basins, with
        assessments being released as they are completed throughout 2024 and
        2025. The basin coverage includes Gulf Coast Onshore, Permian, Illinois,
        Michigan, Appalachia, North Dakota, Sacramento-San Joaquin and DJ,
        encompassing more than 43,000 wells across 378 million acres.
      </AccordionSubContent>
      <AccordionImage
        src={StorageAssessmentLayers_1}
        width="100%"
        draggable={false}
      />
      <AccordionSubContent isItalic>
        o This new addition offers a comprehensive geologic assessment of saline
        aquifer storage potential by leveraging TGS’s vast subsurface library of
        seismic, well, and geologic data. The subsurface assessment for carbon
        storage in saline aquifers considers the geologic structure, reservoir
        characteristics, fluid dynamics and caprock integrity to provide
        valuable insights when identifying the most promising subsurface
        formations for carbon sequestration.
      </AccordionSubContent>
      <AccordionImage
        src={StorageAssessmentLayers_2}
        width="100%"
        draggable={false}
      />

      <AccordionContent>• Eagle Ford Well Economics Data</AccordionContent>
      <AccordionSubContent isItalic>
        o The recently added Eagle Ford well economics data are now available in
        Well Data Analytics.
      </AccordionSubContent>
      <AccordionImage
        src={EagleFordWellEconomics}
        width="100%"
        draggable={false}
      />
    </Accordion>
  );
};

export default Version20240819;
