import * as dayjs from "dayjs";

import {
  WELLNAME_CARD_MAX_LENGTH_LOWERCASE,
  WELLNAME_CARD_MAX_LENGTH_UPPERCASE,
  WELLNAME_MAX_LENGTH,
} from "../constants/constants";

export const removePropertyFromObject = (object, key) => {
  return Object.keys(object)
    .filter((attributeKey) => attributeKey !== key)
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

/**
 * @param {array} list - Array of data objects
 * @param {array} keys - Array of keys to be used as the key of the object
 * @param {string} dataKeyValue - Specify the key of the data object to be the value
 * @returns {object} - { <key-value>: <data-object> }
 */
export const convertListToObject = (list, keys, dataKeyValue) => {
  let object = {};

  list?.forEach((data) => {
    let valueKey = keys.map((key) => data[key] ?? " ").join("-");
    object[valueKey] = data[dataKeyValue];
  });

  return object;
};

export const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFormattedDate = (date, format) => {
  return dayjs(date).format(format);
};

export const convertToMilliseconds = (dateString) => {
  return dayjs(dateString).valueOf();
};

export const getNumberWithComma = (number) => {
  const numberFormat = Intl.NumberFormat("en-US");
  return numberFormat.format(number);
};

export const hasInvalidCharacters = (regexp, string) => {
  return regexp.test(string);
};

export const stringifyBooleanValues = (value) => {
  if (value === true) return "True";
  if (value === false) return "False";
  return value;
};

/**
 * Compare if two arrays are identical, will not sort the order
 */
export const compareArrays = (a = [], b = []) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const anyInObjIsLoading = (obj) => {
  let isLoading = false;
  for (let item in obj) {
    if (item.true) {
      isLoading = true;
      break;
    }
  }
  return isLoading;
};

export const digits_count = (n) => {
  var count = 0;
  var posN = Math.abs(n);

  if (posN === Infinity) {
    //return count 0 if value is infinity
    return count;
  } else {
    if (posN >= 1) ++count;
    while (posN / 10 >= 1) {
      posN /= 10;
      ++count;
    }

    return count;
  }
};

export const clone = (obj) => JSON.parse(JSON.stringify(obj));

export const digitToUnitMapper = (digit) => {
  if (digit < 4) {
    return "";
  } else if (digit < 7) {
    return " M";
  } else if (digit < 10) {
    return " MM";
  } else if (digit < 13) {
    return " B";
  } else if (digit < 16) {
    return " T";
  } else {
    return "";
  }
};

export const digitToMultiplierMapper = (digit) => {
  if (digit < 4) {
    return 1;
  } else if (digit < 7) {
    return 1e3;
  } else if (digit < 10) {
    return 1e6;
  } else if (digit < 13) {
    return 1e9;
  } else if (digit < 16) {
    return 1e12;
  } else {
    return 1e3;
  }
};

export const getLargestSignificantNumber = (largestNumber) => {
  const digits = digits_count(largestNumber);
  const multiplier = 10 ** (digits - 1);

  return Math.round(largestNumber / multiplier) * multiplier;
};

export const transformToTick = (num) => {
  const digits = digits_count(num);
  return `${num / digitToMultiplierMapper(digits)}${digitToUnitMapper(digits)}`;
};

// apply formatting standards to numbers displayed
export const formattedNumber = (number, formatType) => {
  if (!number && number !== 0) {
    return;
  }
  switch (formatType) {
    case "special-float":
      return specialFloatRule(number);

    default:
      const numWholeDigits = digits_count(number);
      let formattedNumber = number;
      if (numWholeDigits > 2) {
        formattedNumber = Math.round(number);
      }
      return getNumberWithComma(formattedNumber);
  }
};

export const specialFloatRule = (number) => {
  const numInt = parseInt(number);
  const numString = numInt.toString();

  let transformedNum;

  if (!Number.isInteger(number)) {
    if (numString.length === 2) {
      transformedNum = Math.round(numInt / 10) * 10;
    } else {
      const lastDigit = numInt % 10;
      let roundDown = numInt - lastDigit;
      if (lastDigit >= 5) {
        roundDown += 10;
      }
      transformedNum = roundDown;
    }
  } else {
    transformedNum = numInt;
  }
  return getNumberWithComma(transformedNum);
};

export const getFormattedWellNameAndNumber = (wellName, wellNumber) => {
  const numberDisplay = wellNumber ? ` ${wellNumber}` : "";
  const formattedName = `${getFormattedWellName(wellName)}${numberDisplay}`;
  return formattedName.trim();
};

const getFormattedWellName = (wellName) => {
  if (!wellName) return "";
  return wellName.length <= WELLNAME_MAX_LENGTH
    ? wellName
    : `${wellName.slice(0, 6)}...${wellName.slice(-4).trim()}`;
};

export const getFullWellNameAndNumber = (wellName, wellNumber) => {
  const numberDisplay = wellNumber ? `${wellNumber}` : "";
  const formattedName = `${wellName} ${numberDisplay}`;
  return formattedName.trim();
};

export const getTrimmedWellNamePlusNumber = (wellName, wellNumber) => {
  const nameDisplay = wellName && wellName !== "" ? wellName.trim() : "";
  const numberDisplay = wellNumber ? wellNumber.trim() : "";
  const maxLength =
    nameDisplay === nameDisplay.toUpperCase()
      ? WELLNAME_CARD_MAX_LENGTH_UPPERCASE
      : WELLNAME_CARD_MAX_LENGTH_LOWERCASE;
  const addLength = numberDisplay.length > 3 ? 0 : 3;

  return nameDisplay.length <= maxLength
    ? `${nameDisplay}, ${numberDisplay}`
    : `${nameDisplay.slice(0, maxLength + addLength)}..., ${numberDisplay
        .slice(0, 5)
        .trim()}`;
};

const formatDateTime = (data) => {
  return `0${data}`;
};

const dateTimeFormatting = (day, month, year, hour, minute, seconds) => {
  return `${year}-${month}-${day}-${hour}:${minute}:${seconds}`;
};

const convertDateTimeToString = (data) => {
  return data.toString();
};

export const formatDateToString = (val) => {
  var date = new Date(val);
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};
export const formatCurrentDateTime = () => {
  const dateTime = new Date();
  const yyyy = convertDateTimeToString(dateTime.getFullYear());
  let mm = convertDateTimeToString(dateTime.getMonth() + 1);
  let dd = convertDateTimeToString(dateTime.getDate());
  let hr = convertDateTimeToString(dateTime.getHours());
  let min = convertDateTimeToString(dateTime.getMinutes());
  let ss = convertDateTimeToString(dateTime.getSeconds());

  if (mm.length === 1) mm = formatDateTime(mm);
  if (dd.length === 1) dd = formatDateTime(dd);
  if (hr.length === 1) hr = formatDateTime(hr);
  if (min.length === 1) min = formatDateTime(min);
  if (ss.length === 1) ss = formatDateTime(ss);

  let date = dateTimeFormatting(dd, mm, yyyy, hr, min, ss);
  return date;
};

export const handleKeyDown = (e) => {
  if (e.code === "13") {
    toggleContent();
  }
};

//Round of number at n{scale} places
export const roundNumber = (num, scale) => {
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(
      Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
      "e-" +
      scale
    );
  }
};

export const removeTrailingZeros = (num) => {
  return typeof num !== "string"
    ? String(num).replace(/\.0+$/, "")
    : num.replace(/\.0+$/, "");
};

export const formatNumberInK = (number, division) => {
  return `${getNumberWithComma(Math.round(Math.abs(number) / division))}K`;
};

export const toPercentageValue = (num) => {
  const value = num < 0 ? num * 100 : num;

  return roundNumber(value, 0) + "%";
};
