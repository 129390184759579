import {
  DatalakePressureDataResponseBody,
  TestType,
} from "../../../types/panels/wellPanel/dataLakeType";
import {
  PressureDataWellPanel,
  RecoveryRemarksData,
} from "../../../types/panels/wellPanel/pressureDataType";

import {
  DST_TEST_TYPE_LABEL,
  PRESSURE_DATA_TEST_TYPES,
} from "../../../constants/panels/wellPanel/pressureData";

import {
  floatValidation,
  stringValidation,
} from "../../../utils/panel/wellPanel/fieldValidation";
import { sortPanelData } from "../../../utils/panel/wellPanel/sorting";

import { clone } from "../../../utils";

const usePressureData = () => {
  const getTestType = (testType: TestType) => {
    return testType === PRESSURE_DATA_TEST_TYPES.DST ||
      testType === PRESSURE_DATA_TEST_TYPES.DST_PLUS
      ? DST_TEST_TYPE_LABEL
      : "";
  };

  const createTestName = (testType: TestType, dstNumber: string) => {
    const dstTestTypeLabel = getTestType(testType);

    return `${dstTestTypeLabel ?? ""} - ${dstNumber ?? ""}`;
  };

  const formatTemp = (temp: number | null) => {
    return stringValidation(
      `${temp?.toString() ? `${floatValidation(temp, 0, true)} \u00B0F` : ""}`
    );
  };

  const formatDepthData = (depth: number | null) => {
    return stringValidation(
      `${depth?.toString() ? `${floatValidation(depth, 0)} ft` : ""}`
    );
  };

  const formatPressureData = (duration: number | null, end: number | null) => {
    return stringValidation(
      `${
        duration?.toString() ? `${floatValidation(duration, 0, true)}' ` : ""
      }${end?.toString() ? `${floatValidation(end, 0, true)} psi` : ""}`
    );
  };

  const formatRecoveryRemarks = (recoveryData: RecoveryRemarksData) => {
    let recoveryRemarksText = "";

    const buildRemarks = (
      amt: number | null,
      units: string | null,
      desc: string | null
    ) => {
      return `${amt?.toString() ? `${floatValidation(amt, 0, true)} ` : ""}${
        units ? `${units} ` : ""
      }${desc ? `${desc} ` : ""}`;
    };

    const firstRecovery = buildRemarks(
      recoveryData.FirstRecoveryAmt,
      recoveryData.FirstRecoveryAmtUnits,
      recoveryData.FirstRecoveryDesc
    );
    const secondRecovery = buildRemarks(
      recoveryData.SecondRecoveryAmt,
      recoveryData.SecondRecoveryAmtUnits,
      recoveryData.SecondRecoveryDesc
    );
    const thirdRecovery = buildRemarks(
      recoveryData.ThirdRecoveryAmt,
      recoveryData.ThirdRecoveryAmtUnits,
      recoveryData.ThirdRecoveryDesc
    );
    const fourthRecovery = buildRemarks(
      recoveryData.FourthRecoveryAmt,
      recoveryData.FourthRecoveryAmtUnits,
      recoveryData.FourthRecoveryDesc
    );
    const fifthRecovery = buildRemarks(
      recoveryData.FifthRecoveryAmt,
      recoveryData.FifthRecoveryAmtUnits,
      recoveryData.FifthRecoveryDesc
    );
    const sixthRecovery = buildRemarks(
      recoveryData.SixthRecoveryAmt,
      recoveryData.SixthRecoveryAmtUnits,
      recoveryData.SixthRecoveryDesc
    );

    recoveryRemarksText = recoveryRemarksText.concat(
      firstRecovery,
      secondRecovery,
      thirdRecovery,
      fourthRecovery,
      fifthRecovery,
      sixthRecovery
    );

    return stringValidation(recoveryRemarksText);
  };

  const dataMapper = (data: DatalakePressureDataResponseBody) => {
    return {
      TestType: getTestType(data.DocType as TestType),
      DSTNo: data.DstNumber,
      TestName: createTestName(data.DocType as TestType, data.DstNumber),
      Formation: data.ReportedFormation?.toUpperCase() ?? "",
      TgsLandingZone: stringValidation(data.TgsLandingZone ?? ""),
      Temperature: formatTemp(data.DstTemp),
      Top: formatDepthData(data.IntF),
      Bottom: formatDepthData(data.IntT),
      FirstFlow: formatPressureData(data.FirstFlowDuration, data.FirstFlowEnd),
      FirstFlowShutIn: formatPressureData(
        data.FirstShutinDuration,
        data.FirstShutinEnd
      ),
      SecondFlow: formatPressureData(
        data.SecondFlowDuration,
        data.SecondFlowEnd
      ),
      SecondFlowShutIn: formatPressureData(
        data.SecondShutinDuration,
        data.SecondShutinEnd
      ),
      RecoveryRemarks: formatRecoveryRemarks({
        FirstRecoveryAmt: data.FirstRecoveryAmt,
        FirstRecoveryAmtUnits: data.FirstRecoveryAmtUnits,
        FirstRecoveryDesc: data.FirstRecoveryDesc,
        SecondRecoveryAmt: data.SecondRecoveryAmt,
        SecondRecoveryAmtUnits: data.SecondRecoveryAmtUnits,
        SecondRecoveryDesc: data.SecondRecoveryDesc,
        ThirdRecoveryAmt: data.ThirdRecoveryAmt,
        ThirdRecoveryAmtUnits: data.ThirdRecoveryAmtUnits,
        ThirdRecoveryDesc: data.ThirdRecoveryDesc,
        FourthRecoveryAmt: data.FourthRecoveryAmt,
        FourthRecoveryAmtUnits: data.FourthRecoveryAmtUnits,
        FourthRecoveryDesc: data.FourthRecoveryDesc,
        FifthRecoveryAmt: data.FifthRecoveryAmt,
        FifthRecoveryAmtUnits: data.FifthRecoveryAmtUnits,
        FifthRecoveryDesc: data.FifthRecoveryDesc,
        SixthRecoveryAmt: data.SixthRecoveryAmt,
        SixthRecoveryAmtUnits: data.SixthRecoveryAmtUnits,
        SixthRecoveryDesc: data.SixthRecoveryDesc,
      }),
    } as PressureDataWellPanel;
  };

  const buildPressureData = (rawData: DatalakePressureDataResponseBody[]) => {
    let parsedPressureData: PressureDataWellPanel[] = [];

    //Filter DST Plus
    const filteredDstDataPlusData = rawData.filter(
      (pressureData) =>
        pressureData.DocType === PRESSURE_DATA_TEST_TYPES.DST_PLUS
    );

    //Filter DST that doesn't have DST Plus data
    const filteredDstDataPlusNumberList = (
      clone(filteredDstDataPlusData) as DatalakePressureDataResponseBody[]
    ).map((dstPlusData) => dstPlusData.DstNumber);

    const filteredDstData = rawData.filter(
      (dstData) =>
        dstData.DocType === PRESSURE_DATA_TEST_TYPES.DST &&
        !filteredDstDataPlusNumberList.includes(dstData.DstNumber)
    );

    //map data
    const mappedDSTPlustData = (
      clone(filteredDstDataPlusData) as DatalakePressureDataResponseBody[]
    ).map((dstPlusData) => {
      return dataMapper(dstPlusData);
    });

    const mapDstData = (
      clone(filteredDstData) as DatalakePressureDataResponseBody[]
    ).map((dstData) => {
      return dataMapper(dstData);
    });

    parsedPressureData = [...mappedDSTPlustData, ...mapDstData];

    return sortPanelData(parsedPressureData, "TestName");
  };

  return {
    buildPressureData,
  };
};

export default usePressureData;
