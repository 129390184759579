import { GridDisplayFormat } from "../../types/grid";

import { formatNumberByAttribute } from "../../utils/numberFormatter";

import { toPercentageValue } from "../../utils";
import * as attribute from "../attributes";

export const GRID_COLUMN_FORMATTING_RULES: GridDisplayFormat = {
  [attribute.LOE_COST.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value ? formatNumberByAttribute(value, attribute.LOE_COST.key) : "") ??
      "",
  },
  [attribute.GPT_COST.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value ? formatNumberByAttribute(value, attribute.GPT_COST.key) : "") ??
      "",
  },
  [attribute.WATER_COST.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      value
        ? formatNumberByAttribute(value, attribute.WATER_COST.key) ?? ""
        : "",
  },
  [attribute.FIXED_COST.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value ? formatNumberByAttribute(value, attribute.FIXED_COST.key) : "") ??
      "",
  },
  [attribute.DRILLING_COMPLETION_CAP_EX.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value
        ? formatNumberByAttribute(
            value,
            attribute.DRILLING_COMPLETION_CAP_EX.key
          )
        : "") ?? "",
  },
  [attribute.INFRA_CAP_EX.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value
        ? formatNumberByAttribute(value, attribute.INFRA_CAP_EX.key)
        : "") ?? "",
  },
  [attribute.WORKING_INTEREST.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) => (value ? toPercentageValue(value) : ""),
  },
  [attribute.ROYALTY.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) => (value ? toPercentageValue(value) : ""),
  },
  [attribute.INTERNAL_RATE_OF_RETURN.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) => (value ? toPercentageValue(value) : ""),
  },
  [attribute.BREAK_EVEN_PRICE.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value
        ? formatNumberByAttribute(value, attribute.BREAK_EVEN_PRICE.key)
        : "") ?? "",
  },
  [attribute.NET_PRESENT_VALUE.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value
        ? formatNumberByAttribute(value, attribute.NET_PRESENT_VALUE.key)
        : "") ?? "",
  },
  [attribute.PAYOUT.key]: {
    regex: [], //for expression builder input validation
    formatValue: (value) =>
      (value ? formatNumberByAttribute(value, attribute.PAYOUT.key) : "") ?? "",
  },
};
