import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "./../StyledAccordions";

import EagleFord from "../../../../assets/images/eagle-ford.png";
import FieldsPicker from "../../../../assets/images/fields-picker.png";
import PlottingGroup from "../../../../assets/images/plotting-group.png";

const Version20230914: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20230914"}
      onChange={handleChange("Version20230914")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2023.09.14
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          9/14/2023
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This incremental release includes an overhaul to the production plot
          and type curve group-by feature and the addition of Eagle Ford
          structural maps.
        </CustomTypography>
        <AccordionContent>• Plotting Group-by Enhancement</AccordionContent>
        <AccordionSubContent isItalic>
          o Selecting the Group-By or Individual Wells setting in the Production
          Plot and Type Curve charts will now result in a more distinctive color
          palette that aligns with other styling palettes throughout the
          application.
        </AccordionSubContent>
        <AccordionImage src={PlottingGroup} width="100%" draggable={false} />
        <AccordionSubContent isItalic>
          o The data fields picker in the Production Plot and Type Curve
          settings will now change to a radio button selection after switching
          to the Group-By or Individual Wells options. This reflects the
          necessary tradeoff to having a more distinctive color palette, but
          only for one data field at a time.
        </AccordionSubContent>
        <AccordionImage src={FieldsPicker} width={400} draggable={false} />
        <AccordionContent>• Eagle Ford Structure Map Layers</AccordionContent>
        <AccordionSubContent isItalic>
          o The recently updated Eagle Ford stratigraphic model layers are now
          available in Well Data Analytics.
        </AccordionSubContent>
        <AccordionImage src={EagleFord} width={400} draggable={false} />
      </AccordionDetails>
    </Accordion>
  );
};

export default Version20230914;
