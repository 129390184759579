/**
 * Retrieves the 'x' values given a list of coordinates by default
 * Can also retrieve the 'y' values
 * @param {*} coordinates
 * @param {*} key
 * @returns
 */
const getCoordinates = (coordinates = [], key = "x") => {
  return coordinates.map((c) => c[key]);
};

const addPadding = (value, padding = -350) => {
  return value + padding;
};

const calculateMidPoint = (n1, n2 = 0) => {
  return (n1 + n2) / 2;
};

/**
 * Also Rounds difference to nearest 10
 * @param {*} d1
 * @param {*} d2
 * @returns
 */
const getWellDistanceFromTargetWell = (d1, d2 = 0) => {
  return Math.round(Math.abs(d1 - d2) / 10) * 10;
};

const getFormationDistanceFromTargetWell = (d1, d2) => {
  return Math.abs(d1 - d2);
};

const compare = (a, b) => {
  return a.y < b.y ? 1 : -1;
};

export {
  getCoordinates,
  addPadding,
  calculateMidPoint,
  getFormationDistanceFromTargetWell,
  getWellDistanceFromTargetWell,
  compare,
};
