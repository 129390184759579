import { WellCounts } from "../../types/common/wells";

import {
  ATTRIBUTE_INFO_BY_KEY,
  BOOLEAN,
  RECORD_TYPE,
} from "../../constants/attributes";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import { capitalizeString } from "../../utils/helper";

import useRecordType from "./useRecordType";

const useAttributeOptionsPostProcess = () => {
  const { getRecordTypeLabel } = useRecordType();

  const formatOptions = (attribute: string, options: WellCounts[]) => {
    const dataType = ATTRIBUTE_INFO_BY_KEY[attribute].dataType;
    let formattedOptions: WellCounts[] = [];
    switch (dataType) {
      case BOOLEAN:
        formattedOptions = options.map((option) => ({
          ...option,
          attributeValue: capitalizeString(option.attributeValue),
        }));
        break;
      default:
        formattedOptions = options;
        break;
    }

    return formattedOptions;
  };

  const processOptions = (attribute: string, options: WellCounts[]) => {
    const dataKey = ATTRIBUTE_INFO_BY_KEY[attribute].key;
    let processedOptions: WellCounts[] = options;

    switch (dataKey) {
      case RECORD_TYPE.key:
        const wellOptionCount =
          options.find((option) => option.attributeValue === RECORD_TYPES.WELL)
            ?.count ?? 0;
        const permitOptionCount =
          options.find(
            (option) => option.attributeValue === RECORD_TYPES.PERMIT
          )?.count ?? 0;

        // only show wells and permits options if there is either a well or a permit
        if (permitOptionCount > 0 || wellOptionCount > 0) {
          const combinedOption: WellCounts = {
            attributeValue: RECORD_TYPES.WELLS_AND_PERMIT,
            count: permitOptionCount + wellOptionCount,
          };

          processedOptions.push(combinedOption);
        }

        processedOptions = processedOptions.map((option) => {
          return {
            ...option,
            label: getRecordTypeLabel(option.attributeValue),
          };
        });
        break;
    }

    return processedOptions;
  };

  const getOptionLabelByAttribute = (attribute: string, option: string) => {
    if (attribute === RECORD_TYPE.key) {
      return getRecordTypeLabel(option);
    }
  };

  return { formatOptions, processOptions, getOptionLabelByAttribute };
};

export default useAttributeOptionsPostProcess;
