import { useState } from "react";

import axios from "axios";

import { BaseSearchCriteria } from "../../../types/common/search";
import {
  GetWellSpotAttribValuesPayload,
  GetWellSpotAttribValuesProps,
  GetWellSpotAttribValuesResponse,
  WellStylingObject,
} from "../../../types/map/mapSettings/useWellSpotStyling";

import config from "../../../configs/appSettings";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";

import { settledPromise } from "../../../utils/api/request";

import { callServiceAPI } from "../../../action/callServiceAPI";
import useSearchRequest from "../../common/useSearchRequest";
import useUwiFileUpload from "../../search/useUwiFileUpload";

const wellSpotAttributesUrl = `${config.endpoints.wellService}api/wells/well-spot-attributes/`;

const useWellSpotStyling = () => {
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { buildSearchRequestByParam } = useSearchRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<WellStylingObject[]>([]);
  const [error, setError] = useState<unknown>(null);
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const updateIsProcessingStyles = useMapSettingsStore(
    (state) => state.updateIsProcessingStyles
  );

  const getWellSpotAttribValues = async ({
    attribute,
    searchCriteria,
    layer,
  }: GetWellSpotAttribValuesProps) => {
    setIsLoading(true);
    setData([]);
    setError(null);

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons: searchCriteria.drawnPolygons,
      shapeId: searchCriteria.shapeId,
      currentBounds: searchCriteria.currentBounds,
      fileId: searchCriteria.fileId,
      searchedUWIs: searchCriteria.searchedUWIs,
      filters: searchCriteria.filters,
    };

    const searchRequestBody = buildSearchRequestByParam(
      requestBodySearchCriteria
    );

    const body: GetWellSpotAttribValuesPayload = {
      attribute,
      searchRequest: { ...searchRequestBody, offset: 0, pageLimit: 1 },
      layer,
    };

    try {
      const initialResponse =
        await callServiceAPI<GetWellSpotAttribValuesResponse>(
          wellSpotAttributesUrl,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          isFromUploadedUWIFile
        );

      if (!initialResponse || !("data" in initialResponse)) {
        return;
      }

      const totalCount = initialResponse.data.totalCount;
      const pageLimit = config.searchPageLimit;
      const totalIterations = Math.ceil(totalCount / pageLimit);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((num, i) => {
          return axios.post<GetWellSpotAttribValuesResponse>(
            wellSpotAttributesUrl,
            {
              ...body,
              searchRequest: {
                ...body.searchRequest,
                offset: i * pageLimit,
                pageLimit,
              },
            },
            {
              ignoreResponseInterceptor: true,
            }
          );
        });

        settledPromise(responses, (values) => {
          const allData = values.flatMap(
            (response) => response.data.wellSpotAttributes
          );
          setData(allData);

          // Note: Can't move to "finally", due to the loader
          // disappearing before the API call finishes
          updateIsProcessingStyles(false);
          setIsLoading(false);
        });
      } else {
        updateIsProcessingStyles(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("getWellSpotAttribValues error", error);
      setError(error);
      updateIsProcessingStyles(false);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    getWellSpotAttribValues,
  };
};

export default useWellSpotStyling;
