export const sortChartDataAlphabetical = (chartData) => {
  chartData.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB =
      b.title.toString() === ""
        ? (b.chartType + "_" + b.chartId).toLowerCase()
        : b.title.toLowerCase();

    if (titleA.toString() < titleB.toString()) {
      return -1;
    }
    if (titleA.toString() > titleB.toString()) {
      return 1;
    }

    return 0;
  });
};

export const sortModulesAlphabetical = (modules) => {
  modules.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB =
      b.title.toString() === ""
        ? (b.chartType + "_" + b.module).toLowerCase()
        : b.title.toLowerCase();

    if (titleA.toString() < titleB.toString()) {
      return -1;
    }
    if (titleA.toString() > titleB.toString()) {
      return 1;
    }

    return 0;
  });
};

export const getTopDataField = (selectedDataField, dataFieldsList) => {
  if (selectedDataField.length <= 1) {
    return selectedDataField;
  }
  let selectedIndex = [];
  selectedDataField.forEach((dataField) => {
    let matchedDataFieldIndex = dataFieldsList.findIndex(
      (data) => data.id === dataField.id
    );
    let selectedIndexObj = {
      index: matchedDataFieldIndex,
      dataField: dataField,
    };

    selectedIndex.push(selectedIndexObj);
  });

  const indexedSelectedList = selectedIndex.sort((a, b) => a.index - b.index);

  const newSelectedList = [indexedSelectedList[0].dataField];

  return newSelectedList;
};
export default { sortChartDataAlphabetical, sortModulesAlphabetical };
