import { useMemo, useRef, useState } from "react";
import Plot from "react-plotly.js";

import { GunBarrelInfo } from "../../../types/panels/wellPanel/gunBarrelType";

import { GUN_BARREL } from "../../../constants/constants";

import { gunBarrelLayout } from "../../../store/chart/gunBarrelStore";

import { useLoadGunBarrel } from "../../../customHooks/charts/useLoadGunBarrel";

import {
  handleOnHover,
  handleOnUnHover,
} from "../../WellCard/utils/eventHandlers";

import Loading from "../../common/Loading";
import { TGSLogo } from "../../common/icons";

interface GunBarrelProps {
  gunBarrelData: GunBarrelInfo | undefined;
  uwiNumber: string | number | boolean;
  chartExport: boolean;
}

const GunBarrelTab = ({
  gunBarrelData,
  uwiNumber,
  chartExport,
}: GunBarrelProps) => {
  const initialData = useRef();
  const initialLayout = useRef();

  const [state, setState] = useState<gunBarrelLayout>({
    data: [],
    layout: undefined,
    revision: 0,
  });

  const originalData = {
    data: initialData,
    layout: initialLayout,
  };

  const chartExportTitle = useMemo(() => {
    if (!chartExport) return <></>;

    return (
      <div className="chart-name">
        <div className="chart-type">{`${GUN_BARREL}_${uwiNumber}`}</div>
      </div>
    );
  }, [chartExport, uwiNumber]);

  const chartExportLogo = useMemo(() => {
    if (!chartExport) return <></>;

    return (
      <div className="tgs-logo-watermark-gun-barrel">
        <TGSLogo />
      </div>
    );
  }, [chartExport]);

  useLoadGunBarrel(gunBarrelData, setState, originalData, chartExport);

  if (!originalData.data.current && chartExport) return null;

  return (
    <>
      {originalData.data.current ? (
        <div className="gun-barrel-container">
          {chartExportTitle}
          <Plot
            config={{ responsive: true, displayModeBar: false }}
            onHover={(e) =>
              handleOnHover({ e, setState, initialData, initialLayout })
            }
            revision={state.revision}
            onUnhover={(e) =>
              handleOnUnHover({ e, setState, initialData, initialLayout })
            }
            data={state.data}
            layout={state.layout}
          />
          {chartExportLogo}
        </div>
      ) : (
        <div className="flexContainer-Spinner">
          <Loading />
        </div>
      )}
    </>
  );
};

export default GunBarrelTab;
