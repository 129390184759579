import { Button } from "component-library";

import useDataGridStore from "../../../../store/grid/dataGridStore";
import useMapSelectionStore from "../../../../store/map/selection/mapSelectionStore";

import { getNumberWithComma } from "../../../../utils";

const SelectedForAnalysis = () => {
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const sortByAnalysis = useDataGridStore((state) => state.sortByAnalysis);
  const updateSortByAnalysis = useDataGridStore(
    (state) => state.updateSortByAnalysis
  );

  return (
    <>
      {!!selectedGridDataKeys.length && (
        <Button
          type={"tertiary"}
          text={`Selected for Analysis ${getNumberWithComma(
            selectedGridDataKeys.length
          )}`}
          onClick={() => updateSortByAnalysis(!sortByAnalysis)}
          state={sortByAnalysis ? "pressed" : "enabled"}
        />
      )}
    </>
  );
};

export default SelectedForAnalysis;
