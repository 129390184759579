export const testColumns = [
  {
    field: "testDate",
    headerName: "Test Date",
    width: 90,
    sortable: false,
  },
  {
    field: "testType",
    headerName: "Test Type",
    width: 90,
    sortable: false,
  },
  {
    field: "oil",
    headerName: "Oil",
    width: 10,
    sortable: false,
    type: "number",
  },
  {
    field: "gas",
    headerName: "Gas",
    width: 10,
    sortable: false,
    type: "number",
  },
  {
    field: "water",
    headerName: "Water",
    width: 60,
    sortable: false,
    type: "number",
  },
  {
    field: "testDuration",
    headerName: "Test Duration",
    width: 110,
    sortable: false,
    type: "number",
  },
];
