import { Curves } from "../../../types/panels/wellPanel/dataLakeType";

import { logTypeValues } from "../../../constants/panels/wellPanel/logs";

const useLogsData = () => {
  const getLogTypeDisplayName = (logType: string) => {
    let mappedLogType = "";
    const convertedLogType = logType?.toLowerCase();

    switch (convertedLogType) {
      case logTypeValues.STANDARD.key:
        mappedLogType = logTypeValues.STANDARD.displayName;
        break;
      case logTypeValues.WORK_STATION_READY.key:
        mappedLogType = logTypeValues.WORK_STATION_READY.displayName;
        break;
      case logTypeValues.RASTER.key:
        mappedLogType = logTypeValues.RASTER.displayName;
        break;
      case logTypeValues.MUD.key:
        mappedLogType = logTypeValues.MUD.displayName;
        break;
      case logTypeValues.LITHOLOGY.key:
        mappedLogType = logTypeValues.LITHOLOGY.displayName;
        break;
      case logTypeValues.ANALYTIC_READY_LAS.key:
        mappedLogType = logTypeValues.ANALYTIC_READY_LAS.displayName;
        break;
      case logTypeValues.PETROPHYSICAL_LOGS.key:
        mappedLogType = logTypeValues.PETROPHYSICAL_LOGS.displayName;
        break;
      default:
        break;
    }

    return mappedLogType;
  };

  const getLASDescription = (curvesList: Curves[]) => {
    let description = "";
    if (curvesList) {
      const curveClassCdList = curvesList
        .map((curve) => curve.CurveClassCd)
        .filter((curveClass) => curveClass);
      const uniqueList = curveClassCdList.filter((curveClass, index, arr) => {
        return arr.indexOf(curveClass) === index;
      });

      description = uniqueList.join(", ");
    }

    return description;
  };

  return {
    getLogTypeDisplayName,
    getLASDescription,
  };
};

export default useLogsData;
