export const perforationsColumns = [
  {
    field: "perfDate",
    headerName: "Perf Date",
    width: 100,
    sortable: false,
  },
  {
    field: "perfTop",
    headerName: "Perf Top",
    width: 100,
    sortable: false,
    type: "number",
  },
  {
    field: "perfBottom",
    headerName: "Perf Bottom",
    width: 100,
    sortable: false,
    type: "number",
  },
];
