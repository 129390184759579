import {
  WellData,
  WellPanelSectionType,
} from "../../../../types/panels/wellPanel/wellPanel";

import {
  DECLINE_CURVE_DECIMAL_FORMAT,
  WELL_INFO_DECLINE_CURVE_ATTRIBUTES,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import {
  floatValidation,
  stringValidation,
} from "../../../../utils/panel/wellPanel/fieldValidation";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../common/WellPanelAccordion";

interface DeclineCurveParametersProps {
  data: WellData;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}

const DeclineCurveParameters = ({
  data,
  isExpanded,
  onChange,
}: DeclineCurveParametersProps) => {
  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION);
  };
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={false}
    >
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.label}
            value={stringValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.B_FACTOR
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.label}
            value={stringValidation(
              data[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.key] as string
            )}
          />
        </div>
      </div>
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.label}
            value={stringValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.B_FACTOR
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.label}
            value={stringValidation(
              data[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.key] as string
            )}
          />
        </div>
      </div>
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.label}
            value={stringValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.label}
            value={floatValidation(
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.B_FACTOR
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.label}
            value={stringValidation(
              data[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.key] as string
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default DeclineCurveParameters;
