import { StateCreator } from "zustand";

import {
  ChartData,
  ChartExport,
  ChartSettings,
} from "../../types/charts/chartStore/chartStore";

import { PRODUCTION_DATA_FIELDS_LIST } from "../../constants/charts/charts";

const chartDataSlice: StateCreator<ChartData> = (set, get) => ({
  chartData: [],
  addChartData: (chartData) =>
    set((state) => ({ chartData: [...state.chartData, chartData] })),
  updateChartData: (chartData) => set((state) => ({ chartData })),
  updateChartDataByKey: (chartId, chartData) =>
    set(() => ({
      chartData: get().chartData.map((currentChartData) => {
        const newChartData =
          currentChartData?.chartId === chartId ? chartData : currentChartData;
        return newChartData;
      }),
    })),
  updateTraceLegendVisibilityByKey: (
    chartId,
    visibility,
    curveNumber,
    legendGroupClick,
    legendGroupName
  ) => {
    const chart = get().chartData.find((data) => data.chartId === chartId);

    if (chart) chart.chartData[curveNumber].visible = visibility;

    if (chart && legendGroupClick && legendGroupName) {
      chart.chartData = chart.chartData.map((trace) => {
        if (trace.legendgroup === legendGroupName) {
          trace.visible = visibility;
        }
        return trace;
      });
    }
    return set(() => ({
      chartData: get().chartData.map((currentChartData) => {
        const newChartData =
          chart && currentChartData?.chartId === chartId
            ? chart
            : currentChartData;
        return newChartData;
      }),
    }));
  },
  removeChartDataByKey: (chartId) =>
    set(() => ({
      chartData: get().chartData.filter(
        (currentChartData) => currentChartData.chartId !== chartId
      ),
    })),
});

const chartSettingsSlice: StateCreator<ChartSettings> = (set, get) => ({
  chartSettingsModalParams: {
    open: false,
    chartId: "",
    chartType: "",
  },
  chartDataRefetchFlag: { chartId: "", refetch: false },
  chartSettingsDataFieldsList: PRODUCTION_DATA_FIELDS_LIST,
  setChartSettingsModalParams: (chartSettingsModalParams) =>
    set((state) => ({ chartSettingsModalParams })),
  setChartDataRefetchFlag: (chartDataRefetchFlag) =>
    set((state) => ({ chartDataRefetchFlag })),
});

const chartExportSlice: StateCreator<ChartExport> = (set, get) => ({
  apiChartDataExcel: [],
  // addApiChartDataExcel: (apiChartDataExcel: ChartExportObject[]) =>
  //   set((state) => ({
  //     apiChartDataExcel: [...get().apiChartDataExcel, apiChartDataExcel],
  //   })),
  // updateApiChartDataExcelByID: (id: string, apiChartDataExcel: ChartExportObject[]) =>
  //   set(state => ({
  //     apiChartDataExcel: get().apiChartDataExcel.map(ChartDataDatum => {
  //       if (ChartDataDatum.chartId === id) {
  //         ChartDataDatum.data = apiChartDataExcel.data;
  //       }
  //       return ChartDataDatum;
  //     }),
  //   })),
  // removeApiChartDataExcelByID: id =>
  //   set(state => ({
  //     apiChartDataExcel: get().apiChartDataExcel.filter(
  //       ChartDataDatum => ChartDataDatum.chartId !== id,
  //     ),
  //   })),

  apiChartDataForecastExcel: [],
  // addApiChartDataForecastExcel: apiChartDataForecastExcel =>
  //   set(state => ({
  //     apiChartDataForecastExcel: [
  //       ...get().apiChartDataForecastExcel,
  //       apiChartDataForecastExcel,
  //     ],
  //   })),
  // updateApiChartDataForecastExcelByID: (id, apiChartDataForecastExcel) =>
  //   set(state => ({
  //     apiChartDataForecastExcel: get().apiChartDataForecastExcel.map(
  //       ChartDataDatum => {
  //         if (ChartDataDatum.chartId === id) {
  //           ChartDataDatum.data = apiChartDataForecastExcel.data;
  //         }
  //         return ChartDataDatum;
  //       },
  //     ),
  //   })),
  // removeApiChartDataForecastExcelByID: id =>
  //   set(state => ({
  //     apiChartDataForecastExcel: get().apiChartDataForecastExcel.filter(
  //       ChartDataDatum => ChartDataDatum.chartId !== id,
  //     ),
  //   })),
});

export { chartDataSlice, chartExportSlice, chartSettingsSlice };
