import { Casings } from "../../types/panels/wellPanel/casingsType";
import { WellDataExport } from "../../types/panels/wellPanel/exportWellPanel";
import { Formations } from "../../types/panels/wellPanel/formationType";
import { Perforation } from "../../types/panels/wellPanel/perforationType";
import { Permits } from "../../types/panels/wellPanel/permitsType";
import { PressureDataWellPanelExport } from "../../types/panels/wellPanel/pressureDataType";
import { Test } from "../../types/panels/wellPanel/testType";
import { WellData } from "../../types/panels/wellPanel/wellPanel";

import {
  INTERPRETED_PRODUCING_FORMATION,
  PERMIT_APPROVAL_DATE,
} from "../../constants/attributes";
import { specialFloat } from "../../constants/constants";
import {
  DST_NO,
  DST_TEMP,
  REPORTED_FORMATION,
  TEST_TYPE,
  TGS_LANDING_ZONE,
} from "../../constants/panels/wellPanel/pressureData";
import {
  DECLINE_CURVE_DECIMAL_FORMAT,
  UWI,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES,
  WELL_INFO_DECLINE_CURVE_ATTRIBUTES,
  WELL_INFO_HEADER_ATTRIBUTES,
  WELL_INFO_STATISTICS_ATTRIBUTES,
  WELL_INFO_WELL_NAME,
  WELL_INFO_WELL_NUMBER,
  WELL_INFO_WELL_STATUS,
  WELL_PANEL_FLAGS,
  WELL_PANEL_SECTION,
} from "../../constants/panels/wellPanel/wellInfo";

import { fieldValidation } from "../../components/WellCard/utils/fieldValidation";

import { convertStringToDate } from "../../utils/charts/aggregator";
import {
  floatValidation,
  stringValidation,
} from "../../utils/panel/wellPanel/fieldValidation";

import { formattedNumber } from "../../utils";

const useSortWellPanelData = () => {
  const sortWellPanelContent = (data: WellData[], exportList: string[]) => {
    const headerArray: WellDataExport[] = [];
    const statisticsArray: WellDataExport[] = [];
    const declineCurveParametersArray: WellDataExport[] = [];
    const completionArray: WellDataExport[] = [];

    data?.forEach((item) => {
      if (exportList.includes(WELL_PANEL_SECTION.HEADER_SECTION.key)) {
        headerArray?.push({
          [UWI.label]: item.uwi,
          [WELL_INFO_WELL_NAME.label]: fieldValidation(item.wellName),
          [WELL_INFO_WELL_NUMBER.label]: fieldValidation(item.wellNumber),
          [WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.label]: fieldValidation(
            item.stateName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNTY.label]: fieldValidation(
            item.county
          ),
          [WELL_INFO_WELL_STATUS.label]: fieldValidation(item.wellStatus),
          [WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label]: fieldValidation(
            item.wellType
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.SLANT.label]: fieldValidation(
            item.slant
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.label]: fieldValidation(
            item.operatorName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.ULTIMATE_OWNER.label]: fieldValidation(
            item.ultimateOwner
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.label]: fieldValidation(
            item.fieldName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.label]: fieldValidation(
            item.basinName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.label]:
            fieldValidation(item.displayFormation),
          [INTERPRETED_PRODUCING_FORMATION.label]: fieldValidation(
            item.interpretedProducingFormation,
            "num"
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.label]: fieldValidation(
            item.measuredDepth,
            "num"
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.label]:
            fieldValidation(item.totalVerticalDepth, "num"),
          [WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.label]: fieldValidation(
            item.lateralLength,
            "num"
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.label]: fieldValidation(
            item.spudDate,
            "date"
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.label]: fieldValidation(
            item.completionDate,
            "date"
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.ELEVATION.label]: fieldValidation(
            item.displayElevation,
            "special"
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.label]: fieldValidation(
            item.hasProduction
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.label]: fieldValidation(
            item.hasForecast
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.label]:
            fieldValidation(item.perfIntervalTop, "num"),
          [WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.label]:
            fieldValidation(item.perfIntervalBottom, "num"),

          [WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.label]:
            fieldValidation(item.closestHorizontalDistanceInZone, specialFloat),
          [WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
            .label]: fieldValidation(
            item.closestHorizontalDistance,
            specialFloat
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.label]:
            fieldValidation(item.closestVerticalDistance, specialFloat),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.label]:
            fieldValidation(item.countOfParentWells, "num"),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.label]:
            fieldValidation(item.countOfChildWells, "num"),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.label]:
            fieldValidation(item.countOfSiblingWells, "num"),
        });
      }

      if (
        exportList.includes(WELL_PANEL_SECTION.STATISTICS_SECTION.key) &&
        (item[WELL_PANEL_FLAGS.HAS_PRODUCTION.key] ||
          item[WELL_PANEL_FLAGS.HAS_INJECTION.key])
      ) {
        statisticsArray?.push({
          [UWI.label]: item.uwi,
          [WELL_INFO_STATISTICS_ATTRIBUTES.FIRST_MONTH.label]: fieldValidation(
            item.firstMonth,
            "date"
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.LAST_MONTH.label]: fieldValidation(
            item.lastMonth,
            "date"
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.label]: fieldValidation(
            item.cumOil,
            specialFloat
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.label]: fieldValidation(
            item.maxOil,
            specialFloat
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.label]:
            fieldValidation(item.maxOilPlus2, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.label]:
            fieldValidation(item.wellForecastOilUltimate, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.label]: fieldValidation(
            item.cumGas,
            specialFloat
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.label]: fieldValidation(
            item.maxGas,
            specialFloat
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.label]:
            fieldValidation(item.maxGasPlus2, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.label]:
            fieldValidation(item.wellForecastGasUltimate, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.label]: fieldValidation(
            item.cumWater,
            specialFloat
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.label]: fieldValidation(
            item.maxWater,
            specialFloat
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.label]:
            fieldValidation(item.maxWaterPlus2, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE.label]:
            fieldValidation(item.wellForecastWaterUltimate, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.label]:
            fieldValidation(item.cumInjectionLiquid, specialFloat),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.label]:
            fieldValidation(item.cumInjectionGas, specialFloat),
        });
      }

      if (
        exportList.includes(
          WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION.key
        ) &&
        item[WELL_PANEL_FLAGS.HAS_FORECAST.key]
      ) {
        declineCurveParametersArray?.push({
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.label]:
            stringValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.key
              ] as string
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.B_FACTOR
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.label]: fieldValidation(
            item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.key],
            "date"
          ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.label]:
            stringValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.key
              ] as string
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.B_FACTOR
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.label]: fieldValidation(
            item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.key],
            "date"
          ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.label]:
            stringValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.key
              ] as string
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.RATES
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.label]:
            floatValidation(
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.key
              ] as number,
              DECLINE_CURVE_DECIMAL_FORMAT.B_FACTOR
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.label]:
            fieldValidation(
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.key],
              "date"
            ),
        });
      }

      if (
        exportList.includes(WELL_PANEL_SECTION.COMPLETION_SECTION.key) &&
        item[WELL_PANEL_FLAGS.HAS_COMPLETION.key]
      ) {
        completionArray?.push({
          [UWI.label]: item.uwi,
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.COMPLETION_DATE.label]:
            fieldValidation(item.completionDate, "date"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.LATERAL_LENGTH.label]:
            fieldValidation(item.lateralLength, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.DRILLING_DAYS.label]:
            fieldValidation(item.drillingDays, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.NUMBER_OF_STAGES.label]:
            fieldValidation(item.numberOfStages, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_PRESSURE.label]:
            fieldValidation(item.maxTreatmentRate, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_RATE.label]:
            fieldValidation(item.maxTreatmentPressure, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.PROPPANT_AMOUNT.label]:
            fieldValidation(item.proppantAmount, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.FLUID_AMOUNT.label]:
            fieldValidation(item.fractureFluidAmount, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.ACID_AMOUNT.label]:
            fieldValidation(item.acidAmount, "num"),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.TREATMENT_REMARKS.label]:
            fieldValidation(item.treatmentRemarks),
        });
      }
    });

    return {
      header: headerArray,
      statistics: statisticsArray,
      declineCurveParam: declineCurveParametersArray,
      completion: completionArray,
    };
  };

  const sortPerfData = (data: Perforation[]) => {
    if (!data.length) return [];
    const perfArray: any[] = [];
    const sortedData = data.flatMap((item) => item);

    sortedData.forEach((item) => {
      perfArray.push({
        [UWI.label]: item.uwi,
        "Perf Date": convertStringToDate(item.perfDate),
        "Perf Top": formattedNumber(item.perfTop),
        "Perf Bottom": formattedNumber(item.perfBottom),
      });
    });

    return perfArray;
  };

  const sortTestingData = (data: Test[]) => {
    if (!data.length) return [];
    const testArray: any[] = [];
    const sortedData = data.flatMap((item) => item);

    sortedData?.forEach((item: Test) => {
      testArray.push({
        [UWI.label]: item.uwi,
        "Test Date": convertStringToDate(item.testDate),
        "Test Type": item.testType,
        Oil: formattedNumber(item.oil),
        Gas: formattedNumber(item.gas),
        Water: formattedNumber(item.water),
        "Test Duration": formattedNumber(item.testDuration),
      });
    });

    return testArray;
  };

  const sortPressureData = (data: PressureDataWellPanelExport[][]) => {
    if (!data.length) return [];
    const pressureDataArray: { [key: string]: string }[] = [];
    const sortedData = data.flatMap(
      (item: PressureDataWellPanelExport[]) => item
    );
    sortedData?.forEach((item: PressureDataWellPanelExport) => {
      pressureDataArray.push({
        [UWI.label]: item.uwi,
        [TEST_TYPE.label]: item.TestType,
        [DST_NO.label]: item.DSTNo,
        [REPORTED_FORMATION.label]: item.Formation,
        [TGS_LANDING_ZONE.label]: item.TgsLandingZone,
        [DST_TEMP.label]: item.Temperature,
        ["Top  (of an interval)"]: item.Top,
        ["Bottom (of an interval)"]: item.Bottom,
        ["1st Flow"]: item.FirstFlow,
        ["1st Flow shut-in"]: item.FirstFlowShutIn,
        ["2nd Flow"]: item.SecondFlow,
        ["2nd Flow shut-in"]: item.SecondFlowShutIn,
        ["Recovery Remarks"]: item.RecoveryRemarks,
      });
    });

    return pressureDataArray;
  };

  const sortCasingData = (data: Casings[]) => {
    if (!data.length) return [];
    const casingArray: any[] = [];
    const sortedData = data.flatMap((item: any) => item);
    sortedData?.forEach((item: Casings) => {
      casingArray.push({
        [UWI.label]: item.uwi,
        "Casing Type": item.casingType,
        "Casing Purpose": item.casingPurpose,
        "Hole Size": formattedNumber(item.holeSize),
        "Casing Size": formattedNumber(item.casingSize),
        "Casing Weight": formattedNumber(item.casingWeight),
        "Top Depth": formattedNumber(item.topDepth),
        "Bottom Depth": formattedNumber(item.bottomDepth),
        "Casing Grade": item.casingGrade,
      });
    });

    return casingArray;
  };

  const sortPermitsData = (data: Permits[]) => {
    if (!data.length) return [];
    const permitsArray: any[] = [];
    const sortedData = data.flatMap((item: any) => item);

    sortedData.forEach((item: Permits) => {
      permitsArray.push({
        [UWI.label]: item.uwi,
        "Permit Number": item.permitNumber,
        [PERMIT_APPROVAL_DATE.label]: convertStringToDate(
          item.permitApprovalDate
        ),
        "Permit Posted Date": convertStringToDate(item.permitPostedDate),
        "Permit Proposed Depth": formattedNumber(item.proposedTotalDepth),
        "Permit Formation": item.formation,
        "Permit Type": item.wellPurpose,
        "Permit Type Code": item.permitTypeCode,
        Slant: item.wellOrientation,
        "Well Name": item.wellName,
        "Well Number": item.wellNumber,
        "Permit Surface Latitude": item.surfaceLat,
        "Permit Surface Longitude": item.surfaceLong,
        "Permit Bottom Latitude": item.bottomHoleLat,
        "Permit Bottom Longitude": item.bottomHoleLong,
      });
    });

    return permitsArray;
  };

  const sortFormationsData = (data: Formations[]) => {
    if (!data.length) return [];
    const formationsArray: any[] = [];
    const sortedData = data.flatMap((item: any) => item);

    sortedData.forEach((item: Formations) => {
      formationsArray.push({
        [UWI.label]: item.uwi,
        "Formation Name": item.formationName,
        "Top Depth": formattedNumber(item.topDepth),
      });
    });
    return formationsArray;
  };

  return {
    sortWellPanelContent,
    sortPerfData,
    sortCasingData,
    sortTestingData,
    sortPressureData,
    sortPermitsData,
    sortFormationsData,
  };
};

export default useSortWellPanelData;
