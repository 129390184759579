const COMMON_MARKER_CONFIG = {
  size: 9,
  line: {
    width: 2,
  },
};

//see marker API for additional properties
const targetMarkerStyle = {
  color: "#FFF",
  size: 9,
  symbol: "star",
};

const parentsMarkerStyle = {
  color: "blue",
  ...COMMON_MARKER_CONFIG,
  line: {
    width: COMMON_MARKER_CONFIG.width,
    color: "blue",
  },
};

const childMarkerStyle = {
  color: "red",
  ...COMMON_MARKER_CONFIG,
  line: {
    width: COMMON_MARKER_CONFIG.width,
    color: "red",
  },
};

const siblingMarkerStyle = {
  color: "#FFF",
  ...COMMON_MARKER_CONFIG,
  line: {
    width: COMMON_MARKER_CONFIG.width,
    color: "#FFF",
  },
};

const formationStyle = {
  color: "#444",
  line: {
    width: 1,
  },
};

export {
  targetMarkerStyle,
  parentsMarkerStyle,
  childMarkerStyle,
  siblingMarkerStyle,
  formationStyle,
};
