import {
  ChartDataFields,
  ChartType,
} from "../../types/charts/chartType/chartType";

import * as attribute from "../attributes";

//Plotly Tick date formatting
export const mmmYYYYTickFormat = "%b %Y";
export const YYYYTickFormat = "%Y";

//CHART TYPES
export const CHART_TYPES = {
  PRODUCTION_PLOT: "Production Plot",
  TYPE_CURVE: "Type Curve",
  BAR: "Bar",
  SCATTER_PLOT: "Scatter Plot",
} as const;

export const CHARTS_FIELDS_TO_CHART_TYPE_MAPPER: { [key: string]: string } = {
  [CHART_TYPES.PRODUCTION_PLOT]: "PRODUCTION_PLOT",
  [CHART_TYPES.TYPE_CURVE]: "TYPE_CURVE",
  [CHART_TYPES.SCATTER_PLOT]: "scatter",
};

export const ANALYSIS_PANE_CHART_LIST: ChartType[] = [
  CHART_TYPES.PRODUCTION_PLOT,
  CHART_TYPES.TYPE_CURVE,
  CHART_TYPES.SCATTER_PLOT,
];

export const CHART_SCALING_TYPE = {
  LINEAR: "linear",
  LOGARITHMIC: "logarithmic",
} as const;

export const CHART_AXIS = {
  LEFT: "left",
  RIGHT: "right",
} as const;

export const CHART_ACCUMULATION_METHOD = {
  MONTHLY: "MONTHLY",
  CUMULATIVE: "CUMULATIVE",
} as const;

export const CHART_DATA_SOURCE = {
  PRODUCTION: "PRODUCTION",
  PRODUCTION_FORECAST: "PRODUCTION_FORECAST",
  PRODUCTION_UNION_FORECAST: "PRODUCTION_UNION_FORECAST",
  INJECTION: "INJECTION",
  VENTFLARE: "VENTFLARE",
} as const;

export const CHART_DATA_STATE = {
  POST_SEARCH: "post_search",
  PRE_SEARCH: "pre_search",
  INTER_SEARCH: "inter_search",
} as const;

export const CHART_STATUS = {
  EXPANDED: "expanded",
  COLLAPSED: "collapsed",
} as const;

export const CHART_GROUP_BY_TYPE = {
  INDIVIDUAL_WELLS: "NONE",
  DEFAULT: "Default",
} as const;

export const AGGREGATION_TYPE: { [key: string]: string } = {
  [CHART_TYPES.PRODUCTION_PLOT]: "sum",
  [CHART_TYPES.TYPE_CURVE]: "average",
};

export const MAX_INDIVIDUAL_WELLS_COUNT = 10;
export const INDIVIDUAL_WELLS_BATCH_LABEL = " (First 10)";

export const CHART_ALL_OTHERS_KEY = "all others";

export const PRODUCTION_SOURCE_DATA_FIELDS: ChartDataFields[] = [
  {
    id: 1,
    name: "Oil",
    displayName: "Oil (Monthly)",
    dailyDisplayName: "Oil (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#287D3C",
    hasForecast: true,
    hasDaily: true,
    hasLateralLength: true,
    responseData: {
      name: "oil",
      origin: "mdata",
    },
    unit: "bbl",
  },
  {
    id: 2,
    name: "Oil",
    displayName: "Oil (Cumulative)",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#287D3C",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: true,
    responseData: {
      name: "oil",
      origin: "cdata",
    },
    unit: "bbl",
  },
  {
    id: 3,
    name: "Gas",
    displayName: "Gas (Monthly)",
    dailyDisplayName: "Gas (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#E62828",
    hasForecast: true,
    hasDaily: true,
    hasLateralLength: true,
    responseData: {
      name: "gas",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 4,
    name: "Gas",
    displayName: "Gas (Cumulative)",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#E62828",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: true,
    responseData: {
      name: "gas",
      origin: "cdata",
    },
    unit: "mcf",
  },
  {
    id: 26,
    name: "Boe",
    displayName: "BOE (Monthly)",
    dailyDisplayName: "BOE (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#84FFFF",
    hasForecast: true,
    hasDaily: true,
    hasLateralLength: true,
    responseData: {
      name: "bOE",
      origin: "mdata",
    },
    unit: "boe",
  },
  {
    id: 27,
    name: "Boe",
    displayName: "BOE (Cumulative)",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#84FFFF",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: true,
    responseData: {
      name: "bOE",
      origin: "cdata",
    },
    unit: "boe",
  },
  {
    id: 5,
    name: "Water",
    displayName: "Water (Monthly)",
    dailyDisplayName: "Water (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#2962FF",
    hasForecast: true,
    hasDaily: true,
    hasLateralLength: true,
    responseData: {
      name: "wtr",
      origin: "mdata",
    },
    unit: "bbl",
  },
  {
    id: 6,
    name: "Water",
    displayName: "Water (Cumulative)",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#2962FF",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: true,
    responseData: {
      name: "wtr",
      origin: "cdata",
    },
    unit: "bbl",
  },
  {
    id: 7,
    name: "DaysOnProduction",
    displayName: "Days On Production",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#00AAAB",
    hasForecast: false,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "dOP",
      origin: "mdata",
    },
    unit: "days",
  },
  {
    id: 8,
    name: "WaterOilRatio",
    displayName: "Water-Oil Ratio",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#5500AB",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "wtrOR",
      origin: "mdata",
    },
    unit: "",
  },
  {
    id: 9,
    name: "WaterCut",
    displayName: "Water Cut",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#AA7878",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "wtrC",
      origin: "mdata",
    },
    unit: "",
  },
  {
    id: 10,
    name: "GasOilRatio",
    displayName: "Gas-Oil Ratio",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.PRODUCTION,
    color: "#8F44EE",
    hasForecast: true,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "gasOR",
      origin: "mdata",
    },
    unit: "scf/bbl",
  },
];

export const INJECTION_SOURCE_DATA_FIELDS: ChartDataFields[] = [
  {
    id: 11,
    name: "TotalLiquid",
    displayName: "Total Injected Liquid (Monthly)",
    dailyDisplayName: "Total Injected Liquid (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#C06B15",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "tLqd",
      origin: "mdata",
    },
    unit: "bbl",
  },
  {
    id: 12,
    name: "TotalLiquid",
    displayName: "Total Injected Liquid (Cumulative)",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#C06B15",
    hasForecast: false,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "tLqd",
      origin: "cdata",
    },
    unit: "bbl",
  },
  {
    id: 13,
    name: "TotalGas",
    displayName: "Total Injected Gas (Monthly)",
    dailyDisplayName: "Total Injected Gas (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#85BA85",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "tGas",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 14,
    name: "TotalGas",
    displayName: "Total Injected Gas (Cumulative)",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#85BA85",
    hasForecast: false,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "tGas",
      origin: "cdata",
    },
    unit: "mcf",
  },
  {
    id: 15,
    name: "MaxInjPressure",
    displayName: "Max Injection Pressure",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#6D7085",
    hasForecast: false,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "mIP",
      origin: "mdata",
    },
    unit: "psi",
  },
  {
    id: 16,
    name: "AvInjPressure",
    displayName: "Average Injection Pressure",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#FECB78",
    hasForecast: false,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "aIP",
      origin: "mdata",
    },
    unit: "psi",
  },
  {
    id: 17,
    name: "DaysOnInjection",
    displayName: "Days On Injection",
    dailyDisplayName: "",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.INJECTION,
    color: "#747500",
    hasForecast: false,
    hasDaily: false,
    hasLateralLength: false,
    responseData: {
      name: "dOI",
      origin: "mdata",
    },
    unit: "days",
  },
];

export const VENTFLARE_SOURCE_DATA_FIELDS: ChartDataFields[] = [
  {
    id: 18,
    name: "TotalDisposed",
    displayName: "Total Disposed Volume",
    dailyDisplayName: "Total Disposed Volume (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#CE93D8",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "tD",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 19,
    name: "Vent",
    displayName: "Vent",
    dailyDisplayName: "Vent (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#020075",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "vnt",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 20,
    name: "Flare",
    displayName: "Flare",
    dailyDisplayName: "Flare (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#DBFF00",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "flr",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 21,
    name: "VentFlare",
    displayName: "Vent or Flare",
    dailyDisplayName: "Vent or Flare (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#003660",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "vntFlr",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 22,
    name: "TotalUsed",
    displayName: "Total Used Volume",
    dailyDisplayName: "Total Used Volume (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#FA0272",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "tUsed",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 23,
    name: "Used",
    displayName: "Used",
    dailyDisplayName: "Used (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#612A00",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "used",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 24,
    name: "Inferred",
    displayName: "Inferred",
    dailyDisplayName: "Inferred (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#F17B93",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "inf",
      origin: "mdata",
    },
    unit: "mcf",
  },
  {
    id: 25,
    name: "Other",
    displayName: "Other",
    dailyDisplayName: "Other (Daily)",
    accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
    dataSource: CHART_DATA_SOURCE.VENTFLARE,
    color: "#F9A01B",
    hasForecast: false,
    hasDaily: true,
    hasLateralLength: false,
    responseData: {
      name: "othr",
      origin: "mdata",
    },
    unit: "",
  },
];

export const PRODUCTION_DATA_FIELDS_LIST: ChartDataFields[] = [
  ...PRODUCTION_SOURCE_DATA_FIELDS,
  ...INJECTION_SOURCE_DATA_FIELDS,
  ...VENTFLARE_SOURCE_DATA_FIELDS,
];

export const PRODUCTION_GROUP_BY_ATTRIBUTES = [
  { name: "County", displayName: "County" },
  { name: "SurfaceUWI", displayName: "Surface UWI" },
  { name: "FieldName", displayName: "Field" },
  { name: "BasinName", displayName: "Basin" },
  { name: "DisplayFormation", displayName: "Producing Formation" },
  {
    name: "InterpretedProducingFormation",
    displayName: attribute.INTERPRETED_PRODUCING_FORMATION.label,
  },
  { name: "OperatorName", displayName: "Current Operator" },
  {
    name: attribute.ULTIMATE_OWNER.key,
    displayName: attribute.ULTIMATE_OWNER.label,
  },
  { name: "OriginalOperator", displayName: "Original Operator" },
  { name: attribute.LEASE_NAME.key, displayName: attribute.LEASE_NAME.label },
  { name: "LeaseID", displayName: attribute.LEASE_NAME_AND_ID.label },
  { name: "WellStatus", displayName: "Well Status" },
  { name: "WellType", displayName: "Well Type" },
  { name: "Slant", displayName: "Slant" },
  { name: "CalculatedMajorPhase", displayName: "Major Phase" },
  { name: "LastInjectionType", displayName: "Injection Type" },
  { name: "LastInjectionFormation", displayName: "Injection Formation" },
  { name: "DispositionType", displayName: "Gas Disposition Type" },
];

export const CHART_GROUP_BY_ATTRIBUTES = {
  [CHART_TYPES.PRODUCTION_PLOT]: PRODUCTION_GROUP_BY_ATTRIBUTES,
  [CHART_TYPES.TYPE_CURVE]: PRODUCTION_GROUP_BY_ATTRIBUTES,
};

export const DEFAULT_ANALYSIS_CHARTS_VALUE = {
  [CHART_TYPES.PRODUCTION_PLOT]: {
    SHOWFORECAST: true,
    GROUP_BY: CHART_GROUP_BY_TYPE.DEFAULT,
    DATA_FIELDS_TO_DISPLAY: [
      ...PRODUCTION_DATA_FIELDS_LIST.filter(
        (dataField) =>
          dataField.id === 1 || dataField.id === 3 || dataField.id === 5
      ),
    ],
  },
  [CHART_TYPES.TYPE_CURVE]: {
    SHOWFORECAST: false,
    GROUP_BY: CHART_GROUP_BY_TYPE.DEFAULT,
    DATA_FIELDS_TO_DISPLAY: [
      ...PRODUCTION_DATA_FIELDS_LIST.filter(
        (dataField) =>
          dataField.id === 1 || dataField.id === 3 || dataField.id === 5
      ),
    ],
  },
  [CHART_TYPES.BAR]: {
    SHOWFORECAST: false,
    GROUP_BY: "",
    DATA_FIELDS_TO_DISPLAY: [],
  },
};

export const PERMIT_CHARTS: string[] = [CHART_TYPES.SCATTER_PLOT];

export const scatterPlotXAxisColumns = [
  "AcidAmount",
  "CompletionDate",
  "FirstMonth",
  "FractureFluidAmount",
  "FractureFluidAmountPerFt",
  "LastMonth",
  "LateralLength",
  "MeasuredDepth",
  "PerfIntervalBottom",
  "PerfIntervalTop",
  "PlugDate",
  "ProppantAmount",
  "ProppantAmountPerFt",
  "SpudDate",
  "TDDate",
  "TotalVerticalDepth",
];

export const scatterPlotFieldsUsingFt = [
  "MeasuredDepth",
  "TotalVerticalDepth",
  "PerfIntervalTop",
  "PerfIntervalBottom",
  "LateralLength",
];
export const scatterPlotFieldsUsingBbl = [
  "FractureFluidAmount",
  "BOEIP30",
  "BOEIP90",
  "MaxBOE",
  "CumBOE",
  "CumOil",
  "MaxOil",
  "MaxOilPlus2",
  "CumWater",
  "MaxWater",
  "MaxWaterPlus2",
  "CumInjectionLiquid",
  "WellForecastBOERemaining",
  "WellForecastBOEUltimate",
  "WellForecastOilRemaining",
  "WellForecastOilUltimate",
  "WellForecastWaterRemaining",
  "WellForecastWaterUltimate",
];
export const scatterPlotFieldsUsingBblPerFt = [
  "FractureFluidAmountPerFt",
  "BOEMaxPerFt",
  "EURPerFt",
  "OilEURPerFt",
  "OilCumPerFt",
  "WaterCumPerFt",
  "BOECumPerFt",
];
export const scatterPlotFieldsUsingBblPerMmcf = [
  "CumYield",
  "WellForecastYieldUltimate",
];
export const scatterPlotFieldsUsingCfPerBbl = [
  "CumGOR",
  "WellForecastGORUltimate",
];
export const scatterPlotFieldsUsingMcf = [
  "CumGas",
  "MaxGas",
  "MaxGasPlus2",
  "CumInjectionGas",
  "CumVentFlareVol",
  "WellForecastGasRemaining",
  "WellForecastGasUltimate",
];
export const scatterPlotFieldsUsingMcfPerFt = ["GasEURPerFt", "GasCumPerFt"];
