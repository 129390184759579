import { FC, useEffect, useState } from "react";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";
import { Test as TestType } from "../../../../types/panels/wellPanel/testType";

import { testColumns } from "../../../../constants/panels/wellPanel/testing";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellPanelTest from "../../../../customHooks/panels/wellPanel/useWellPanelTest";

import WellCardTable from "../../../WellCard/WellCardTable";
import WellPanelAccordion from "../common/WellPanelAccordion";

const Test: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  uwi,
  isExpanded,
  onChange,
}) => {
  const [testData, setTestData] = useState<TestType[]>([]);
  const [isLoadingTest, setIsLoadingTest] = useState(true);

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsTestLoading = usePanelsStore(
    (state) => state.updateIsTestLoading
  );

  const { getWellTest, data, success } = useWellPanelTest();

  useEffect(() => {
    if (success && data?.length) {
      setTestData(data);
      setIsLoadingTest(false);

      updateWellCardDataByTypeAndKey(
        { type: "test", test: data },
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsTestLoading(false);
    } else {
      setTestData([]);
      setIsLoadingTest(false);
    }
  }, [data, success]);

  useEffect(() => {
    if (wellData.pageNumber === activePage) {
      //check if data was already in store, else call api
      if (wellData.testingData?.length) {
        setTestData(wellData.testingData);
      } else {
        getWellTest(
          wellData.wellCardData.wellId as number,
          wellData.wellCardData.uwi as string
        );
        setIsLoadingTest(true);
        updateIsTestLoading(true);
      }
    }
  }, [wellData.pageNumber, wellData.testingData, activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.TEST_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.TEST_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isLoadingTest}
    >
      <WellCardTable
        TableData={testData}
        TableHeader={testColumns}
        tabContentTableSize={280}
        isLoading={isLoadingTest}
      />
    </WellPanelAccordion>
  );
};

export default Test;
