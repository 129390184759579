import { useEffect } from "react";

import { Divider } from "@mui/material";

import { Button } from "component-library";

import { OperationTypeKeys } from "../../../../../types/panels/searchPanel/queryBuilder";

import { ATTRIBUTE_INFO_BY_KEY } from "../../../../../constants/attributes";
import { QB_REQUIRED_ATTRIBUTES } from "../../../../../constants/panels/searchPanel/queryBuilder/store";

import usePanelsStore from "../../../../../store/panels/panelsStore";
import useQueryBuilderStore from "../../../../../store/search/queryBulder/queryBuilderStore";

import { createBoundsByKey } from "../../../../../utils/common/boundsData";

import QBAttribute from "./qbAttributes/QBAttribute";

const QueryBuilder = () => {
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const currentQBList = useQueryBuilderStore((state) => state.currentQBList);
  const currentQBInfo = useQueryBuilderStore((state) => state.currentQBInfo);
  const currentBounds = useQueryBuilderStore((state) => state.currentBounds);
  const updateCurrentBounds = useQueryBuilderStore(
    (state) => state.updateCurrentBounds
  );

  useEffect(() => {
    //triggers count api to already display count according to attributes having default/required values

    if (
      !Boolean(currentBounds.length) &&
      Boolean(Object.keys(currentQBInfo).length) &&
      Object.values(currentQBInfo)
        .map((info) => info.isUpdated)
        .every((val) => !val)
    ) {
      const newBounds = [...currentBounds];

      QB_REQUIRED_ATTRIBUTES.forEach((attr) => {
        if (
          currentQBInfo[attr] &&
          currentQBInfo[attr].values.length &&
          currentQBInfo[attr].operationType !== ""
        ) {
          const newAttributeBound = createBoundsByKey(
            ATTRIBUTE_INFO_BY_KEY[attr],
            currentQBInfo[attr].values,
            currentQBInfo[attr].operationType as OperationTypeKeys
          );
          newBounds.push(newAttributeBound);
        }
      });

      updateCurrentBounds(newBounds);
    }
  }, [currentBounds, currentQBInfo]);

  return (
    <>
      {currentQBList.map((attribute) => (
        <QBAttribute
          key={attribute.key}
          attribute={attribute}
          attributeQBInfo={currentQBInfo[attribute.key]}
        />
      ))}
      <div className="padded">
        <Divider light />
        <Button
          iconLeft={"edit"}
          text={<p className="modify-add-text">MODIFY / ADD MORE</p>}
          state={isOpenQBEditorPanel ? "disabled" : "enabled"}
          onClick={() => toggleIsOpenQBEditorPanel()}
        />
      </div>
    </>
  );
};

export default QueryBuilder;
