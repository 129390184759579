import { FC, useEffect, useState } from "react";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";
import { Perforation as PerforationType } from "../../../../types/panels/wellPanel/perforationType";

import { perforationsColumns } from "../../../../constants/panels/wellPanel/perforation";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellPanelPerforation from "../../../../customHooks/panels/wellPanel/useWellPanelPerforation";

import WellCardTable from "../../../WellCard/WellCardTable";
import WellPanelAccordion from "../common/WellPanelAccordion";

const Perforation: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  isExpanded,
  layer,
  onChange,
}) => {
  const [perforationData, setPerforationData] = useState<PerforationType[]>([]);
  const [isLoadingPerforation, setIsLoadingPerforation] = useState(true);

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsPerforationsLoading = usePanelsStore(
    (state) => state.updateIsPerforationsLoading
  );

  const { getWellPerforations, data, success } = useWellPanelPerforation();

  useEffect(() => {
    if (success && data?.length) {
      setPerforationData(data);
      setIsLoadingPerforation(false);
      updateWellCardDataByTypeAndKey(
        { type: "perforation", perforation: data },
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsPerforationsLoading(false);
    } else {
      setPerforationData([]);
      setIsLoadingPerforation(false);
    }
  }, [data, success]);

  useEffect(() => {
    if (wellData.pageNumber === activePage) {
      //check if data was already in store, else call api
      if (wellData.perforationData?.length) {
        setPerforationData(wellData.perforationData);
      } else {
        getWellPerforations(
          wellData.wellCardData.wellId as number,
          wellData.wellCardData.uwi as string
        );
        setIsLoadingPerforation(true);
        updateIsPerforationsLoading(true);
      }
    }
  }, [wellData.pageNumber, wellData.perforationData, activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.PERFORATION_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.PERFORATION_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isLoadingPerforation}
    >
      {perforationData.length && (
        <WellCardTable
          TableData={perforationData}
          TableHeader={perforationsColumns}
          tabContentTableSize={285}
          isLoading={isLoadingPerforation}
        />
      )}
    </WellPanelAccordion>
  );
};

export default Perforation;
