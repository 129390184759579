import {
  WellData,
  WellPanelSectionType,
} from "../../../../types/panels/wellPanel/wellPanel";

import { noData } from "../../../../constants/constants";
import {
  WELL_INFO_HEADER_ATTRIBUTES,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import {
  booleanValidation,
  numberValidation,
  specialCharValidation,
  specialFloatValidation,
  stringValidation,
} from "../../../../utils/panel/wellPanel/fieldValidation";

import { getNumberWithComma } from "../../../../utils";
import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../common/WellPanelAccordion";

interface HeaderProps {
  data: WellData;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}
const Header = ({ data, isExpanded, onChange }: HeaderProps) => {
  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.HEADER_SECTION);
  };
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.HEADER_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={false}
    >
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNTY.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.COUNTY.key] as string
            )}
          />
        </div>

        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key] as string
            )}
          />
        </div>

        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.SLANT.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.SLANT.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.TGS_OPERATOR.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.TGS_OPERATOR.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.key] as string
            )}
          />
        </div>

        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.label
            }
            value={stringValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.label}
            value={booleanValidation(
              Boolean(data[WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.key])
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.label}
            value={stringValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.label}
            value={booleanValidation(
              Boolean(data[WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.key])
            )}
          />
        </div>
      </div>
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.label}
            value={numberValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.key] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.label}
            value={
              data[WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.key]
                ? getNumberWithComma(
                    Math.round(
                      data[
                        WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.key
                      ] as number
                    )
                  )
                : noData
            }
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.label}
            value={numberValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.key] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.label}
            value={numberValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.key] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.label}
            value={numberValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.ELEVATION.label}
            value={specialCharValidation(
              data[WELL_INFO_HEADER_ATTRIBUTES.ELEVATION.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
                .label
            }
            value={specialFloatValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
                  .key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.label
            }
            value={specialFloatValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.label}
            value={specialFloatValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.key
              ] as number
            )}
          />
        </div>
      </div>
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.label}
            value={numberValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.label}
            value={numberValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.label}
            value={numberValidation(
              data[
                WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.key
              ] as number
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default Header;
