import { useEffect } from "react";

import { GunBarrelLayout } from "../../utils/charts/layouts/GunBarrelLayout";

import { initGunBarrel, updateGunBarrelStyles } from "./useGunBarrelUtils";

export const useLoadGunBarrel = (
  apiContent: any,
  setState: any,
  originalData: any,
  isChartExport: boolean
) => {
  useEffect(() => {
    if (Object.keys(apiContent).length > 0 && Object.keys(apiContent).length) {
      const gunBarrelData = updateGunBarrelStyles(initGunBarrel(apiContent));

      //For Gun Barrel Export Props
      const gunBarrelLayoutProps = isChartExport
        ? [700, 450, 16]
        : [400, 280, 12];

      const gunBarreLayout = GunBarrelLayout(
        gunBarrelLayoutProps[0],
        gunBarrelLayoutProps[1],
        gunBarrelLayoutProps[2],
        gunBarrelData,
        isChartExport
      );

      setState((state: any) => ({
        ...state,
        ...{
          data: [...gunBarrelData.formationTraces, ...gunBarrelData.wellTraces],
          layout: gunBarreLayout,
          revision: state.revision + 1,
        },
      }));
      originalData.data.current = gunBarrelData;
      originalData.layout.current = gunBarreLayout;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiContent]);
};
