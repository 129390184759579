import { useCallback, useState } from "react";

import {
  ParsedProductionDataInfo,
  ProductionDataBody,
} from "../../types/charts/chartHooks/productionData";
import {
  ChartDataFields,
  IChartData,
} from "../../types/charts/chartType/chartType";
import { GroupedProductionResponseData } from "../../types/charts/chartType/productionChartData";

import config from "../../configs/appSettings";

import {
  CHARTS_FIELDS_TO_CHART_TYPE_MAPPER,
  CHART_DATA_SOURCE,
  CHART_GROUP_BY_TYPE,
} from "../../constants/charts/charts";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { extractGData, groupDataByFields } from "../../utils/charts/aggregator";

import { callServiceAPI } from "../../action/callServiceAPI";
import { useGridSelectedData } from "../grid/useGridSelectedData";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

const productionChartsURL = `${config.endpoints.wellService}api/wells/productions`;

export const useProductionData = () => {
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ParsedProductionDataInfo | null>(null);

  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { buildChartPostRequest } = useChartSearchRequest();
  const { isSelectedAllGrid } = useGridSelectedData();
  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const parseProductionData = useCallback(
    (data: any, chartData: IChartData) => {
      const groupByFieldsToUse =
        chartData?.groupBy === CHART_GROUP_BY_TYPE.DEFAULT
          ? ["production"]
          : ["name"];

      const extractedGData = extractGData(
        data.productions.filter(
          (p: any) =>
            p.source === CHART_DATA_SOURCE.PRODUCTION ||
            p.source === CHART_DATA_SOURCE.INJECTION ||
            p.source === CHART_DATA_SOURCE.VENTFLARE
        )
      );

      const groupedByChartData = groupDataByFields(
        extractedGData,
        groupByFieldsToUse
      );
      let groupedByChartDataForecast = {};
      if (chartData?.chartShowForecast) {
        const extractedForecastGData = extractGData(
          data.productions.filter(
            (p: any) => p.source === CHART_DATA_SOURCE.PRODUCTION_FORECAST
          )
        );

        groupedByChartDataForecast = groupDataByFields(
          extractedForecastGData,
          groupByFieldsToUse
        );
      }

      setData({
        groupedByChartData: groupedByChartData as GroupedProductionResponseData,
        groupedByChartDataForecast:
          groupedByChartDataForecast as GroupedProductionResponseData,
      });
    },
    []
  );

  const getProductionData = useCallback(
    async (chartData: IChartData) => {
      setIsLoading(true);
      setData(null);

      try {
        const forecastDatasets: any[] = []; //list for forecast data
        const dataSets: any[] = [];
        chartData.chartDisplayedDataFields.forEach(
          (dataField: ChartDataFields) => {
            const dataSetInfo = {
              accumulationMethod: dataField.accumMethod,
              data: dataField.name,
              source:
                dataField.dataSource === CHART_DATA_SOURCE.PRODUCTION &&
                chartData.chartShowForecast
                  ? CHART_DATA_SOURCE.PRODUCTION_UNION_FORECAST
                  : dataField.dataSource,
            };

            dataSets.push(dataSetInfo);
          }
        );
        const body: ProductionDataBody = {
          chartType: CHARTS_FIELDS_TO_CHART_TYPE_MAPPER[chartData.chartType],
          groupBy:
            chartData.groupBy === CHART_GROUP_BY_TYPE.DEFAULT
              ? "ALL"
              : chartData.groupBy,
          dataSet: dataSets,
          flags: {
            daily: chartData.chartShowDailyValues ?? false,
            normalizeByLateralLength:
              chartData.chartShowByNormalizedLateralLength ?? false,
          },
        };

        if (CHARTS_FIELDS_TO_CHART_TYPE_MAPPER[chartData.chartType]) {
          body.dataSet = [...body.dataSet, ...forecastDatasets];
        }

        body.searchRequest = buildChartPostRequest(chartData.chartType);

        const response: any = await callServiceAPI(
          productionChartsURL,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          isFromUploadedUWIFile
        );

        parseProductionData(response.data, chartData);
      } catch (e) {
        console.log("getProductionData error", e);
      }

      setIsLoading(false);
    },
    [
      isSelectedAllGrid,
      selectedWellIdsKeys,
      isFromUploadedUWIFile,
      uploadedUWIFile,
      getUWIFileIdWithRetry,
      parseProductionData,
    ]
  );

  return { getProductionData, isLoading, data };
};

export default useProductionData;
