import { useState } from "react";

import axios from "axios";

import { GunBarrelInfo } from "../../../types/panels/wellPanel/gunBarrelType";

import config from "../../../configs/appSettings";

import { GUN_BARREL_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

const useWellPanelGunBarrel = () => {
  const [gunBarrel, setGunBarrel] = useState<GunBarrelInfo>();
  const [success, setSuccess] = useState(true);
  const getWellGunBarrel = async (wellId: number) => {
    setSuccess(false);
    try {
      const response = await axios.get(
        `${config.endpoints.wellService}api/wells/${wellId}/diagram/${GUN_BARREL_SECTION.urlSlug}`
      );
      setGunBarrel(response.data);
      setSuccess(true);
    } catch (error) {
      console.debug("No WellCard Gun Barrel Data", error);
      return {
        status: "error",
        msg: error,
      };
    }
  };

  return { gunBarrel, success, getWellGunBarrel };
};
export default useWellPanelGunBarrel;
