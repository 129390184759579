import BaseLayer from "ol/layer/Base";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

import { CreateLayerFromLayerDefinition } from "../../../types/map/layers/dvt";

import config from "../../../configs/appSettings";

import { getDVTLayer } from "./dvtUtils";
import { getMVTLayer } from "./mvtUtils";
import {
  returnAsBaseLayer,
  returnAsDVT,
  returnAsMVT,
  returnAsWMS,
  returnAsWMTS,
} from "./tileUtils";
import { getWMSLayer } from "./wmsUtils";
import { getWMTSLayer } from "./wmtsUtils";

const generateTGSBaseLayer = () => {
  const tileLayer = returnAsBaseLayer(
    new TileLayer({
      source: new XYZ({
        url: config.endpoints.darkGrayCanvasBaseMap,
      }),
      visible: true,
      zIndex: 0,
      properties: {
        isBaseLayer: true,
        title: "BaseLayer",
        isBaseMap: true,
      },
    })
  );

  return new LayerGroup({
    layers: [tileLayer],
    properties: {
      isBaseMap: true,
    },
  });
};

const createLayerFromLayerDefinition = async ({
  definitionKey,
  token,
  currentDeckGl,
  layerStyles,
  layerLegendColors,
  zoom,
  visible,
  hasBubbleMap,
  DVTQuery,

  //WellCard Props
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
  selectedCardPermitIDs,
  //WellCard props End

  selectedMapParentWellIDs,
  selectedBottomWellboreIDs,
  selectedPermitIds,
  compressedWellSpotData,
  compressedWellSpotInfo,
  compressedBubbleMapData,
  compressedBubbleMapInfo,

  getDVTQuery,
  isBottomSelected,
  initialGridSearchMade,
  cartoIsEnabledByZoomLevel,
  updateDVTProcessing,
  updateViewportChanged,
  isHighlightSelectedSpots,
  dataGridSelector,
  cartoClickProps,
}: CreateLayerFromLayerDefinition) => {
  const layerDefinition = config.layerDefinitions[definitionKey];

  if (!layerDefinition || !token) {
    return new LayerGroup();
  }

  if (layerDefinition.layers) {
    const layers = layerDefinition.layers;

    const olObjectLayers = layers.map((layer) => {
      switch (layer.fetchType) {
        case "WMTS":
          return getWMTSLayer(
            returnAsWMTS(layer),
            layers,
            definitionKey,
            token
          );
        case "DVT":
          return getDVTLayer({
            layer: returnAsDVT(layer),
            definitionKey,
            initialGridSearchMade,
            hasBubbleMap,
            cartoIsEnabledByZoomLevel,
            currentDeckGl,
            layerStyles,
            layerLegendColors,

            //WellCard Props
            selectedWellCardPWIDs,
            selectedWellCardBWIDs,
            selectedCardPermitIDs,
            //WellCard props End

            isHighlightSelectedSpots,
            selectedMapParentWellIDs,
            selectedBottomWellboreIDs,
            selectedPermitIds,
            compressedWellSpotData,
            compressedWellSpotInfo,
            compressedBubbleMapData,
            compressedBubbleMapInfo,

            DVTQuery,
            isBottomSelected,
            zoom,
            updateDVTProcessing,
            updateViewportChanged,
            layers,
            visible,
            token,
            getDVTQuery,
            dataGridSelector,
            cartoClickProps,
          });
        case "WMS":
          return getWMSLayer(returnAsWMS(layer), definitionKey, token);
        case "MVT":
          return getMVTLayer(returnAsMVT(layer), definitionKey, token);
        default:
      }
    });

    const layerGroups = (await Promise.all(olObjectLayers)) as BaseLayer[];

    return new LayerGroup({
      layers: layerGroups.filter((olLayer) => !!olLayer),
      properties: {
        title: definitionKey,
      },
      zIndex: layerDefinition.layerGroupZIndex,
    });
  }
};

const getLayerDefinitionsKeys = () => {
  return Object.keys(config.layerDefinitions);
};

const getLayerGroupNamesByFetchType = (keys: string[], fetchType: string) =>
  keys.filter((layerDefinitionKey) => {
    const layerDefinition = config.layerDefinitions[layerDefinitionKey];
    let hasLayer = false;
    for (let i = 0; i < layerDefinition.layers.length; i++) {
      const layer = layerDefinition.layers[i];
      if (layer.fetchType === fetchType) {
        hasLayer = true;
        break;
      }
    }
    return hasLayer;
  });

export {
  createLayerFromLayerDefinition,
  generateTGSBaseLayer,
  getLayerDefinitionsKeys,
  getLayerGroupNamesByFetchType,
};
