import { FC, useEffect, useState } from "react";

import { Casings as CasingType } from "../../../../types/panels/wellPanel/casingsType";
import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";

import { casingColumns } from "../../../../constants/panels/wellPanel/casing";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellPanelCasings from "../../../../customHooks/panels/wellPanel/useWellPanelCasings";

import WellCardTable from "../../../WellCard/WellCardTable";
import WellPanelAccordion from "../common/WellPanelAccordion";

const Casing: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
}) => {
  const [casingData, setCasingData] = useState<CasingType[]>([]);
  const [isLoadingCasing, setIsLoadingCasing] = useState(true);

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsCasingLoading = usePanelsStore(
    (state) => state.updateIsCasingLoading
  );

  const { getWellCasings, data, success } = useWellPanelCasings();

  useEffect(() => {
    if (success && data?.length) {
      setCasingData(data);
      setIsLoadingCasing(false);
      updateWellCardDataByTypeAndKey(
        { type: "casing", casing: data },
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsCasingLoading(false);
    } else {
      setCasingData([]);
      setIsLoadingCasing(false);
    }
  }, [data, success]);

  useEffect(() => {
    if (wellData.pageNumber === activePage) {
      //check if data was already in store, else call api
      if (wellData.casingData?.length) {
        setCasingData(wellData.casingData);
      } else {
        getWellCasings(
          wellData.wellCardData.wellId as number,
          wellData.wellCardData.uwi as string
        );
        setIsLoadingCasing(true);
        updateIsCasingLoading(true);
      }
    }
  }, [wellData.pageNumber, wellData.casingData, activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.CASING_SECTION);
  };
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.CASING_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isLoadingCasing}
    >
      <WellCardTable
        TableData={casingData}
        TableHeader={casingColumns}
        tabContentTableSize={280}
        isLoading={isLoadingCasing}
      />
    </WellPanelAccordion>
  );
};

export default Casing;
