import {
  AddLocationAlt as AddLocationAltIcon,
  ShareLocation as ShareLocationIcon,
} from "@mui/icons-material";
import { Box, Chip, Divider, ListItem, Typography } from "@mui/material";

import { PolygonData } from "../../../../../types/map/gis/common";

import { SHAPE_FILE_UPLOAD_FILE_EXTENSIONS } from "../../../../../constants/map/shapefile";

import useMapDrawStore from "../../../../../store/map/draw/mapDrawStore";
import useMapStore from "../../../../../store/map/mapStore";
import usePanelsStore from "../../../../../store/panels/panelsStore";

import useShapeFile from "../../../../../customHooks/map/shapefile/useShapeFile";
import useDrawPolygon from "../../../../../customHooks/search/useDrawPolygon";

import DrawPolygon from "../../../../charts/utils/DrawPolygon";

import ButtonUpload from "../../../../common/ButtonUpload";
import Loading from "../../../../common/Loading";

import * as freeformDrawIconURL from "../../../../../assets/images/freeform_polygon.png";

export const PolygonSearch = () => {
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const polygons = useMapDrawStore((state) => state.polygons);
  const drawLayerSource = useMapStore((state) => state.drawLayerSource);

  const {
    drawPolygonDynamically,
    removeDrawnPolygon,
    removePolygonFromSource,
  } = useDrawPolygon();

  const {
    isUploadingShapeFile,
    removeAndUploadShapeFilePolygons,
    handleUploadShapeFile,
  } = useShapeFile({
    drawPolygonDynamically,
  });

  const handleLoadShapeClick = () => {
    if (isOpenQBEditorPanel) {
      toggleIsOpenQBEditorPanel(false);
    }
  };

  const handlePolygonDelete = (polygon: PolygonData) => {
    if (polygon.isShapeFile) {
      removeAndUploadShapeFilePolygons(polygon);
    } else {
      removeDrawnPolygon(polygon);
    }
    removePolygonFromSource(polygon, drawLayerSource);
  };

  return (
    <div className="polygon-search">
      <div className="polygon-search-header">
        <ShareLocationIcon />
        <Typography>SHAPES ({polygons.length})</Typography>
      </div>
      <Box className="polygon-search-content">
        {polygons.length ? (
          <>
            {polygons.map((polygon) => {
              return (
                <ListItem key={polygon.id}>
                  <Chip
                    icon={
                      <img
                        src={freeformDrawIconURL.default}
                        className="draw-controls-icon"
                        aria-label="free-form"
                        color="#8dc6f7"
                      />
                    }
                    label={polygon.id}
                    onDelete={() => handlePolygonDelete(polygon)}
                    disabled={isUploadingShapeFile && polygon.isShapeFile}
                    deleteIcon={
                      isUploadingShapeFile && polygon.isShapeFile ? (
                        <Loading size={20} />
                      ) : undefined
                    }
                  />
                </ListItem>
              );
            })}
          </>
        ) : (
          <div className="polygon-search-empty">
            <Typography variant="h6">Add shapes to current search</Typography>
          </div>
        )}
      </Box>
      <div className="polygon-buttons-container">
        <ButtonUpload
          label="LOAD SHAPE"
          buttonIcon={<AddLocationAltIcon fontSize="small" />}
          allowedFileExtension={SHAPE_FILE_UPLOAD_FILE_EXTENSIONS}
          handleFileChange={handleUploadShapeFile}
          buttonClickCallback={handleLoadShapeClick}
          buttonClasses={{ root: "load-button" }}
        />
        <Divider orientation="vertical" flexItem />
        <DrawPolygon />
      </div>
    </div>
  );
};

export default PolygonSearch;
