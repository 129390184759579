import { useState } from "react";

import { ChartDataBody } from "../../types/charts/chartHooks/chartData";
import { GetStackAreaData } from "../../types/charts/dashboardChartType/stackedAreaChart";

import config from "../../configs/appSettings";

import { STACKED_AREA_COLOR_PALLETE } from "../../constants/charts/chartColorPallete";
import { CHART_DATA_STATE } from "../../constants/charts/charts";
import { DASHBOARD_CHARTS_FIELDS_MAPPER } from "../../constants/charts/dashboardCharts";

import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

interface StackAreaData {
  x: string[];
  y: number[];
  name: string;
  stackgroup: string;
  mode: string;
  fillcolor: string;
}

interface StackAreaChartResponseData {
  fields: {
    name: string;
    val: string;
  }[];
  groupings: { name: string }[];
}

export const useStackedAreaChartData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<StackAreaChartResponseData[]>([]);
  const [formattedChartData, setFormattedChartData] = useState<StackAreaData[]>(
    []
  );
  const [error, setError] = useState(null);

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const { getUWIFileIdWithRetry } = useUwiFileUpload();

  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );

  const { buildChartPostRequest, isDashboardChartEmpty } =
    useChartSearchRequest();
  const { buildSearchRequest } = useSearchRequest();

  const getChartData = async ({
    chartDataState,
    chartType,
  }: GetStackAreaData) => {
    setIsLoading(true);
    setData([]);
    setError(null);

    try {
      if (isDashboardChartEmpty(chartDataState)) {
        setData([]);
        setFormattedChartData([]);
        setIsLoading(false);
        return;
      }
      const body: ChartDataBody = {
        chartType: DASHBOARD_CHARTS_FIELDS_MAPPER[chartType],
      };

      switch (chartDataState) {
        case CHART_DATA_STATE.PRE_SEARCH:
          body.dataForDefaultDashboard = true;
          break;
        case CHART_DATA_STATE.INTER_SEARCH:
          body.searchRequest = buildSearchRequest(chartType);
          break;
        case CHART_DATA_STATE.POST_SEARCH:
          body.searchRequest = buildChartPostRequest(chartType);
          break;
        default:
          break;
      }

      const response = await callServiceAPI<StackAreaChartResponseData[]>(
        `${config.endpoints.wellService}api/wells/chart`,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        isFromUploadedUWIFile
      );

      if (!response || !("data" in response)) {
        setData([]);
        return;
      }

      setData(response?.data);

      const chartTrace: StackAreaData[] = [];
      let lastYearLogged = 0;

      response.data.forEach((group, parentIndex) => {
        let groupingName = group.groupings[0].name.toString();
        const grouping = Number(group.groupings[0].name); //only has one value
        if (grouping.toString().length === 3) {
          //by decade
          groupingName = (grouping * 10).toString().concat("'s");
        }

        const trace: StackAreaData = {
          x: [],
          y: [],
          name: groupingName,
          stackgroup: "vintage",
          mode: "none",
          fillcolor: STACKED_AREA_COLOR_PALLETE[parentIndex],
        };

        group.fields.forEach((field, index) => {
          const dateFieldFormat = new Date(field.name);
          const year = dateFieldFormat.getFullYear();
          if (!parentIndex) {
            lastYearLogged = year;
          } else if (parentIndex > 0 && year - lastYearLogged > 1) {
            const yearDifference = year - lastYearLogged - 1;
            let i = 0;

            [...Array(yearDifference).keys()].forEach((val, index) => {
              const nextYear = lastYearLogged + 1;
              trace.x.push(nextYear.toString());
              trace.y.push(0);
              lastYearLogged = nextYear;
              i++;
            });
          } else {
            lastYearLogged = year;
          }

          trace.x.push(field.name);
          trace.y.push(Number(field.val));
        });

        chartTrace.push(trace);
      });

      setFormattedChartData(chartTrace);
      setIsLoading(false);
    } catch (error: any) {
      console.debug("getChartDataError error", error);
      setData([]);
      setFormattedChartData([]);
      setError(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    formattedChartData,
    error,
    getChartData,
  };
};
