import { getFormattedWellNameAndNumber } from "../../../utils";
import { WELLNAME_MAX_LENGTH } from "../constant";

const createTrace = ({ x = [0], y = [], text = [] }, obj) => {
  return {
    x,
    y,
    text,
    hoverinfo: "none",
    ...obj,
  };
};

const createAnnotation = (text, { x, y }, annotationConfig) => {
  return {
    x,
    y,
    text,
    ...annotationConfig,
  };
};

/**
 * Creates Text Array for a Trace
 * Well.wellNumber may be undefined for targetWell
 */
const createHoverTemplate = (wells) => {
  return wells.map((well) =>
    getFormattedWellNameAndNumber(well.wellName, well.wellNumber)
  );
};

export { createTrace, createAnnotation, createHoverTemplate };
