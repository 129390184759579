import { MouseEvent, useMemo, useState } from "react";

import {
  AddCircleOutlineRounded,
  Close,
  ShowChart as ShowChartIcon,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import classNames from "classnames";

import {
  ChartType,
  IChartTypeInfo,
} from "../../../types/charts/chartType/chartType";

import {
  ANALYSIS_PANE_CHART_LIST,
  CHART_SCALING_TYPE,
  CHART_STATUS,
  CHART_TYPES,
  DEFAULT_ANALYSIS_CHARTS_VALUE,
} from "../../../constants/charts/charts";
import { PRODUCTION_PLOT, TYPE_CURVE } from "../../../constants/constants";

import useChartStore from "../../../store/chart/chartStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import useChartReference from "../../../customHooks/charts/useChartReference";
import useScatterPlotChartReset from "../../../customHooks/charts/useScatterPlotChartReset";
import useRecordType from "../../../customHooks/common/useRecordType";

import { generateId } from "../../../utils/common/generateId";

import { darkTheme, lightTheme } from "../../../themes";
import useOutsideClick from "../../useOutsideClick";

import { default as ProductionPlotSelector } from "../../../assets/images/ProductionPlotSelector.svg";
import { default as ScatterPlotSelector } from "../../../assets/images/ScatterPlotSelector.svg";
import { default as TypeCurveSelector } from "../../../assets/images/TypeCurveSelector.svg";

export const AnalysisChartToggle = () => {
  const addModule = useModularityStore((state) => state.addModule);
  const addChartFullScreenLayout = useModularityStore(
    (state) => state.addChartFullScreenLayout
  );
  const addChartDefaultLayout = useModularityStore(
    (state) => state.addChartDefaultLayout
  );
  const addChartData = useChartStore((state) => state.addChartData);
  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );
  const { getScatterPlotDefault } = useScatterPlotChartReset({});
  const { searchedRecordType } = useRecordType();
  const { getIDsReference } = useChartReference();

  const [modulesMenu, setModulesMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setModulesMenu(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setModulesMenu(false);
  };

  const handleSelectItem = (type: ChartType) => {
    toggleAnalysisPanel(true);
    const id = generateId(5);
    addModule(id, type, "", Boolean(getIDsReference(type).length));
    addChartDefaultLayout(id, type);
    addChartFullScreenLayout(id, type);

    switch (type) {
      case CHART_TYPES.PRODUCTION_PLOT:
      case CHART_TYPES.TYPE_CURVE:
        const newChartData: IChartTypeInfo = {
          objectType: type,
          title: "",
          chartType: type,
          chartId: id,
          chartYAxisScaling: CHART_SCALING_TYPE.LINEAR,
          chartShowForecast: DEFAULT_ANALYSIS_CHARTS_VALUE[type].SHOWFORECAST,
          chartShowDailyValues: false,
          chartDisplayedDataFields:
            DEFAULT_ANALYSIS_CHARTS_VALUE[type].DATA_FIELDS_TO_DISPLAY,
          chartData: [],
          groupBy: DEFAULT_ANALYSIS_CHARTS_VALUE[type].GROUP_BY,
          chartRange: undefined,
          status: CHART_STATUS.EXPANDED,
          chartShowByNormalizedLateralLength: false,
        };
        addChartData(newChartData);
        break;
      case CHART_TYPES.SCATTER_PLOT:
        const defaultValues = getScatterPlotDefault(searchedRecordType);

        if (defaultValues) {
          const newScatterPlotChartData: IChartTypeInfo = {
            objectType: type,
            title: "",
            chartType: type,
            chartId: id,
            chartData: [],
            chartRange: undefined,
            status: CHART_STATUS.EXPANDED,
            chartXAxisDataFields: defaultValues.xAxis,
            chartYAxisDataFields: defaultValues.yAxis,
            chartAxis: defaultValues.axis,
            chartColorBy: defaultValues.colorBy,
          };
          addChartData(newScatterPlotChartData);
        }

        break;
    }
  };

  const chartImg = (chart: ChartType) => {
    if (chart === PRODUCTION_PLOT) {
      return (
        <img src={ProductionPlotSelector} alt="Production Plot Selector" />
      );
    } else if (chart === TYPE_CURVE) {
      return <img src={TypeCurveSelector} alt="Type Curve Selector" />;
    } else {
      return <img src={ScatterPlotSelector} alt="Scatter Plot Selector" />;
    }
  };

  const analyzeChartSelection = useMemo(() => {
    return (
      <ThemeProvider theme={darkTheme}>
        <Menu
          keepMounted
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="analyze-chart-menu"
        >
          <div className="add-new-chart">
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography className="analyze-menu-label">
                  Add New Chart
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Button className="analyze-menu-close" endIcon={<Close />} />
              </Grid>
            </Grid>

            <Divider />
          </div>
          {ANALYSIS_PANE_CHART_LIST.map((chart: ChartType) => (
            <MenuItem
              divider
              key={chart}
              onClick={() => handleSelectItem(chart)}
            >
              {chartImg(chart)}
            </MenuItem>
          ))}
        </Menu>
      </ThemeProvider>
    );
  }, [modulesMenu]);

  const chartsRef: any = useOutsideClick(handleMenuClose);

  return (
    <div
      className={classNames("analyze-button-container", {
        "charts-empty": !selectedGridDataKeys.length,
        "charts-open": modulesMenu,
      })}
    >
      <ThemeProvider theme={lightTheme}>
        <Button
          onClick={(e: any) => {
            handleMenuOpen(e);
          }}
          startIcon={<ShowChartIcon className="charts-icon-button" />}
          endIcon={<AddCircleOutlineRounded />}
          ref={chartsRef}
        >
          <Typography>ANALYZE</Typography>
        </Button>
      </ThemeProvider>
      {analyzeChartSelection}
    </div>
  );
};

export default AnalysisChartToggle;
