export const formationColumns = [
  {
    field: "formationName",
    headerName: "Formation Name",
    width: 120,
    sortable: false,
  },
  {
    field: "topDepth",
    headerName: "Top Depth",
    width: 80,
    sortable: false,
    type: "number",
  },
];
