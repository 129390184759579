import { FC } from "react";

import { PermitPanelSectionProp } from "../../../../types/panels/permitPanel/permitPanel";

import {
  PERMIT_INFO_HEADER_ATTRIBUTES,
  PERMIT_PANEL_SECTION,
} from "../../../../constants/panels/permitPanel/permit";

import { truncateStringDecimal } from "../../../../utils/helper2";
import { checkFormatValidation } from "../../../../utils/panel/wellPanel/fieldValidation";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../../wellPanel/common/WellPanelAccordion";

const Header: FC<PermitPanelSectionProp> = ({ isExpanded, onChange, data }) => {
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={PERMIT_PANEL_SECTION.HEADER_SECTION.displayLabel}
      onChange={() => onChange(PERMIT_PANEL_SECTION.HEADER_SECTION)}
      isLoading={false}
    >
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.label}
            value={checkFormatValidation(
              truncateStringDecimal(
                data[PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.key],
                6
              )
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.label}
            value={checkFormatValidation(
              truncateStringDecimal(
                data[PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.key],
                6
              )
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.label}
            value={checkFormatValidation(
              truncateStringDecimal(
                data[PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.key],
                6
              )
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.label}
            value={checkFormatValidation(
              truncateStringDecimal(
                data[PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.key],
                6
              )
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.label}
            value={`${checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key]
            )} ft`}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key]
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.label}
            value={checkFormatValidation(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.key]
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default Header;
