import { useCallback, useEffect, useRef } from "react";

import { FileDownloadOutlined as FileDownloadOutlinedIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import classNames from "classnames";
import { CircularButton } from "component-library";

import {
  MapLayer,
  WellPanelInfo,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import { DYNAMIC_WELL_SPOTS, WELL_SPOTS } from "../../../constants/constants";
import {
  MAP_LAYER,
  UWI,
  WELL_INFO_WELL_NAME,
  WELL_INFO_WELL_NUMBER,
  WELL_INFO_WELL_STATUS,
} from "../../../constants/panels/wellPanel/wellInfo";

import useExportPanelStore from "../../../store/exportPanel/exportPanelStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useStore from "../../../store/useStore";

import useExportWellPanel from "../../../customHooks/panels/wellPanel/export/useExportWellPanel";
import useExportWellPanelState from "../../../customHooks/panels/wellPanel/export/useExportWellPanelState";
import useWellPanelExportChecking from "../../../customHooks/panels/wellPanel/export/useWellPanelExportChecking";

import { getTruncatedWellStatus } from "../../../utils/helper2";
import { checkFormatValidation } from "../../../utils/panel/wellPanel/fieldValidation";

import WellPanelChartExports from "./WellPanelChartExports";

interface WellPanelHeaderProps {
  data: WellSystemData;
  groupedWellID: number;
  parentWellId: number;
  bottomWellBoreId: number;
  wellPanelInfo: WellPanelInfo;
  layer: MapLayer;
}

const WellPanelHeader = ({
  data,
  groupedWellID,
  layer,
  wellPanelInfo,
  parentWellId,
  bottomWellBoreId,
}: WellPanelHeaderProps) => {
  const gunBarrelRef = useRef<any>();
  const productionRef = useRef<any>();

  const removeWellPanelByKey = usePanelsStore(
    (state) => state.removeWellPanelByKey
  );

  const selectedWellCardPWID = usePanelsStore(
    (state) => state.selectedWellCardPWIDs
  );

  const selectedWellCardBWIDs = usePanelsStore(
    (state) => state.selectedWellCardBWIDs
  );
  const wellList = usePanelsStore((state) => state.wellList);

  const removeSelectedWellCardByPWID = usePanelsStore(
    (state) => state.removeSelectedWellCardByPWID
  );
  const removeSelectedWellCardByBWID = usePanelsStore(
    (state) => state.removeSelectedWellCardByBWID
  );

  const removeMultipleSlectedWellCardByBWID = usePanelsStore(
    (state) => state.removeMultipleSlectedWellCardByBWID
  );

  const removeWellEconomicsByWellIds = usePanelsStore(
    (state) => state.removeWellEconomicsByWellIds
  );

  const { setOpenedPanelExportSections } = useWellPanelExportChecking();
  const isOpenExportWellPanelDialog = useExportPanelStore(
    (state) => state.isOpenExportWellPanelDialog
  );

  const updateIsOpenExportWellPanelDialog = useExportPanelStore(
    (state) => state.updateIsOpenExportWellPanelDialog
  );

  const gunBarrelData = useExportPanelStore((state) => state.gunBarrelData);
  const updateExportModalProps = useExportPanelStore(
    (state) => state.updateExportModalProps
  );

  const exportChart = useStore((state) => state.exportChart);
  const updateExportChart = useStore((state) => state.updateExportChart);

  const { groupedByParentWellID } = useDataGridStore(
    (state) => state.allWellSelectors
  );

  const { enableWellPanelExport } = useExportWellPanelState();
  const { updateExportImage } = useExportWellPanel();

  const wellBottomsToRemove = useCallback(
    (groupedBWIDs: number[]) =>
      groupedBWIDs.filter((bwid) => {
        const wellInfo = wellList.find(
          (data) =>
            data.wellType !== WELL_SPOTS &&
            data.wellType !== DYNAMIC_WELL_SPOTS &&
            data.bottomWellBoreId === bwid
        );

        if (wellInfo) return false;
        return true;
      }),
    [wellList]
  );

  const isOpenSurfaceSpot = useCallback(
    (parentWellId: number) => {
      return wellList.find(
        (data) =>
          (data.wellType === WELL_SPOTS ||
            data.wellType === DYNAMIC_WELL_SPOTS) &&
          data.parentWellId === parentWellId
      );
    },
    [wellList]
  );

  const handleCloseWellPanel = () => {
    const wellIDs = wellPanelInfo?.wellSystemData?.map(
      (wellSystem) => wellSystem.wellId
    );
    if (wellIDs) removeWellEconomicsByWellIds(wellIDs);

    removeWellPanelByKey(layer, groupedWellID);

    if (
      wellPanelInfo.wellType === MAP_LAYER.WELL_SPOTS ||
      wellPanelInfo.wellType === MAP_LAYER.DYNAMIC_WELL_SPOTS
    ) {
      if (selectedWellCardPWID?.includes(parentWellId)) {
        removeSelectedWellCardByPWID(parentWellId);

        if (wellPanelInfo.groupedBWIDs?.length) {
          //do not remove opened bottom well spots
          const BWIDs = wellBottomsToRemove(wellPanelInfo.groupedBWIDs);

          if (BWIDs.length) removeMultipleSlectedWellCardByBWID(BWIDs);
        }
      }
    } else {
      if (selectedWellCardBWIDs?.includes(bottomWellBoreId)) {
        if (!isOpenSurfaceSpot(parentWellId))
          removeSelectedWellCardByBWID(bottomWellBoreId);

        const surfaceEquivalent = groupedByParentWellID[parentWellId];

        if (
          surfaceEquivalent?.UWI === data.wellCardData[UWI.key] &&
          selectedWellCardPWID?.includes(parentWellId)
        ) {
          removeSelectedWellCardByPWID(parentWellId);
        }
      }
    }
  };

  const handleOnExportClick = () => {
    updateIsOpenExportWellPanelDialog(true);
  };

  useEffect(() => {
    //trigger only when wellPanelInfo is active well panel
    if (
      enableWellPanelExport &&
      wellPanelInfo.isActive &&
      wellPanelInfo.wellSystemData
    ) {
      setOpenedPanelExportSections(wellPanelInfo.wellSystemData);
    }
  }, [
    enableWellPanelExport,
    wellPanelInfo.isActive,
    wellPanelInfo.wellSystemData,
  ]);

  useEffect(() => {
    if (isOpenExportWellPanelDialog && wellPanelInfo.isActive) {
      const wellCardUwi = data.wellCardData[UWI.key] as string;
      updateExportModalProps({
        activePageWellCardUwi: wellCardUwi,
        activePageWellId: data.wellId,
        activeWellPanelInfo: wellPanelInfo,
        gunBarrelRef: gunBarrelRef,
        productionRef: productionRef,
      });
    }
    if (
      isOpenExportWellPanelDialog &&
      (data.productionData || data.gunBarrelData)
    ) {
      updateExportImage(wellPanelInfo, data.wellCardData[UWI.key] as string);
    } else {
      updateExportChart({ chartType: "", chartId: "", chartMode: "" });
    }
  }, [
    isOpenExportWellPanelDialog,
    data.productionData,
    data.gunBarrelData,
    wellPanelInfo.isActive,
  ]);

  return (
    <div className="well-panel-header">
      <div className="well-panel-header-wrapper">
        <span className="well-uwi">{data.wellCardData[UWI.key]}</span>{" "}
        <div className="well-panel-pill-container">
          {data.wellCardData[WELL_INFO_WELL_STATUS.key] && (
            <span className="well-panel-pill">
              {getTruncatedWellStatus(
                checkFormatValidation(
                  data.wellCardData[WELL_INFO_WELL_STATUS.key]
                ) ?? ""
              )}
            </span>
          )}
          <span className="well-panel-pill">
            <span className="well-panel-pill-text">
              {wellPanelInfo.recordType}
            </span>
          </span>
        </div>
        <span className="well-panel-header-button-container">
          <div className="well-panel-buttons">
            <Button
              className={classNames("export-well-panel-button", {
                isModalOpen: isOpenExportWellPanelDialog,
              })}
              onClick={handleOnExportClick}
              disabled={!enableWellPanelExport}
              disableRipple
              endIcon={<FileDownloadOutlinedIcon />}
            />
            <CircularButton
              icon={"close"}
              className="well-panel-close-button"
              onClick={handleCloseWellPanel}
            />
          </div>
        </span>
      </div>
      <div className="well-name-number">
        {data.wellCardData[WELL_INFO_WELL_NAME.key]},{" "}
        {data.wellCardData[WELL_INFO_WELL_NUMBER.key]}
      </div>
      {exportChart.length > 0 && (
        <WellPanelChartExports
          wellPanelInfo={wellPanelInfo}
          exportChart={exportChart}
          gunBarrelRef={gunBarrelRef}
          productionRef={productionRef}
          gunBarrelData={gunBarrelData}
          layer={layer}
          groupedWellID={groupedWellID}
          data={data}
        />
      )}
    </div>
  );
};

export default WellPanelHeader;
