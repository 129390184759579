import {
  MAX_TARGET_FORMATION_DISTANCE,
  X_AXIS,
  Y_AXIS_PADDING,
  childMarkerConfig,
  formationAnnotationConfig,
  formationConfig,
  parentMarkerConfig,
  siblingMarkerConfig,
  targetMarkerConfig,
} from "../../components/WellCard/constant/index";
import {
  compare,
  getCoordinates,
  getFormationDistanceFromTargetWell,
} from "../../components/WellCard/utils/mathUtils";
import {
  createAnnotation,
  createHoverTemplate,
  createTrace,
} from "../../components/WellCard/utils/traceUtils";

//functions for initilization
const initGunBarrel = (rawData: any) => {
  const formattedData = gunBarrelBuilder(rawData);

  return getCorrectFormationData(formattedData);
};

//private
const getCorrectFormationData = (formattedData: any) => {
  const targetWell = formattedData.targetWell;

  return createFormattedData(
    formattedData,
    X_AXIS,
    { range: [targetWell.y - Y_AXIS_PADDING, targetWell.y + Y_AXIS_PADDING] },
    getWellTraces(formattedData)
  );
};

//private
const createFormattedData = (
  formattedData: any,
  xAxis: any,
  yAxis: any,
  wellTraces: any = []
) => {
  const [xMin, xMax] = xAxis.range;
  const [yMin] = yAxis.range;

  return {
    originalData: formattedData,
    formationTraces: getFormationTraces(formattedData, xMin, xMax),
    wellTraces,
    annotations: getFormationAnnotations(
      formattedData.formations,
      formattedData.targetWell,
      xMax,
      yMin
    ),
    xRange: xAxis,
    yRange: yAxis,
  };
};

/**
 * Handle API edge cases
 * @param {*} data
 */

type gunBarrelKeys = {
  [key: string]: any;
};

const gunBarrelBuilder = (data: any) => {
  const formattedData: gunBarrelKeys = {};

  const gunBarrelKeys = ["parents", "children", "siblings", "formations"];

  gunBarrelKeys.forEach((key) => {
    formattedData[key] = key in data ? data[key] : [];
  });

  formattedData["targetWell"] = data.targetWell;

  //consider case of multiple formations with > 500 ft from target well
  formattedData.formations = sortFormations(formattedData);

  return formattedData;
};

//private
const sortFormations = (data: any) => {
  let sortedFormations = [];

  //sort
  sortedFormations = data.formations.sort(compare);

  sortedFormations = sortedFormations.filter(
    (f: any) => f.yoffset < MAX_TARGET_FORMATION_DISTANCE
  );
  //slice
  //get all formations and first formation that is > 500 ft from target well
  const length = sortedFormations.length;
  let i = 0;

  while (i < length) {
    const offset = sortedFormations[i].yoffset;

    if (
      (offset <= -410 && offset >= -500) ||
      offset < -MAX_TARGET_FORMATION_DISTANCE
    ) {
      break;
    }

    i++;
  }

  return sortedFormations.slice(0, ++i);
};

//private
const getWellTraces = (data: any) => {
  const targetWell = [
    createTrace(
      {
        y: [data.targetWell.y],
        text: createHoverTemplate([data.targetWell]),
      },
      targetMarkerConfig
    ),
  ];

  const children = [
    createTrace(
      {
        x: getCoordinates(data.children),
        y: getCoordinates(data.children, "y"),
        text: createHoverTemplate(data.children),
      },
      childMarkerConfig
    ),
  ];

  const siblings = [
    createTrace(
      {
        x: getCoordinates(data.siblings),
        y: getCoordinates(data.siblings, "y"),
        text: createHoverTemplate(data.siblings),
      },
      siblingMarkerConfig
    ),
  ];

  const parents = [
    createTrace(
      {
        x: getCoordinates(data.parents),
        y: getCoordinates(data.parents, "y"),
        text: createHoverTemplate(data.parents),
      },
      parentMarkerConfig
    ),
  ];

  return [...targetWell, ...siblings, ...children, ...parents];
};

//private
const getFormationTraces = (data: any, xMin: any, xMax: any) => {
  const formations = data.formations;

  //handle removing last formation if < -500ft
  if (formations.length !== 0) {
    const length = formations.length - 1;
    const offset = formations[length].yoffset;

    if (offset <= -MAX_TARGET_FORMATION_DISTANCE) {
      return createFormationsTraces(formations.slice(0, length), xMin, xMax);
    }
  }
  return createFormationsTraces(formations, xMin, xMax);
};

//private
const createFormationsTraces = (formations: any, xMin: any, xMax: any) => {
  return formations.map((f: any) =>
    createTrace(
      {
        x: [xMin, xMax],
        y: [f.y, f.y],
      },
      formationConfig
    )
  );
};

//private
const getFormationAnnotations = (
  formations: any,
  targetWell: any,
  xMax: any,
  yMin: any
) => {
  return [
    ...formations.map((f: any) =>
      createAnnotation(
        f.name,
        {
          x: xMax,
          y: getFormationY(targetWell, f, yMin),
        },
        formationAnnotationConfig
      )
    ),
  ];
};

//private
const getFormationY = (targetWell: any, f: any, yMin: any) => {
  if (targetWell === undefined) {
    return f.y;
  }
  return getFormationDistanceFromTargetWell(f.y, targetWell.y) <
    MAX_TARGET_FORMATION_DISTANCE
    ? f.y
    : yMin;
};

//well card resize API
const updateGunBarrelStyles = (data: any) => {
  const { wellTraces } = data;

  return {
    ...data,
    annotations: updateAnnotationFontSize(data.annotations),
    wellTraces: wellTraces.length
      ? [
          {
            ...wellTraces[0],
            marker: {
              ...wellTraces[0].marker,
              size: 12,
            },
          },
          ...updateMarkerSize(wellTraces.slice(1)),
        ]
      : [],
  };
};

//well card resizing
const updateAnnotationFontSize = (annotations: any) => {
  return annotations.map((a: any) => ({
    ...a,
    font: {
      ...a.font,
      size: "medium",
    },
    yshift: -9,
    borderpad: 0,
    align: "right",
    xanchor: "right",
    yanchor: "middle",
  }));
};

//private
const getYShift = (gunBarrelContentSize: any) => {
  switch (gunBarrelContentSize) {
    case "large":
      return -12;
    case "medium":
      return -10;
    case "small":
    default:
      return -9;
  }
};

//well card resizing
const updateMarkerSize = (traces: any) => {
  return traces.map((t: any) => ({
    ...t,
    marker: {
      ...t.marker,
      size: 9,
    },
  }));
};

export function layout(gunBarrelData: any, isChartExport: boolean) {
  return {
    margin: {
      t: isChartExport ? 60 : 35,
    },
    zeroline: "false",
    datarevision: 0,
    hoverlabel: {
      bgcolor: "rgba(255,255,255,0.9)",
    },
    hoverdistance: 40,
    showlegend: true,
    legend: {
      x: 1,
      y: 0.5,
    },
    width: 550,
    height: 500,
    paper_bgcolor: "#272727",
    plot_bgcolor: "#272727",
    font: {
      family: "Roboto, Arial",
      size: 12,
      color: "rgba(255, 255, 255, 0.7)",
    },
    xaxis: {
      fixedrange: true,
      showgrid: true,
      gridwidth: 3,
      ticks: "outside",
      tickwidth: 1.5,
      title: {
        text: "Horizontal Distance From Target Well (ft)",
        standoff: 30,
        font: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
      tick0: -2500,
      dtick: 1250,
      showticklabels: true,
      range: gunBarrelData.xRange.range,
      showline: true,
      linewidth: 1,
      mirror: true,
      tickformat: ",.0f",
      zerolinecolor: "2a2a2a",
      zerolinewidth: 3,
    },
    yaxis: {
      zeroline: false,
      fixedrange: true,
      automargin: true,
      gridwidth: 3,
      standoff: 1000,
      title: {
        text: "TVD (ft)",
        standoff: 20,
        font: {
          color: "rgba(255, 255, 255, 0.7)",
        },
      },
      tick0: -8050,
      dtick: 400,
      showgrid: true,
      range: [gunBarrelData.yRange.range[1], gunBarrelData.yRange.range[0]],
      showline: true,
      linewidth: 1,
      mirror: true,
      ticks: "outside",
      tickwidth: 1.5,
      tickformat: ",.0f",
    },
    annotations: gunBarrelData.annotations,
  };
}

const getFormattedLegend = (gunBarrelFontSize: number) => {
  const fn = (gunBarrelFontSize: number) => {
    switch (gunBarrelFontSize) {
      case 16:
        return {
          y: -0.4,
          yanchor: "bottom",
          entrywidth: 98,
          font: {
            size: 16,
          },
        };
      case 14:
        return {
          y: -0.3,
          entrywidth: 73,
          font: {
            size: 14,
          },
        };
      case 12:
      default:
        return {
          y: -0.4,
          entrywidth: 47,
          font: {
            size: 7,
          },
        };
    }
  };

  return {
    orientation: "h",
    x: -0.2,
    itemclick: false,
    itemdoubleclick: false,
    color: "rgba(117, 117, 117, 1)",
    tracegroupgap: 3,
    ...fn(gunBarrelFontSize),
  };
};

export {
  initGunBarrel,
  //well card resizing
  updateGunBarrelStyles,
};
