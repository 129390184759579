import { GridColDef } from "@mui/x-data-grid-premium";

import { LogType } from "../../../types/panels/wellPanel/logsType";

import LogPanelCell from "../../../components/panels/wellPanel/common/LogPanelCell";

export const dataLakeSize = 15;
export const logSearchType = ["Las", "Raster"] as const;
export const LOGSTAB = "logs";
export const DOCSTAB = "docs";

export const logSearchFields = [
  "DocType",
  "LasFileType",
  "StartValue",
  "StopValue",
  "LasTriple",
  "LasQuad",
  "Curves",
  "LogTypeClass",
  "DisplayClass",
  "MinDepth",
  "MaxDepth",
];

export const logTypeValues: { [key: string]: LogType } = {
  STANDARD: {
    key: "standard",
    displayName: "LAS",
  },
  WORK_STATION_READY: {
    key: "work station ready",
    displayName: "LAS+",
  },
  RASTER: {
    key: "raster",
    displayName: "Raster",
  },
  MUD: {
    key: "mud",
    displayName: "Mud LAS",
  },
  LITHOLOGY: {
    key: "lithology",
    displayName: "Lith LAS",
  },
  ANALYTIC_READY_LAS: {
    key: "analytic ready las",
    displayName: "ARLAS",
  },
  PETROPHYSICAL_LOGS: {
    key: "petrophysical interpretation logs",
    displayName: "PI Logs",
  },
};
export const logPanelColumns: GridColDef[] = [
  {
    field: "logType",
    headerName: "Log Type",
    renderCell: LogPanelCell,
    width: 150,
  },
  {
    field: "description",
    headerName: "Detailed Description",
    width: 210,
  },
  {
    field: "topDepth",
    headerName: "Top Depth",
  },
  {
    field: "baseDepth",
    headerName: "Base Depth",
  },
];

export const COMBO_FLAG_LABELS = {
  QUAD: "QUAD",
  TRIP: "TRIP",
};
