import { StateCreator } from "zustand";

import {
  ExportCardStateStore,
  ExportUWIDataStore,
} from "../../types/exports/export-card/exportCardState";

const exportCardSlice: StateCreator<ExportCardStateStore> = (set, get) => ({
  exportCard: false,
  updateExportCard: (exportCard) => set((state) => ({ exportCard })),
  isSearchCriteriaChange: false,
  updateIsSearchCriteriaChange: (isSearchCriteriaChange) =>
    set((state) => ({ isSearchCriteriaChange })),
  exportPanelMenuTrigger: false,
  updateExportPanelMenuTrigger: (exportPanelMenuTrigger) =>
    set((state) => ({ exportPanelMenuTrigger })),
  exportManagerLoading: false,
  updateExportManagerLoading: (exportManagerLoading) =>
    set((state) => ({ exportManagerLoading })),
});

const exportUWIDataSlice: StateCreator<ExportUWIDataStore> = (set, get) => ({
  exportUwiList: [],
  updateExportUwiList: (exportUwiList) => {
    set((state) => ({ exportUwiList }));
  },
  exportWellCounts: false,
  updateExportWellCounts: (exportWellCounts) =>
    set((state) => ({ exportWellCounts })),
});

export { exportCardSlice, exportUWIDataSlice };
