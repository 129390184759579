import { FC, useEffect, useState } from "react";

import {
  DataLakeLogsDocumentBody,
  DataLakeLogsResponseBody,
} from "../../../../types/panels/wellPanel/dataLakeType";
import { LogsProps } from "../../../../types/panels/wellPanel/logsDocsType";
import { LogRowDataType } from "../../../../types/panels/wellPanel/logsType";

import { COUNTRY } from "../../../../constants/constants";
import {
  dataLakeSize,
  logPanelColumns,
  logSearchFields,
  logSearchType,
} from "../../../../constants/panels/wellPanel/logs";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useDatalake from "../../../../customHooks/panels/wellPanel/useDatalake";
import useLogsData from "../../../../customHooks/panels/wellPanel/useLogsData";

import { formatUWI12ByCountry } from "../../../../utils/common/wells";
import { floatValidation } from "../../../../utils/panel/wellPanel/fieldValidation";

import WellCardTable from "../../../WellCard/WellCardTable";

const LogsPanel: FC<LogsProps> = ({
  groupedWellID,
  wellSystem,
  activePage,
  layer,
  country,
}) => {
  const { dataLakeResponse, getDataLakeData, successRequest, loadingRequest } =
    useDatalake<DataLakeLogsResponseBody>();
  const [logData, setLogData] = useState<LogRowDataType[]>([]);
  const { getLogTypeDisplayName, getLASDescription } = useLogsData();
  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );

  const assembleGridRows = (response: DataLakeLogsDocumentBody[]) => {
    const rows: LogRowDataType[] = [];
    response.map((item) => {
      if (item.DocType === "Raster") {
        rows.push({
          id: item.Id,
          type: "raster",
          logType: getLogTypeDisplayName(item.DocType),
          description: item.LogTypeClass,
          topDepth: floatValidation(item.MinDepth, 0),
          baseDepth: floatValidation(item.MaxDepth, 0),
        });
      } else if (item.DocType === "Las") {
        const logTypeDisplayName = getLogTypeDisplayName(item.LasFileType);
        if (logTypeDisplayName) {
          rows.push({
            id: item.Id,
            type: "las",
            logType: logTypeDisplayName,
            lasTriple: item.LasTriple,
            lasQuad: item.LasQuad,
            description: getLASDescription(item.Curves),
            topDepth: floatValidation(item.StartValue, 0),
            baseDepth: floatValidation(item.StopValue, 0),
          });
        }
      }
    });

    return rows;
  };

  useEffect(() => {
    if (successRequest && dataLakeResponse?.length) {
      const logRowData = assembleGridRows(dataLakeResponse);
      setLogData(logRowData);
      updateWellCardDataByTypeAndKey(
        { type: "logs", logs: logRowData },
        wellSystem.wellId,
        layer,
        groupedWellID
      );
    } else {
      setLogData([]);
    }
  }, [dataLakeResponse, successRequest]);

  useEffect(() => {
    if (wellSystem.pageNumber === activePage && country) {
      //check if data was already in store, else call api
      if (wellSystem.logData?.length) {
        setLogData(wellSystem.logData);
      } else {
        const UWIByCountry = formatUWI12ByCountry(
          wellSystem.wellCardData.uwi as string,
          country
        );

        const requestBody = {
          SearchTypes: logSearchType,
          Query: `Uwi:${UWIByCountry}`,
          IncludeFields: logSearchFields,
          From: 0, // from count to start at (paging)
          Size: dataLakeSize,
        };

        getDataLakeData(requestBody);
      }
    }
  }, [wellSystem.pageNumber, wellSystem.logData, activePage, country]);

  return (
    <WellCardTable
      TableData={logData}
      TableHeader={logPanelColumns}
      tabContentTableSize={"80%"}
      isLoading={loadingRequest}
    />
  );
};
export default LogsPanel;
