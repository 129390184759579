import {
  childMarkerStyle,
  formationStyle,
  parentsMarkerStyle,
  siblingMarkerStyle,
  targetMarkerStyle,
} from "../styles/trace";

const showlegend = true;
const COMMON_MARKER_CONFIG = {
  type: "scatter",
};

const targetMarkerConfig = {
  marker: targetMarkerStyle,
  name: "Target Well",
  mode: "markers",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const parentMarkerConfig = {
  marker: parentsMarkerStyle,
  mode: "markers",
  name: "Parent Wells",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const childMarkerConfig = {
  marker: childMarkerStyle,
  mode: "markers",
  name: "Child Wells",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const siblingMarkerConfig = {
  marker: siblingMarkerStyle,
  mode: "markers",
  name: "Sibling Wells",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const formationConfig = {
  marker: formationStyle,
  type: "lines",
  mode: "lines",
  showlegend: !showlegend,
  hoverinfo: "skip",
};

const formationAnnotationConfig = {
  font: {
    color: "rgba(255, 255, 255, 0.7)",
    size: 12, //will be updated in hook
  },
  showarrow: false,
  xanchor: "right",
  yshift: -10, //will be updated in hook
};

export {
  targetMarkerConfig,
  parentMarkerConfig,
  childMarkerConfig,
  siblingMarkerConfig,
  formationConfig,
  formationAnnotationConfig,
};
