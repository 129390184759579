import { FC } from "react";

import { Button } from "@mui/material";

import { SearchTypes } from "../../../../types/panels/searchPanel/search";

import { SEARCH_TYPES } from "../../../../constants/panels/searchPanel/search";

import usePanelsStore from "../../../../store/panels/panelsStore";

import ButtonWithPrompt from "../../../common/ButtonWithPrompt";
import SaveSearchButton from "./SaveSearchButton";

interface Props {
  searchType?: SearchTypes;
  isResetDisabled: boolean;
  isSaveDisabled: boolean;
  isSearchDisabled: boolean;
  isShowPrompt: boolean;
  hasLoadedSavedSearch: boolean;
  handleResetClick: () => void;
  onClickSaveCallback: () => void;
  handleSearchClick: () => void;
  handleCancelClick: () => void;
}

const ActionButtons: FC<Props> = ({
  searchType = SEARCH_TYPES.ATTRIBUTE_SEARCH,
  isResetDisabled,
  isSaveDisabled,
  isSearchDisabled,
  isShowPrompt,
  hasLoadedSavedSearch,
  handleResetClick,
  onClickSaveCallback,
  handleSearchClick,
  handleCancelClick,
}) => {
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const closeAttributesPanel = () => {
    if (isOpenQBEditorPanel) {
      toggleIsOpenQBEditorPanel(false);
    }
  };

  return (
    <div className="action-button-container">
      <Button
        className="text-white"
        aria-label="reset-btn"
        size="medium"
        onClick={handleResetClick}
        disabled={isResetDisabled}
      >
        Reset
      </Button>
      <SaveSearchButton
        searchType={searchType}
        hasLoadedSavedSearch={hasLoadedSavedSearch}
        disabled={isSaveDisabled}
        onClickSaveCallback={onClickSaveCallback}
        closeAttributesPanel={closeAttributesPanel}
      />
      <ButtonWithPrompt
        label="Search"
        description="Do you want to start a new search?"
        disabled={isSearchDisabled}
        showPrompt={isShowPrompt}
        handleConfirmClick={handleSearchClick}
        handleCancelClick={handleCancelClick}
      />
    </div>
  );
};

export default ActionButtons;
