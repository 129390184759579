export const casingColumns = [
  {
    field: "casingType",
    headerName: "Casing Type",
    width: 100,
    sortable: false,
  },
  {
    field: "casingPurpose",
    headerName: "Casing Purpose",
    width: 120,
    sortable: false,
  },
  {
    field: "holeSize",
    headerName: "Hole Size",
    width: 80,
    sortable: false,
    type: "number",
  },
  {
    field: "casingSize",
    headerName: "Casing Size",
    width: 100,
    sortable: false,
    type: "number",
  },
  {
    field: "casingWeight",
    headerName: "Casing Weight",
    width: 120,
    sortable: false,
    type: "number",
  },
  {
    field: "topDepth",
    headerName: "Top Depth",
    width: 100,
    sortable: false,
    type: "number",
  },
  {
    field: "bottomDepth",
    headerName: "Bottom Depth",
    width: 110,
    sortable: false,
    type: "number",
  },
  {
    field: "casingGrade",
    headerName: "Casing Grade",
    width: 110,
    sortable: false,
  },
];
