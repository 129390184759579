import { useMemo, useState } from "react";

import axios, { AxiosError } from "axios";
import { Data, Datum } from "plotly.js";

import {
  DataSet,
  ProductionDataBody,
} from "../../../types/charts/chartHooks/productionData";
import { ChartDataFields } from "../../../types/charts/chartType/chartType";
import { GroupedProductionResponseData } from "../../../types/charts/chartType/productionChartData";
import { APIErrorResponse } from "../../../types/common/api";
import { GetProductionDataProps } from "../../../types/panels/wellPanel/hooks";
import { ProductionData } from "../../../types/panels/wellPanel/wellPanel";

import config from "../../../configs/appSettings";

import {
  CHARTS_FIELDS_TO_CHART_TYPE_MAPPER,
  CHART_DATA_SOURCE,
  CHART_GROUP_BY_TYPE,
  CHART_TYPES,
  PRODUCTION_DATA_FIELDS_LIST,
} from "../../../constants/charts/charts";
import { PRODUCTION_PLOT_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import {
  extractGData,
  groupDataByFields,
} from "../../../utils/charts/aggregator";
import {
  formatTraceName,
  formatUnit,
  formattedTraceText,
} from "../../../utils/charts/formatter";

import {
  getFormattedWellNameAndNumber,
  getLargestSignificantNumber,
} from "../../../utils";

const productionChartsURL = `${config.endpoints.wellService}api/wells/${PRODUCTION_PLOT_SECTION.urlSlug}`;

const useWellPanelProduction = () => {
  const [isProductionDataLoading, setIsProductionDataLoading] = useState(false);
  const [isProductionDataError, setIsProductionDataError] = useState<
    string | undefined
  >(undefined);
  const [productionDataTrace, setProductionDataTrace] = useState<
    ProductionData | undefined
  >(undefined);

  const productionDataFieldList = useMemo(() => {
    return PRODUCTION_DATA_FIELDS_LIST.filter(
      (dataField) =>
        dataField.id === 1 || dataField.id === 3 || dataField.id === 5
    );
  }, []);

  const buildTrace = (formattedText: string, markerColor: string) => {
    const trace: Data = {
      type: "scatter",
      mode: "lines",
      hoverlabel: { namelength: -1 },
      hoverinfo: "x+y+text",
      text: formattedText,
      name: formattedText,
      marker: { color: markerColor },
      xhoverformat: "%b %Y",
      visible: true,
      x: [],
      y: [],
    };
    return trace;
  };

  const getFormattedTrace = (data: GroupedProductionResponseData) => {
    const trace: Data[] = [];
    Object.entries(data).forEach(([key, value]) => {
      productionDataFieldList.forEach((dataFieldSelected: ChartDataFields) => {
        let wellInfo = "";
        if (value.length > 0) {
          // get WellName and WellNumber
          const wellName: string[] = Array.from(
            new Set(value.map((val: any) => val.wellName))
          );
          const wellNumber: string[] = Array.from(
            new Set(value.map((val: any) => val.wellNumber))
          );

          wellInfo = getFormattedWellNameAndNumber(wellName[0], wellNumber[0]);
        }

        const formattedTraceName = `${wellInfo}, ${formatTraceName(
          dataFieldSelected.hasDaily,
          true,
          dataFieldSelected.dailyDisplayName,
          dataFieldSelected.displayName,
          false
        )}`;

        const formattedUnit = formatUnit(
          dataFieldSelected.hasDaily,
          true,
          dataFieldSelected.hasLateralLength,
          false,
          dataFieldSelected.unit
        );

        const formattedText = formattedTraceText(
          formattedTraceName,
          formattedUnit,
          Boolean(dataFieldSelected.unit)
        );

        const dataInfo = buildTrace(formattedText, dataFieldSelected.color);

        value.forEach((productionData: any) => {
          if (
            productionData[dataFieldSelected.responseData.origin]?.[
              dataFieldSelected.responseData.name
            ] !== undefined
          ) {
            //NOTE: Identified that parsing srting date to data caused performance degradation
            //As a fix, passing the string formatted date into plotly
            // dataInfo["x"].push(
            //   getFormattedXByChart(chartType, val.production)
            // );
            (dataInfo["x"] as Datum[]).push(productionData.production);
            (dataInfo["y"] as Datum[]).push(
              productionData[dataFieldSelected.responseData.origin]?.[
                dataFieldSelected.responseData.name
              ]
                ? parseFloat(
                    productionData[dataFieldSelected.responseData.origin][
                      dataFieldSelected.responseData.name
                    ]
                  )
                : 0
            );
          }
        });

        if (dataInfo.y?.length === 1) {
          dataInfo.mode = "markers";
        }

        trace.push(dataInfo);
      });
    });

    return trace;
  };

  const getCustomTicks = (data: Data[]) => {
    try {
      const maxValues: { [key: string]: number } = {};
      data.forEach((data) => {
        if (data.name && data.type === "scatter") {
          maxValues[data.name] = Math.max(...Object.values({ ...data.y }));
        }
      });

      let largestNumber: number = Math.round(
        Math.max(...Object.values(maxValues))
      );

      if (largestNumber === 0) {
        largestNumber = Math.max(...Object.values(maxValues));
      }

      const largestSignificantNumber =
        getLargestSignificantNumber(largestNumber);

      const newYData: number[] = [];
      const tickDivider = 5;

      let initialTickVal = 0;
      let currentTickVal = 0;
      if (largestSignificantNumber < 5) {
        initialTickVal = Number(
          (largestSignificantNumber / tickDivider).toFixed(2)
        );
        currentTickVal = initialTickVal;

        for (let i = 0; i < 7; i++) {
          newYData.push(currentTickVal);
          currentTickVal = Number((initialTickVal + currentTickVal).toFixed(2));
        }
      } else {
        initialTickVal = Math.round(largestSignificantNumber / tickDivider);
        currentTickVal = initialTickVal;

        for (let i = 0; i < 7; i++) {
          newYData.push(currentTickVal);
          currentTickVal += initialTickVal;
        }
      }

      return newYData;
    } catch (error) {
      console.debug(error);
      return [-Infinity, -Infinity, -Infinity];
    }
  };

  const getProductionData = async (prodDataProps: GetProductionDataProps) => {
    try {
      setIsProductionDataLoading(true);

      const dataSets: DataSet[] = [];

      productionDataFieldList.forEach((dataField) => {
        const dataSetObj: DataSet = {
          accumulationMethod: dataField.accumMethod,
          data: dataField.name,
          source: dataField.dataSource,
        };
        dataSets.push(dataSetObj);
      });

      const body: ProductionDataBody = {
        chartType:
          CHARTS_FIELDS_TO_CHART_TYPE_MAPPER[CHART_TYPES.PRODUCTION_PLOT],
        groupBy: CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS,
        dataSet: dataSets,
        flags: {
          daily: true,
          normalizeByLateralLength: false,
        },
        searchRequest: {
          wellIds: [prodDataProps.wellId],
        },
      };

      const response = await axios.post(productionChartsURL, body);

      const productionData: ProductionData = {
        trace: [],
        isDataLoaded: true,
        customTicks: [-Infinity, -Infinity, -Infinity],
      };

      if (!response || !("data" in response)) {
        setProductionDataTrace(productionData);
        return;
      }

      const extractedGData = extractGData(
        response.data.productions.filter(
          (p: any) => p.source === CHART_DATA_SOURCE.PRODUCTION
        )
      );

      const groupedByChartData = groupDataByFields(extractedGData, ["name"]);

      productionData.trace = getFormattedTrace(
        groupedByChartData as GroupedProductionResponseData
      );
      productionData.customTicks = getCustomTicks(productionData.trace);

      setProductionDataTrace(productionData);
    } catch (errorResponse) {
      const err = errorResponse as AxiosError<APIErrorResponse>;
      setIsProductionDataError(err.message);
    } finally {
      setIsProductionDataLoading(false);
    }
  };

  return {
    getProductionData,
    isProductionDataLoading,
    productionDataTrace,
    isProductionDataError,
  };
};

export default useWellPanelProduction;
