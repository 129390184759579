import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "component-library";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";
import { WellEconomicsInputs } from "../../../../types/panels/wellPanel/wellEconomicsType";

import * as constant from "../../../../constants/panels/wellPanel/wellEconomics";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellEconomics from "../../../../customHooks/panels/wellPanel/useWellEconomics";
import useWellEconomicsOutputData from "../../../../customHooks/panels/wellPanel/useWellEconomicsData";
import useWellEconomicsEvents from "../../../../customHooks/panels/wellPanel/useWellEconomicsEvents";

import OutlinedInputField from "../../../common/OutlinedInputField";
import DownloadIcon from "../../../common/icons/DownloadIcon";
import WellPanelAccordion from "../common/WellPanelAccordion";

const WellEconomics: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
  handleRef,
}) => {
  const [hasTriggeredRecalculate, setHasTriggeredRecalculate] = useState(false);
  const [disableRecalculate, setDisableRecalculate] = useState(true);
  const [disableExport, setDisableExport] = useState(false);
  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.WELL_ECONOMICS_SECTION);
  };
  const updateIsWellEconomicsLoading = usePanelsStore(
    (state) => state.updateIsWellEconomicsLoading
  );

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const isWellEconomicsLoading = usePanelsStore(
    (state) => state.isWellEconomicsLoading
  );

  const wellEconomicsCurrentState = usePanelsStore(
    (state) => state.wellEconomicsInputs
  );
  const addWellEconomicsInput = usePanelsStore(
    (state) => state.addWellEconomicsInput
  );

  const wellEconomicsInputInfo = useMemo(() => {
    return wellEconomicsCurrentState?.[wellData.wellCardData.wellId as number];
  }, [wellEconomicsCurrentState]);

  const { getWellEconomicsOutputData, data, success, loading } =
    useWellEconomicsOutputData();

  const {
    originalInputValues,
    parseInputValues,
    convertNegativeToParenthesis,
    reCheckAPIValidations,
    checkEquality,
    checkDefaultEquality,
  } = useWellEconomics(wellData);

  const {
    handleInputChange,
    handleOnBlur,
    validateBeforeRecalculate,
    setGreyedStateNull,
  } = useWellEconomicsEvents(wellData);

  const handleRecalculate = (
    parsedChangedInputValues?: WellEconomicsInputs,
    isForExport = false
  ) => {
    if (!wellEconomicsInputInfo?.inputs) return;

    if (validateBeforeRecalculate() && reCheckAPIValidations()) {
      const inputs = parsedChangedInputValues
        ? parsedChangedInputValues
        : parseInputValues(wellEconomicsInputInfo?.inputs);
      const formattedInputs = setGreyedStateNull(inputs);

      getWellEconomicsOutputData(
        [wellData.wellCardData.uwi] as string[],
        formattedInputs,
        isForExport
      );
      setDisableRecalculate(true);
      setDisableExport(true);
      updateIsWellEconomicsLoading(true);
      setHasTriggeredRecalculate(true);
    }
  };

  useEffect(() => {
    if (!wellEconomicsInputInfo?.inputs || !originalInputValues) {
      setDisableRecalculate(true);
      return;
    }

    if (wellEconomicsInputInfo?.errorState) {
      const parsedChangedInputValues = parseInputValues(
        wellEconomicsInputInfo.inputs
      );
      if (
        Object.values(wellEconomicsInputInfo.errorState).some((item) => item) ||
        checkEquality(parsedChangedInputValues)
      ) {
        setDisableRecalculate(true);
      } else {
        setDisableRecalculate(false);
      }
    }
  }, [
    originalInputValues,
    wellEconomicsInputInfo?.inputs,
    wellEconomicsInputInfo?.errorState,
  ]);

  useEffect(() => {
    if (wellData.pageNumber === activePage) {
      //check if data was already in store, else call api
      if (wellData.wellEconomicsData) {
        if (!wellEconomicsInputInfo?.inputs) {
          const parsedData = convertNegativeToParenthesis(
            wellData.wellEconomicsData.defaultValues.Inputs
          );
          addWellEconomicsInput({
            [wellData.wellCardData.wellId as number]: {
              inputs: parsedData,
              errorState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
              changedState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
              greyedState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
            },
          });
        }
      } else {
        getWellEconomicsOutputData(
          [wellData.wellCardData.uwi] as string[],
          undefined,
          false
        );
        updateIsWellEconomicsLoading(true);
      }
    }
  }, [wellData.pageNumber, wellData.wellEconomicsData, activePage]);

  useEffect(() => {
    if (!loading && success) {
      if (data?.Inputs) {
        if (hasTriggeredRecalculate && wellData.wellEconomicsData) {
          const parsedChangedInputValues = parseInputValues(
            wellEconomicsInputInfo.inputs
          );
          const economicsData = {
            ...wellData.wellEconomicsData,
            recalculatedValues: data,
            userValuesRecalculated: parsedChangedInputValues,
          };
          updateWellCardDataByTypeAndKey(
            { type: "welleconomics", wellEconomics: economicsData },
            wellData.wellId,
            layer,
            groupedWellID
          );

          setHasTriggeredRecalculate(false);
        } else {
          if (!wellEconomicsInputInfo) {
            const parsedData = convertNegativeToParenthesis(data.Inputs);
            addWellEconomicsInput({
              [wellData.wellCardData.wellId as number]: {
                inputs: parsedData,
                errorState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
                changedState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
                greyedState: constant.WELL_ECONOMICS_FIELD_ERROR_VALUES,
              },
            });
          }

          //do not update the default values of well economics
          if (!wellData.wellEconomicsData) {
            const economicsData = {
              defaultValues: data,
              recalculatedValues: null,
              userValuesRecalculated: null,
            };
            updateWellCardDataByTypeAndKey(
              { type: "welleconomics", wellEconomics: economicsData },
              wellData.wellId,
              layer,
              groupedWellID
            );
          }
        }

        setDisableRecalculate(true);
        setDisableExport(false);
        setTimeout(() => updateIsWellEconomicsLoading(false), 500);
        updateIsWellEconomicsLoading(false);
      } else {
        setDisableExport(false);
      }
    } else {
      if (!loading && success && data?.Result.Success) {
        addWellEconomicsInput({});
        updateIsWellEconomicsLoading(false);
      }
    }
  }, [data, success, loading]);

  const handleExport = useCallback(() => {
    if (!wellEconomicsInputInfo?.inputs) return;
    const parsedChangedInputValues = parseInputValues(
      wellEconomicsInputInfo.inputs
    );

    if (checkDefaultEquality(parsedChangedInputValues)) {
      getWellEconomicsOutputData(
        [wellData.wellCardData.uwi] as string[],
        undefined,
        true
      );

      setDisableRecalculate(true);
      setDisableExport(true);
    } else {
      //will also recalculate and update changes made by user
      handleRecalculate(parsedChangedInputValues, true);
    }
  }, [wellEconomicsInputInfo?.inputs]);

  const hasInputData = useMemo(() => {
    return (
      wellEconomicsInputInfo &&
      Object.keys(wellEconomicsInputInfo.inputs).length
    );
  }, [wellEconomicsInputInfo]);

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.WELL_ECONOMICS_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isWellEconomicsLoading}
      id={`well-economics-wrapper-${wellData.wellCardData.wellId}`}
    >
      <div
        className="well-economics-fields-container"
        ref={(instance) => handleRef && handleRef(instance)}
      >
        {constant.WELL_ECONOMICS_INPUT_FIELDS.map((field) => (
          <OutlinedInputField
            key={field.key}
            label={field.label}
            suffix={field.unit}
            value={wellEconomicsInputInfo?.inputs?.[field.key] ?? ""}
            onChange={(e) => handleInputChange(e, field.key)}
            onBlur={() => handleOnBlur(field.key)}
            error={wellEconomicsInputInfo?.errorState[field.key]}
            highlight={
              !wellEconomicsInputInfo?.errorState[field.key] &&
              wellEconomicsInputInfo?.changedState?.[field.key]
            }
            greyoutfilled={wellEconomicsInputInfo?.greyedState?.[field.key]}
          />
        ))}
      </div>
      <div className="well-economics-input-buttons">
        <Button
          text="Recalculate"
          type="secondary"
          disabled={disableRecalculate || isWellEconomicsLoading}
          onClick={() => handleRecalculate()}
        />
        <Button
          text={
            <>
              <span>
                <DownloadIcon />
              </span>
              <p>EXPORT</p>
            </>
          }
          disabled={isWellEconomicsLoading || !hasInputData || disableExport}
          onClick={handleExport}
        />
      </div>
    </WellPanelAccordion>
  );
};

export default WellEconomics;
