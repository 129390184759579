import { WellPermitPanelState } from "../../types/panels/sidebar";

import { SIDEBAR_MENU_TEXTS } from "../../constants/panels/menu";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import usePanelsStore from "../../store/panels/panelsStore";

import WellPanelBubbleIcon from "../common/icons/WellPanelBubbleIcon";
import WellPanelDashedLineIcon from "../common/icons/WellPanelDashedLineIcon";

interface SidebarWellPanelSlotProps {
  wellPermitData: WellPermitPanelState;
}
const SidebarWellPanelSlot = ({
  wellPermitData,
}: SidebarWellPanelSlotProps) => {
  const updateActiveWellPanel = usePanelsStore(
    (state) => state.updateActiveWellPanel
  );
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );
  const activePanel = usePanelsStore((state) => state.activePanel);
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );

  const onClickHandle = () => {
    if (activePanel !== SIDEBAR_MENU_TEXTS.WELL_PANEL) {
      updateActivePanel(SIDEBAR_MENU_TEXTS.WELL_PANEL);
    }

    if (wellPermitData && !wellPermitData.isActive) {
      switch (wellPermitData.recordType) {
        case RECORD_TYPES.WELL:
          updateActiveWellPanel(true, wellPermitData);
          toggleLeftSidePanel(true);
          updateActivePermitPanel(false);
          return;
        case RECORD_TYPES.PERMIT:
          updateActiveWellPanel(false);
          toggleLeftSidePanel(true);
          updateActivePermitPanel(true, wellPermitData);
          return;
        default:
          return;
      }
    } else {
      if (isOpenLeftSidePanel) {
        switch (wellPermitData.recordType) {
          case RECORD_TYPES.WELL:
            updateActiveWellPanel(false, wellPermitData);
            return;
          default:
            return;
        }
      }
      toggleLeftSidePanel();
    }
  };

  return (
    <div className="well-panel-bubble">
      <WellPanelDashedLineIcon />
      <WellPanelBubbleIcon
        filled={
          wellPermitData?.isActive &&
          activePanel === SIDEBAR_MENU_TEXTS.WELL_PANEL
        }
        onClick={onClickHandle}
      />
    </div>
  );
};

export default SidebarWellPanelSlot;
