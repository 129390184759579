import { useMemo } from "react";

import { Badge } from "@mui/material";

import usePanelsStore from "../../store/panels/panelsStore";

import useSideBarNavPanel from "../../customHooks/panels/useSideBarNavPanel";

import WellPanelCollapseIcon from "../common/icons/WellPanelCollapseIcon";
import WellPanelContractIcon from "../common/icons/WellPanelContractIcon";
import SidebarWellPanelSlot from "./SidebarWellPanelSlot";

const SidebarWellPanelNav = () => {
  const isWellPanelNavCollapsed = usePanelsStore(
    (state) => state.isWellPanelNavCollapsed
  );
  const toggleIsWellPanelNavCollapsed = usePanelsStore(
    (state) => state.toggleIsWellPanelNavCollapsed
  );

  const toggleNavigation = () => {
    toggleIsWellPanelNavCollapsed(!isWellPanelNavCollapsed);
  };

  const { wellsAndPermitPanel, sortedWellsAndPermitPanel } =
    useSideBarNavPanel();

  const getWellPanelNavigation = useMemo(() => {
    const children: JSX.Element[] = [];

    sortedWellsAndPermitPanel.forEach((wellPermitData, index) => {
      children.push(
        <SidebarWellPanelSlot
          key={index + "_" + wellPermitData.wellHeaderPermitKey}
          wellPermitData={wellPermitData}
        />
      );
    });

    return children;
  }, [
    sortedWellsAndPermitPanel,
    sortedWellsAndPermitPanel.length,
    isWellPanelNavCollapsed,
  ]);

  return (
    <div className="well-panel-menu">
      <Badge color="error" badgeContent={`${wellsAndPermitPanel.length}`}>
        {isWellPanelNavCollapsed ? (
          <WellPanelCollapseIcon state="pressed" onClickFn={toggleNavigation} />
        ) : (
          <WellPanelContractIcon state="enabled" onClickFn={toggleNavigation} />
        )}
      </Badge>

      {isWellPanelNavCollapsed && wellsAndPermitPanel.length && (
        <>{getWellPanelNavigation}</>
      )}
    </div>
  );
};

export default SidebarWellPanelNav;
