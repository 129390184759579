import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionImageTitle,
  AccordionSubContent,
  CustomTypography,
} from "./../StyledAccordions";

import SanAndreasImage from "../../../../assets/images/san-andreas.png";
import WolfCampImage from "../../../../assets/images/wolfcamp.svg";

const Version20230718: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20230718"}
      onChange={handleChange("Version20230718")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2023.07.18
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          7/18/2023
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release focused on incremental updates to the map culture layers,
          gun barrel diagrams, and production plots.
        </CustomTypography>
        <AccordionContent>
          • Added 2 additional Stratigraphic Model isopach layers in the map
          culture layers:
        </AccordionContent>
        <AccordionImageTitle>San Andreas</AccordionImageTitle>
        <AccordionImage src={SanAndreasImage} width={300} draggable={false} />
        <AccordionImageTitle>wolfcamp y</AccordionImageTitle>
        <AccordionImage src={WolfCampImage} width={300} draggable={false} />
        <AccordionContent>
          • Added loading indicator to the Gun Barrel tab on the well card
        </AccordionContent>
        <AccordionContent>
          • Default sort by CumBOE desc instead of well count desc.
        </AccordionContent>
        <AccordionSubContent>o Top 5 Ultimate Owners Chart</AccordionSubContent>
        <AccordionSubContent>
          o Well spot styling by nvarchar attribute
        </AccordionSubContent>
        <AccordionContent>
          • Production plot hover labels changed from UWI to Wellname and
          Wellnumber.
        </AccordionContent>
        <AccordionSubContent isItalic>
          o Customer feedback and user testing showed that the gun barrel
          diagram and production plots were being directly compared in well
          spacing workflows, but this was disrupted by having different
          attributes used as the hover label in each module. This change
          normalizes all hover labels to wellname and wellnumber.
        </AccordionSubContent>
      </AccordionDetails>
    </Accordion>
  );
};

export default Version20230718;
