import { noData, specialFloat } from "../../../constants/constants";

import { formattedNumber, getNumberWithComma, roundNumber } from "../../helper";

export const stringValidation = (data: string) => {
  return data ? data : noData;
};

export const booleanValidation = (data: boolean) => {
  return data ? "True" : "False";
};

export const numberValidation = (data: number) => {
  return data !== undefined ? formattedNumber(data) : noData;
};

export const specialCharValidation = (data: string) => {
  if (data) {
    const specialField = data.split(" ");

    return getNumberWithComma(parseInt(specialField[0])).concat(
      " ",
      specialField[1]
    );
  } else {
    return noData;
  }
};

export const checkFormatValidation = (data: any) => {
  if (typeof data === "string") return stringValidation(data);
  if (typeof data === "number") return numberValidation(data);
  if (typeof data === "boolean") return booleanValidation(data);
  return noData;
};

export const specialFloatValidation = (data: number) => {
  if (data !== undefined) {
    return formattedNumber(data, specialFloat);
  } else {
    return noData;
  }
};

export const floatValidation = (
  data: number,
  numberOfDecimals: number,
  noCommas?: boolean
) => {
  if (data !== undefined) {
    const trimDecimals = roundNumber(data, numberOfDecimals);

    if (noCommas) {
      return trimDecimals.toString();
    } else {
      return getNumberWithComma(trimDecimals);
    }
  } else {
    return noData;
  }
};
