import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import HelpPortalMenu from "../../../../assets/images/release-notes/HelpPortalMenu.png";
import HelpPortalPage from "../../../../assets/images/release-notes/HelpPortalPage.png";
import LogCurveAttributes from "../../../../assets/images/release-notes/LogCurveAttributes.png";
import LogInventoryViewLink from "../../../../assets/images/release-notes/LogInventoryViewLink.png";
import LogViewer from "../../../../assets/images/release-notes/LogViewer.png";
import WellEconomicsEnhancements from "../../../../assets/images/release-notes/WellEconomicsEnhancements.png";

const Version20240131: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240131"}
      onChange={handleChange("Version20240131")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.01.31
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          01/31/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes Adding Well Log Viewer, Help Portal, and Data
          Dictionary. We also made some enhancements to Well Economics and well
          panel.
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>• Log Inventory and Log Viewer Link</AccordionContent>
      <AccordionSubContent isItalic>
        o Users can now easily view the available log inventory for every single
        well through well panel and view geological products associated with the
        well through the integration of Well Data Analytics and the TGS R360
        application. This integration streamlines user workflow, allowing for
        easy log discovery in Well Data Analytics.
      </AccordionSubContent>

      <AccordionImage
        src={LogInventoryViewLink}
        width="100%"
        draggable={false}
      />
      <AccordionSubContent isItalic>
        o Users can also select the Log Viewer link in the Logs & Docs section
        of the well panel to open a new tab with the LAS Worx log viewer to
        preview an LAS or Raster.
      </AccordionSubContent>
      <AccordionImage src={LogViewer} width="100%" draggable={false} />

      <AccordionContent>• Help Portal and Data Dictionary</AccordionContent>
      <AccordionSubContent isItalic>
        o The Help Portal and Data Dictionary now enable users to learn how to
        use specific features in the application and obtain deeper knowledge
        related to its functionalities, as well as export terms and their
        associated definitions.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o To access the Help Portal or Data Dictionary, upon logging into the
        application, navigate to the Help icon. Click on it to access the
        drop-down menu. This menu will contain Help and Data Dictionary tabs. To
        access either of these, simply click on the tab you want, and you will
        be redirected to a new browser tab.
      </AccordionSubContent>
      <AccordionImage src={HelpPortalMenu} width="100%" draggable={false} />
      <AccordionImage src={HelpPortalPage} width="100%" draggable={false} />

      <AccordionContent>• Well Economics Enhancements</AccordionContent>
      <AccordionSubContent isItalic>
        o This initial release includes economic data for the Midland Basin and
        future releases will include additional basins.
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Default Economic Outputs - Breakeven, IRR, NPV, Payout Ratio
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Exportable financial model
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Users can edit inputs and recalculate economic outputs, customizable
        investment analysis and asset valuations
      </AccordionSubContent>
      <AccordionImage
        src={WellEconomicsEnhancements}
        width="100%"
        draggable={false}
      />

      <AccordionContent>
        • Well Panel Functionality Improvements
      </AccordionContent>
      <AccordionSubContent isItalic>
        o We have made some improvements to the Well Info tabs visualization for
        better analysis
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Enabled Production Plot and Gun Barrel Diagram image export
      </AccordionSubContent>
      <AccordionSubContent isItalic>
        o Added Decline Curve parameters tab under Well Info
      </AccordionSubContent>
      <AccordionImage src={LogCurveAttributes} width="100%" draggable={false} />

      <AccordionContent>
        • Added more production statistic attributes to search attributes and
        grid results:
      </AccordionContent>
      <AccordionSubContent>o Recent1MoGas</AccordionSubContent>
      <AccordionSubContent>o Recent6MoGas</AccordionSubContent>
      <AccordionSubContent>o Recent1YrGas</AccordionSubContent>
      <AccordionSubContent>o Recent1MoOil</AccordionSubContent>
      <AccordionSubContent>o Recent6MoOil</AccordionSubContent>
      <AccordionSubContent>o Recent1YrOil</AccordionSubContent>
      <AccordionSubContent>o Recent1MoWater</AccordionSubContent>
      <AccordionSubContent>o Recent6MoWater</AccordionSubContent>
      <AccordionSubContent>o Recent1YrWater</AccordionSubContent>
    </Accordion>
  );
};

export default Version20240131;
